import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BimApi } from "api/bim.api"
import CmicLinkedObjectsApi from "api/cmicLinkedObjects.api"
import { FlatTreeNode, LinkedObjectAPI } from "common/define"
import { GlobalState } from "common/global"
import { MarkupEntity, MarkupViewEntities } from "common/type-markup"
import { RootEpic } from "common/type-state"
import { concat, merge, Observable } from "rxjs"
import { catchError, filter, switchMap, withLatestFrom, map, concatMap } from "rxjs/operators"
import Utils from "utils/utils"
import { setLinkedObjects } from "./RFIstatus.slice"



interface Markup3dActionState {
    listMarkup3d: MarkupEntity[];
    fetchLoading: boolean;
    saveLoading: boolean;
}

const initState: Markup3dActionState = {
    listMarkup3d: [],
    fetchLoading: false,
    saveLoading: false,
}

const markup3dActiveSlice = createSlice({
    name: 'pinMarkerAction',
    initialState: initState,
    reducers: {
        setListMarkup3d(state, action: PayloadAction<MarkupEntity[]>) {
            state.listMarkup3d = action.payload;
        },
        fetchMarkup3d(state, action: PayloadAction<undefined>) { return },
        setFetchLoading(state, action: PayloadAction<boolean>) {
            state.fetchLoading = action.payload;
        },
        saveMarkup3d(state, action: PayloadAction<MarkupEntity>) { 
            state.saveLoading = true;
        },
        setSaveLoading(state, action: PayloadAction<boolean>) {
            state.saveLoading = action.payload;
        },
        updateMarkup3d(state, action: PayloadAction<MarkupEntity>) { return },
        deleteMarkup3d(state, action: PayloadAction<MarkupEntity>) { 
            state.saveLoading = true;
        },
        cmicRemoveLinkedObject(state, action: PayloadAction<LinkedObjectAPI>) { return }
    }
})

const fetchMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const viewer = GlobalState.getViewer3D(viewActive.viewId);
        return merge(
            [setFetchLoading(true)],
            BimApi.getMarkup3D(viewActive.modelFileId).pipe(
                switchMap(value => {
                    if (!value) return [setFetchLoading(false)];
                    const parseValue: MarkupEntity[] = JSON.parse(value);
                    parseValue.forEach(markup => {
                        if (!markup.originData?.markupView || !viewer) return;
                        const views: MarkupViewEntities[] = [markup.originData.markupView];
                        const markupData = {
                            views: views,
                            notes: [],
                            measurement: [],
                            lines: [],
                        };
                        viewer.markupManager.loadMarkupData(markupData)
                    })
                    return [setListMarkup3d(parseValue)]
                }),
                catchError(err => [setFetchLoading(false)])
            ),
        )
    }))

const saveMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(saveMarkup3d.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const { payload } = action
        const nodeIds = payload.originData.pinMarkerData.nodes;
        // const linkedID = payload.originData.pinMarkerData.;
        const currentTreeMap = state.tree.currentTreeMap;
        const persistentIds: string[] = [];
        const nodes: FlatTreeNode[] = [];

        nodeIds.forEach((node: number) => {
            const treeNode = currentTreeMap.get(node.toString());
            if (treeNode?.PersistentId){
                persistentIds.push(treeNode.PersistentId);
                nodes.push(treeNode);
            }
        })
        const finalPayload = {
            ...payload,
            persistentIds
        };
        const objSelection = JSON.stringify(nodes);
        const { objectId, type } = payload.originData.pinMarkerData
        // const obj = Utils.getLinkedObjectById(objectId , state.RFIstatus, type)
        const linkedObjectType = Utils.getLinkedObjectTypeById(type);
        const { isDummyData } = state.RFIstatus;
        const mergeReturn: Observable<any>[] = [
            BimApi.updateMarkup3D(viewActive.modelFileId, finalPayload).pipe(
                switchMap(value => {
                    return [
                        setSaveLoading(false),
                        fetchMarkup3d()
                    ]
                }),
                catchError(err => [setSaveLoading(false)])
            ),
        ];
        const { cmicModelId, cmicProjGuid } = payload;
        if (isDummyData && cmicModelId && cmicProjGuid) {
            // Call API Update LinkedObject, Add
            mergeReturn.push(
                CmicLinkedObjectsApi.updateLinkedObjects(
                    linkedObjectType,
                    cmicModelId,
                    objSelection,
                    'Y',
                    objectId,
                    cmicProjGuid).pipe(
                        switchMap(() => {
                            return []
                        }),
                        catchError(err => [setSaveLoading(false)])
                    )
            );
            mergeReturn.push(
                CmicLinkedObjectsApi.getLinkedObjects(cmicModelId, cmicProjGuid, type).pipe(
                    switchMap(res => {
                        const linkedObjectsData = Utils.GetLinkedObjectData(res);
                        const cmicLinkedObject = {
                            ...state.RFIstatus.LinkedObject,
                            ...linkedObjectsData
                        };
                        return [setLinkedObjects(cmicLinkedObject)];
                    }),
                    catchError(() => [])
                )
            );
        }
        return concat(
            // [setSaveLoading(true)],
            ...mergeReturn
        )
    }))

const updateMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const payload = action.payload;
        return merge(
            [setSaveLoading(true)],
            BimApi.updateMarkup3D(viewActive.modelFileId, payload).pipe(
                switchMap(value => {
                    return [setSaveLoading(false)]
                }),
                catchError(err => [])
            ),
        )
    }))

const deleteMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteMarkup3d.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const { payload } = action;
        const nodeIds = payload.originData.pinMarkerData.nodes;
        const currentTreeMap = state.tree.currentTreeMap;
        const nodes: FlatTreeNode[] = [];
        nodeIds.forEach((node: number) => {
            const treeNode = currentTreeMap.get(node.toString());

            if (treeNode){
                nodes.push(treeNode);
            }
        })
        const objSelection = JSON.stringify(nodes);
        const { objectId, type } = payload.originData.pinMarkerData
        // const obj = Utils.getLinkedObjectById(objectId , state.RFIstatus, type)
        const linkedObjectType = Utils.getLinkedObjectTypeById(type);
        const { isDummyData } = state.RFIstatus;
        const mergeReturn: Observable<any>[] = [
            BimApi.deleteMarkup3D(viewActive.modelFileId, [payload.uniqueId]).pipe(
                switchMap(value => {
                    return [
                        setSaveLoading(false),
                        fetchMarkup3d()
                    ]
                }),
                catchError(err => [setSaveLoading(false)])
            ),
        ];
        const { cmicModelId, cmicProjGuid } = payload;
        if (isDummyData && cmicModelId && cmicProjGuid) {
            // Call API Update LinkedObject, Remove
            mergeReturn.push(
                CmicLinkedObjectsApi.updateLinkedObjects(
                    linkedObjectType,
                    cmicModelId,
                    objSelection,
                    'N',
                    objectId,
                    cmicProjGuid).pipe(
                        switchMap(() => {
                            return []
                        }),
                        catchError(err => [])
                    )
            );
            mergeReturn.push(
                CmicLinkedObjectsApi.getLinkedObjects(cmicModelId, cmicProjGuid, type).pipe(
                    map(res => {
                        const linkedObjectsData = Utils.GetLinkedObjectData(res);
                        const cmicLinkedObject = {
                            ...state.RFIstatus.LinkedObject,
                            ...linkedObjectsData
                        };
                        return setLinkedObjects(cmicLinkedObject);
                    }),
                    catchError(() => [])
                )
            );
        }
        return concat(
            // [setSaveLoading(true)],
            ...mergeReturn
        )
    }),
);

const cmicRemoveLinkedObject$: RootEpic = (action$, state$) => action$.pipe(
    filter(cmicRemoveLinkedObject.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { payload } = action;
        const linkedObjectType = Utils.getLinkedObjectTypeById(payload._type);
        const { id } = Utils.getIDName(payload._type);
        const objectId = payload[id];
        const { cmicModelId, cmicProjGuid } = payload;
        if (!cmicModelId || !cmicProjGuid) {
            return [];
        }
        return concat(
            CmicLinkedObjectsApi.updateLinkedObjects(
                linkedObjectType,
                cmicModelId,
                '',
                'N',
                objectId,
                cmicProjGuid).pipe(
                    switchMap(() => {
                        return []
                    }),
                    catchError(err => [])
                ),
            CmicLinkedObjectsApi.getLinkedObjects(cmicModelId, cmicProjGuid, payload._type).pipe(
                map(res => {
                    const linkedObjectsData = Utils.GetLinkedObjectData(res);
                    const cmicLinkedObject = {
                        ...state.RFIstatus.LinkedObject,
                        ...linkedObjectsData
                    };
                    return setLinkedObjects(cmicLinkedObject);
                }),
                catchError(() => [])
            )
        );
    })
);

export const Markup3dActionEpics = [
    fetchMarkup3d$,
    saveMarkup3d$,
    updateMarkup3d$,
    deleteMarkup3d$,
    cmicRemoveLinkedObject$
]

export const {
    setListMarkup3d,
    fetchMarkup3d,
    setFetchLoading,
    saveMarkup3d,
    setSaveLoading,
    updateMarkup3d,
    deleteMarkup3d,
    cmicRemoveLinkedObject
} = markup3dActiveSlice.actions;

export default markup3dActiveSlice.reducer;
