/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CmicLinkedObjectsApi from "api/cmicLinkedObjects.api";
import RFIAPI from "api/rfi.api";
import { PinMarkerType } from "common/define";
import { RFIstatusState, RootEpic } from "common/type-state";
import PCIdummyData from "pages/main-viewer/float-panel/linked-objects/PCIdummyData";
import dummyText from "pages/main-viewer/float-panel/linked-objects/RFIdummyData";
import SUBdummyData from "pages/main-viewer/float-panel/linked-objects/SUBdummyData";
import { merge, of, zip } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom, switchMap } from "rxjs/operators";
import Utils from "utils/utils";

const initState: RFIstatusState = {
    data: {
        RFI: undefined,
        SUB: undefined,
        PCI: undefined,
        DOC: undefined,
    },
    isFetching: false,
    itemInfo: undefined,
    isDummyData: true,
}

const RFIstatusSlice = createSlice({
    name: 'RFIstatus',
    initialState: initState,
    reducers: {
        fetchLinkedObject(state, action: PayloadAction<any>) { return },
        setIsFetching(state, action: PayloadAction<boolean>) {
            state.isFetching = action.payload;
        },
        setData(state, action: PayloadAction<any>) {
            const { RFI, SUB, PCI, DOC, LinkedObject } = action.payload;
            state.data = { RFI, SUB, PCI, DOC };
            state.LinkedObject = LinkedObject;
        },
        setLinkedObjects(state, action: PayloadAction<any>) {
            state.LinkedObject = action.payload;
        },
        toggleDummy(state, action: PayloadAction<any>) { return },
        toggleIsDummy(state, action: PayloadAction<undefined>) {
            state.isDummyData = !state.isDummyData;
        },
        setIsDummy(state, action: PayloadAction<boolean>) {
            state.isDummyData = action.payload;
        },
    }
})

const fetchLinkedObject$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchLinkedObject.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const { modelId, projGuid } = action.payload;
        const apiCall = zip(
            RFIAPI.CMiC.getRFI(modelId, projGuid).pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: any) => item._type = PinMarkerType.RFI);
                    return { RFI: newVal }
                }),
                catchError(e => of({ RFI: null }))
            ),
            RFIAPI.CMiC.getSUB(modelId, projGuid).pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: any) => item._type = PinMarkerType.SUB);
                    return { SUB: newVal }
                }),
                catchError(e => of({ SUB: null }))
            ),
            RFIAPI.CMiC.getPCI(modelId, projGuid).pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: any) => item._type = PinMarkerType.PCI);
                    return { PCI: newVal }
                }),
                catchError(e => of({ PCI: null }))
            ),
            CmicLinkedObjectsApi.getLinkedObjects(modelId, projGuid, 'ALL').pipe(
                map(value => {
                    // const data = Utils.GetLinkedObjectData(LinkedObjectdummy)
                    const data = Utils.GetLinkedObjectData(value)
                    return { LinkedObject: data }
                }),
                catchError(e => of({ LinkedObject: null }))
            ),
        );
        return merge(
            [setIsFetching(true)],
            apiCall.pipe(
                concatMap(val => {
                    const result = Object.assign({}, ...val) as any;
                    return [
                        RFIstatusSlice.actions.setData(result),
                        setIsFetching(false)
                    ]
                }),
                catchError(e => {
                    console.log(e);
                    return [
                        setIsFetching(false)
                    ]
                })
            )
        )
    }
    ))

const toggleDummy$: RootEpic = (action$, state$) => action$.pipe(
    filter(toggleDummy.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { modelId, projGuid } = action.payload;
        const curState = state.RFIstatus.isDummyData;
        if (curState) {
            const dataDummy = {
                RFI: dummyText,
                PCI: PCIdummyData,
                SUB: SUBdummyData,
                // DOC: JSON.parse(DOCdummyText),
            }
            return [
                RFIstatusSlice.actions.setData(dataDummy),
                RFIstatusSlice.actions.toggleIsDummy()
            ]
        }
        return [
            RFIstatusSlice.actions.setData(initState.data),
            RFIstatusSlice.actions.fetchLinkedObject({ modelId, projGuid }),
            RFIstatusSlice.actions.toggleIsDummy(),
        ]
    })
)
export const RFIstatusEpics = [
    // getRFI$,
    // getByCompany$,
    // getPmSub$,
    // getPCI$,
    fetchLinkedObject$,
    // getPMSubByCompCode$,
    // getDocument$,
    // getSubmittal$
    toggleDummy$,
]

export const {
    fetchLinkedObject,
    setIsFetching,
    toggleDummy,
    setIsDummy,
    setLinkedObjects
} = RFIstatusSlice.actions;
export default RFIstatusSlice.reducer;
