import { SystemConstants } from "common/system-constants";
import {CMICLinkedObjects} from "common/type-state";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import HttpClient from "./http-client";
import Utils from "utils/utils";

export default class CmicLinkedObjectsApi {
    static getLinkedObjects(modelId:string, projGuid: string, type = 'All'): Observable<CMICLinkedObjects>{
        const api  = SystemConstants.SERVER_LINKED_OBJECTS.GET(modelId, projGuid, type )
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('ALL'),
                'X-projectGUID': projGuid,
            }
        }
        return HttpClient.get(api, options).pipe(map(res=> {
            return res as CMICLinkedObjects
        }))
    }
    static updateLinkedObjects(objType: string, modelId: string, objSelection: any, linkStatus: 'Y'|'N', objGUID: string, projGUID: string): Observable<string>{
        const api  = SystemConstants.SERVER_LINKED_OBJECTS.POST()
        // Y for add N for remove
        const body = {
            "objType": objType,
            "modelId": modelId,
            "objSelection": objSelection,
            "linkStatus": linkStatus,
            "objGUID": objGUID,
            "projGUID": projGUID
        }
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('ALL'),
                'X-projectGUID': projGUID,
            }
        }
        return HttpClient.post(api, body, options).pipe(map(res=> {
            return res as string
        }))
    }
}
