/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Layout } from "common/define";
import { GlobalState } from "common/global";
import { Point } from "common/type-markup";
import CModel from "../extends/CModel";
import CZoomOperator from "../extends/CZoomOperator";
import { ResultActionContext } from "./model-state";

interface IGhostingInfo {
    nodeIds: number[];

    drawMode: Communicator.DrawMode;
}

const mapGhostingInfo = new Map<ViewId, IGhostingInfo>();
export default class ModelHelper {
    static async getBoundingBox(
        viewer: Communicator.WebViewer,
        nodeIds: NodeId[]
    ): Promise<Communicator.Box | null> {
        let retBox: Communicator.Box | null = null;
        const promiseList = [];
        if (viewer.explodeManager.getMagnitude() > 0) {
            nodeIds.forEach((id) => {
                const pr = new Promise<Communicator.Box>((resolve, reject) => {
                    viewer.model.getNodeRealBounding(id).then((box) => {
                        resolve(box);
                    });
                });
                promiseList.push(pr);
            });
        } else {
            const pr = new Promise<Communicator.Box>((resolve, reject) => {
                const listNodeId = nodeIds.filter(id => ![ Communicator.NodeType.AssemblyNode, Communicator.NodeType.Unknown ].includes(viewer.model.getNodeType(id)));
                if (listNodeId.length > 0)
                    viewer.model.getNodesBounding(listNodeId).then((box) => {
                        resolve(box);
                    });
                else viewer.model.getModelBounding(true, true).then(box => resolve(box));
            });
            promiseList.push(pr);
        }
        const arrBox = await Promise.all([...promiseList]);
        arrBox && arrBox.forEach((box) => {
            if (box) {
                if (!retBox) retBox = box.copy();
                else retBox.addBox(box);
            }
        });
        return retBox;
    }
    static async getCameraFitToBounding(
        viewer: Communicator.WebViewer,
        box: Communicator.Box
    ): Promise<Communicator.Camera | null> {
        if (box) {
            const cam = viewer.view.getCamera();
            const x = box.extents();
            let newW = x.length();
            let newH = newW;
            const retCam = cam.copy();
            const curW = cam.getWidth();
            const curH = cam.getHeight();

            const pa = Communicator.Point3.subtract(
                cam.getTarget(),
                cam.getPosition()
            ); // at - pos
            let lens = 0;
            const k = pa.length();
            if (
                (pa.x === pa.y && pa.x === 0) ||
                (pa.y === pa.z && pa.y === 0) ||
                (pa.z === pa.x && pa.z === 0) // goc nhin ve cac mat phang chinh
            ) {
                const canvas = viewer.view.getCanvasSize();
                const up = cam.getUp();
                const look = pa.copy().normalize();
                const right = ModelHelper.uxv(look, up);
                newH = Math.abs(x.x * up.x + x.y * up.y + x.z * up.z);
                newW = Math.abs(x.x * right.x + x.y * right.y + x.z * right.z);

                if (newW === 0) newW = newH / 100;
                else if (newH === 0) newH = newW / 100;

                if (newW / canvas.x > newH / canvas.y) lens = (newH * k) / curH;
                else lens = (newW * k) / curW;
            } else {
                lens = (newW * k) / curW;
            }
            const newTar = box.center();
            // keep distance camera if the camera is orthographic
            const fixLens = retCam.getProjection() === Communicator.Projection.Orthographic ? k : lens;
            const newPos = Communicator.Point3.add(
                newTar,
                pa.normalize().scale(-fixLens)
            );
            retCam.setTarget(newTar);
            retCam.setPosition(newPos);
            retCam.setWidth(newW);
            retCam.setHeight(newH);
            return retCam;
        }
        return null;
    }
    static uxv(
        u: Communicator.Point3,
        v: Communicator.Point3
    ): Communicator.Point3 {
        const z = new Communicator.Point3(0, 0, 0);
        z.x = u.y * v.z - u.z * v.y;
        z.y = u.z * v.x - u.x * v.z;
        z.z = u.x * v.y - u.y * v.x;
        return z;
    }
    static async getCameraFitToNodeIds(
        viewer: Communicator.WebViewer,
        listNodeId: number[]
    ): Promise<Communicator.Camera | null> {
        const nodeIds = listNodeId.filter(id => ![ Communicator.NodeType.AssemblyNode, Communicator.NodeType.Unknown ].includes(viewer.model.getNodeType(id)));
        if (nodeIds.length === 0)
            return viewer.model.getModelBounding(true, true).then(box => ModelHelper.getCameraFitToBounding(viewer, box));
        return viewer.model
            .getNodesBounding(nodeIds)
            .then((box) => ModelHelper.getCameraFitToBounding(viewer, box));
    }
    static async zoomFit(viewer: Communicator.WebViewer): Promise<ResultActionContext> {
        let rootId = null;
        if (viewer.sheetManager.isDrawingSheetActive()) {
            rootId = viewer.sheetManager.getActiveSheetId();
            if (rootId) {
                await ModelHelper.getCameraFitToNodeIds(viewer, [rootId]).then(
                    (cam) => {
                        if (cam) viewer.view.setCamera(cam);
                    }
                );
            }
        } else {
            await viewer.view.fitWorld(
                Communicator.DefaultTransitionDuration,
                viewer.view.getCamera()
            );
        }
        return {}
    }
    static async zoomSelect(
        viewer: Communicator.WebViewer,
        selectionArray: NodeId[]
    ): Promise<ResultActionContext> {
        if (selectionArray.length > 0) {
            try {
                const box = await ModelHelper.getBoundingBox(
                    viewer,
                    selectionArray
                );
                if (box) {
                    if (viewer.sheetManager.isDrawingSheetActive()) {
                        const camera = await ModelHelper.getCameraFitToBounding(
                            viewer,
                            box
                        );
                        camera && viewer.view.setCamera(camera, 0);
                    } else {
                        viewer.view.fitBounding(box);
                    }
                }
            } catch (error) {
                return {};
            }
        }
        return {}
    }
    static setCursorViewer(viewer: Communicator.WebViewer, cursor: string): void {
        const el = viewer.getViewElement();
        if (el) {
            el.style.cursor = cursor
        }
    }
    static getSelectionItemId(viewer: Communicator.WebViewer): number[] {
        return viewer.selectionManager.getResults().map((item) => item.getNodeId());
    }
    static getRootNodeId(viewer: Communicator.WebViewer): number | null {
        return viewer.sheetManager.isDrawingSheetActive()
            ? viewer.sheetManager.getActiveSheetId()
            : viewer.model.getAbsoluteRootNode();
    }

    static getNodeIdCuttingPlane(viewer: Communicator.WebViewer): number[] {
        const listNodeId = [];
        const countSection = viewer.cuttingManager.getCuttingSectionCount();
        if (countSection > 0) {
            for (let i = 0; i < countSection; i += 1) {
                const section = viewer.cuttingManager.getCuttingSection(i);
                if (section) {
                    const countPlane = section.getCount();
                    if (countPlane > 0) {
                        for (let j = 0; j < countPlane; j += 1) {
                            const id = section.getNodeId(j);
                            if (id) {
                                listNodeId.push(id);
                            }
                        }
                    }
                }
            }
        }
        return listNodeId;
    }

    static unGhostingNodes(viewer: Communicator.WebViewer): void {
        const rootNodeId = ModelHelper.getRootNodeId(viewer);
        rootNodeId !== null && viewer.model.setInstanceModifier(
            Communicator.InstanceModifier.DoNotXRay,
            [rootNodeId],
            false
        );
        // const viewId = GlobalState.getViewerId(viewer);
        // const modelState = GlobalState.getModel3DState(viewId);
        // if (modelState) modelState.ignoreXRayIsolateNodes();
    }
    static doGhostingNodes(nodeIds: number[], viewer: Communicator.WebViewer): void {  
        const viewId = GlobalState.getViewerId(viewer);
        let ghostingInfor = mapGhostingInfo.get(viewId);
        if (nodeIds && nodeIds.length > 0) {
            if (ghostingInfor) {
                ghostingInfor.nodeIds = nodeIds;
            } else {
                ghostingInfor = {
                    nodeIds,
                    drawMode: viewer.view.getDrawMode(),
                };
                mapGhostingInfo.set(viewId, ghostingInfor);
            }
            // const rootNodeId = ModelHelper.getRootNodeId(viewer);
            // rootNodeId && viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, [rootNodeId], false);
            const xRayNodes = (viewer.model as CModel).getDoNotXRayNodes();
            viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, xRayNodes.filter(id => !nodeIds.includes(id)), false);
            viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, nodeIds, false);
        }
    }
    static configHiddenLineMode(viewer: Communicator.WebViewer, topColor: Communicator.Color | null, bottomColor: Communicator.Color | null): void {
        if (topColor && bottomColor && topColor.equals(bottomColor)) {
            viewer.view.getHiddenLineSettings().setBackgroundColor(topColor)
        } else {
            viewer.view.getHiddenLineSettings().setBackgroundColor(Communicator.Color.white())
        }
        viewer.view.setDrawMode(Communicator.DrawMode.HiddenLine)
    }
    static applyXrayOpacity(viewer: Communicator.WebViewer, valueXray: number, valueGhost: number): void {
        const currentDrawMode = viewer.view.getDrawMode();
        if (currentDrawMode <= Communicator.DrawMode.XRay) {
            viewer.view.setXRayOpacity(valueXray / 100.0)
        } else {
            viewer.view.setXRayOpacity(valueGhost / 100.0)
        }
    }
    static applySpeedZoom(viewer: Communicator.WebViewer, valueSpeed: number): void {
        const zoomOperator = viewer.operatorManager.getOperator(Communicator.OperatorId.Zoom) as CZoomOperator;
        if (zoomOperator) {
            zoomOperator.setZoomSpeedFactor(valueSpeed);
        }
    }
    static applyBimOrbitEnabled(viewer: Communicator.WebViewer, value: boolean): void {
        const orbitOperator = viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit);
        if (orbitOperator && value !== null && value !== undefined) {
            orbitOperator.setBimOrbitEnabled(value);
        }
    }
    static setSelectionGhostMode(viewer: Communicator.WebViewer, viewId: string): void {
        const selectionItemIds = ModelHelper.getSelectionItemId(viewer);
        const selectionItemIdsGrid = GlobalState.getIdsSelectedGrid(viewId);
        if(selectionItemIdsGrid && selectionItemIdsGrid.length > 0){
            ModelHelper.doGhostingNodes(selectionItemIdsGrid, viewer);
        } else if (selectionItemIds && selectionItemIds.length > 0) {
            // change drawmode of model, not change visibility state of nodes
            // viewer.model.setNodesVisibility(selectionItemIds, true);
            ModelHelper.doGhostingNodes(selectionItemIds, viewer);
        } else {
            const previousDrawMode = GlobalState.mapPreviousDrawMode.get(viewId);
            if (!previousDrawMode || previousDrawMode === Communicator.DrawMode.Ghost) {
                const rootNodeId = ModelHelper.getRootNodeId(viewer);
                rootNodeId && viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, [rootNodeId], false);
            }
            if (previousDrawMode) {
                viewer.view.setDrawMode(previousDrawMode);
                GlobalState.mapPreviousDrawMode.delete(viewId);
            }
            // const modelState = GlobalState.getModel3DState(viewId);
            // if (modelState) modelState.ignoreXRayIsolateNodes();
        }
    }
    static moveCubeByLayout(layout: Layout, visibleViews: ViewId[], moveView: ViewId): boolean {
        let isMove = false;
        const index = visibleViews.indexOf(moveView)
        switch (layout) {
            case Layout.Full:
                isMove = true;
                break;
            case Layout.TwoAndTwo:
                isMove = index === 1 || index === 3;
                break;
            case Layout.OneOnTwo:
                isMove = index === 0 || index === 2;
                break;
            case Layout.TwoOnOne:
            case Layout.OneAndTwo:
                isMove = index === 1 || index === 2;
                break;
            case Layout.TwoAndOne:
                isMove = index === 1;
                break;
            case Layout.OneAndOne:
                isMove = index === 1;
                break;
            case Layout.OneOnOne:
                isMove = true;
                break;
            default: break
        }
        return isMove
    }
    static getToolbarDragLimitByLayout(layout: Layout, visibleViews: ViewId[], activeView: ViewId, elWidth: number, elHeight: number): Point {
        let retPoint: Point = {
            x: 0,
            y: 0
        };
        const index = visibleViews.indexOf(activeView);
        const { clientWidth, clientHeight } = window.document.documentElement;
        const width = elWidth + 70;
        const height = elHeight + 70;
        const multiViewHeight = height + 40
        const widthLimit = (clientWidth / 2) + width;
        const heightLimit = (clientHeight / 2) + multiViewHeight;

        switch (layout) {
            case Layout.Full:
                retPoint = {
                    x: width,
                    y: height
                };
                break;
            case Layout.TwoAndTwo:
                retPoint = {
                    x: widthLimit,
                    y: heightLimit
                };
                break;
            case Layout.OneOnTwo:
                retPoint = {
                    x: index === 0 ? width : widthLimit,
                    y: heightLimit
                };
                break;
            case Layout.TwoOnOne:
                retPoint = {
                    x: index === 2 ? width : widthLimit,
                    y: heightLimit
                }
                break;
            case Layout.OneAndTwo:
                retPoint = {
                    x: widthLimit,
                    y: index === 0 ? multiViewHeight : heightLimit
                };
                break;
            case Layout.TwoAndOne:
                retPoint = {
                    x: widthLimit,
                    y: index === 1 ? multiViewHeight : heightLimit
                };
                break;
            case Layout.OneAndOne:
                retPoint = {
                    x: widthLimit,
                    y: multiViewHeight
                };
                break;
            case Layout.OneOnOne:
                retPoint = {
                    x: width,
                    y: heightLimit
                };
                break;
            default: break
        }
        return retPoint
    }

    static setHighlighSelectionIsolate(viewer: Communicator.WebViewer, viewId: string): void {
        const selectionItemIds = ModelHelper.getSelectionItemId(viewer);
        const modelState = GlobalState.getModel3DState(viewId);
        const isolateNodes = modelState?.getIsolateNodes();
        if (isolateNodes) {
            viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, isolateNodes, true);
        }
        if (selectionItemIds.length > 0) {
            viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, selectionItemIds, false);
            modelState?.addToIsolateNodes(selectionItemIds);
        }
    }

    static addToIsolateNodes(viewer: Communicator.WebViewer, nodeIds: number[]) {
        const viewId = GlobalState.getViewerId(viewer);
        const modelState = GlobalState.getModel3DState(viewId);
        modelState?.addToIsolateNodes(nodeIds);
    }
}
