import { createSelector } from "@reduxjs/toolkit";
import { selectSelfFileList } from "./self.selector";

export const getFileOrigin = createSelector(
    selectSelfFileList,
    state => state.filesOrigin
)
export const getFilesList = createSelector(
    selectSelfFileList,
    state => state.filesList
)
export const getFilesListThumbnail = createSelector(
    selectSelfFileList,
    state => state.filesListThumnail
)
export const getFileItem = createSelector(
    selectSelfFileList,
    state => {
        const {filesList, filesListThumnail} = state;

        return filesList.map(item => {
            const thumbnail = filesListThumnail.find(f => f.viewId === item.viewId)
            return {
                file: item,
                thumbnail: thumbnail?.srcThumnail || ''
            }
        })
    }
)

export const getFileInfoJsonMap = createSelector(
    selectSelfFileList,
    (state) => state.fileInfoJsonMap
)

export const getFilesListRefresh = createSelector(
    selectSelfFileList,
    (state) => state.refreshFileList
)
