import { GlobalState } from "common/global";
import CameraOrbitOperator from "../custom-operator/camera-orbit.operator";
import BaseOperator from "./base.operator";

export default class OrbitOperator extends BaseOperator {

    cameraOrbitOperator: CameraOrbitOperator;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orbitHandle: any;

    private bimOrbitEnable = false;

    constructor(viewer: Communicator.WebViewer, viewId: string) {
        super(viewer);
        this.Name = 'orbit';
        this.cameraOrbitOperator = new CameraOrbitOperator(viewer, viewId);
        this.orbitHandle = viewer.operatorManager.registerCustomOperator(this.cameraOrbitOperator);
        this.cameraOrbitOperator.setOrbitFallbackMode(Communicator.OrbitFallbackMode.OrbitTarget);
        this.cameraOrbitOperator.setOrbitTarget(viewer.view.getCamera().getTarget());
        this.cameraOrbitOperator.setPrimaryButton(Communicator.Button.None)
    }

    public UpdateTargetPoint(newTarget: Communicator.Point3): void {
        this.cameraOrbitOperator.UpdateTargetPoint(newTarget);
    }

    /**
     * Active
     *
     */
    public Active(): void {
        if (this.Status === false) {
            this.Viewer.operatorManager.push(this.orbitHandle);
            this.cameraOrbitOperator.setMapping(Communicator.Button.Left);
            this.cameraOrbitOperator.addMapping(Communicator.Button.Middle, Communicator.KeyModifiers.Shift);
            const panOperator = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            panOperator.addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Zoom).setMapping(Communicator.Button.Right, Communicator.KeyModifiers.Shift)
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Right)
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(Communicator.Button.None);
            this.Viewer.model.getModelBounding(true, false).then((box) => {
                if (box) this.UpdateTargetPoint(box.center());
            });
            if(this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Select)){
                this.Viewer.operatorManager.push(Communicator.OperatorId.Select)
            }
            this.Status = true;
            GlobalState.mapOperatorCursor.set('orbit', 'url("/assets/cursor/rotateaxisxy_120494.svg"), auto');
            GlobalState.primaryOperator.set(this.Viewer, 'orbit');
            super.SetCursor('url("/assets/cursor/rotateaxisxy_120494.svg"), auto');
        }
        if (this.Status === true) { // using for update the mode changed
        }
    }

    /**
     * InActive
     *
     */
    public InActive(): void {
        this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Navigate).clearMapping();
        this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).clearMapping();
        if (this.Status === true) {
            this.cameraOrbitOperator.unCallBacks();
            this.Viewer.operatorManager.remove(this.orbitHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.orbitHandle);
            this.Viewer.operatorManager
                .getOperator(Communicator.OperatorId.Orbit)
                .setMapping(Communicator.Button.Left);
            this.Status = false;
        }
    }

    GetIdOperator(): number {
        return this.orbitHandle;
    }

    setBimOrbitEnableExt(value: boolean): void {
        this.bimOrbitEnable = value;
        this.cameraOrbitOperator.setBimOrbitEnabled(this.bimOrbitEnable);
    }
}
