// old data is no longer used
// replaced with LinkedObjectdummy.ts
const SUBdummyData = {
    items: [
        {
            ScschAmt: 1000,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "1000",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: 5,
            ScschComplPct: 0.5,
            ScschComplQty: 0,
            ScschContCode: "PAYREQ-0016",
            ScschCurrHldbkAmt: 0,
            ScschCurrHldbkRlsAmt: 0,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-23T16:11:56-04:00",
            ScschIuUpdateDate: "2021-08-23T16:25:57-04:00",
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: null,
            ScschLstAmt: 0,
            ScschOraseq: 6409208,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: 5,
            ScschPrevCertQty: 0,
            ScschPrevHldbkAmt: 0.5,
            ScschPrevHldbkRlsAmt: 0,
            ScschScmstOraseq: 19398807,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "001",
            ScschTaskName: "Specifications ",
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "CA40A0F0CF58124AE05313061FACC945",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA40A0F0CF58124AE05313061FACC945",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA40A0F0CF58124AE05313061FACC945",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
        {
            ScschAmt: 1000,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "2",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: 5,
            ScschComplPct: 0.5,
            ScschComplQty: 0,
            ScschContCode: "PAYREQ-0016",
            ScschCurrHldbkAmt: 0,
            ScschCurrHldbkRlsAmt: 0,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-23T16:11:56-04:00",
            ScschIuUpdateDate: "2021-08-23T16:25:57-04:00",
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: null,
            ScschLstAmt: 0,
            ScschOraseq: 6409209,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: 5,
            ScschPrevCertQty: 0,
            ScschPrevHldbkAmt: 0.5,
            ScschPrevHldbkRlsAmt: 0,
            ScschScmstOraseq: 19398807,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "002",
            ScschTaskName: "Designs ",
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "CA40A0F0CF5D124AE05313061FACC945",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA40A0F0CF5D124AE05313061FACC945",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA40A0F0CF5D124AE05313061FACC945",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
        {
            ScschAmt: 500,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "2",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: 2,
            ScschComplPct: 0.4,
            ScschComplQty: 0,
            ScschContCode: "PAYREQ-0014",
            ScschCurrHldbkAmt: 0,
            ScschCurrHldbkRlsAmt: 0,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-05T13:26:29-04:00",
            ScschIuUpdateDate: "2021-08-05T13:46:02-04:00",
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: null,
            ScschLstAmt: 0,
            ScschOraseq: 6407339,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: 2,
            ScschPrevCertQty: 0,
            ScschPrevHldbkAmt: 0.2,
            ScschPrevHldbkRlsAmt: 0,
            ScschScmstOraseq: 19345705,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "002",
            ScschTaskName: "Designs ",
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "C8D438258E000A83E05313061FAC8BB5",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/C8D438258E000A83E05313061FAC8BB5",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/C8D438258E000A83E05313061FAC8BB5",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
        {
            ScschAmt: 500,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "1000",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: 2,
            ScschComplPct: 0.4,
            ScschComplQty: 0,
            ScschContCode: "PAYREQ-0014",
            ScschCurrHldbkAmt: 0,
            ScschCurrHldbkRlsAmt: 0,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-05T13:26:29-04:00",
            ScschIuUpdateDate: "2021-08-05T13:46:02-04:00",
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: null,
            ScschLstAmt: 0,
            ScschOraseq: 6407338,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: 2,
            ScschPrevCertQty: 0,
            ScschPrevHldbkAmt: 0.2,
            ScschPrevHldbkRlsAmt: 0,
            ScschScmstOraseq: 19345705,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "001",
            ScschTaskName: "Specifications ",
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "C8D438258DFB0A83E05313061FAC8BB5",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/C8D438258DFB0A83E05313061FAC8BB5",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/C8D438258DFB0A83E05313061FAC8BB5",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
        {
            ScschAmt: 1000,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "L",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: null,
            ScschComplPct: null,
            ScschComplQty: null,
            ScschContCode: "PAYREQ-0015",
            ScschCurrHldbkAmt: null,
            ScschCurrHldbkRlsAmt: null,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-23T09:40:01-04:00",
            ScschIuUpdateDate: null,
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: "Landscaping",
            ScschLstAmt: null,
            ScschOraseq: 6409207,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: null,
            ScschPrevCertQty: null,
            ScschPrevHldbkAmt: null,
            ScschPrevHldbkRlsAmt: null,
            ScschScmstOraseq: 19398482,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "002",
            ScschTaskName: null,
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "CA3B275288B936C4E05313061FAC23CB",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA3B275288B936C4E05313061FAC23CB",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA3B275288B936C4E05313061FAC23CB",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
        {
            ScschAmt: 1000,
            ScschApexpCode: null,
            ScschBillCode: null,
            ScschCatCode: "1000",
            ScschChgCode: "000",
            ScschCompCode: "10",
            ScschComplAmt: null,
            ScschComplPct: null,
            ScschComplQty: null,
            ScschContCode: "PAYREQ-0015",
            ScschCurrHldbkAmt: null,
            ScschCurrHldbkRlsAmt: null,
            ScschFullyPurchasedFlag: "N",
            ScschHldbkPct: 10,
            ScschIuCreateDate: "2021-08-23T09:40:01-04:00",
            ScschIuUpdateDate: null,
            ScschJbBillCode: null,
            ScschJbContCode: null,
            ScschJobCode: "PAYREQ",
            ScschLineChangedFlag: null,
            ScschLineNum: null,
            ScschLinkedPmBiditemOraseq: null,
            ScschLongDesc: "Survey",
            ScschLstAmt: null,
            ScschOraseq: 6409206,
            ScschOrderNum: null,
            ScschPhsCode: "100",
            ScschPmItemOraseq: null,
            ScschPrevCertAmt: null,
            ScschPrevCertQty: null,
            ScschPrevHldbkAmt: null,
            ScschPrevHldbkRlsAmt: null,
            ScschScmstOraseq: 19398482,
            ScschSiLineNum: null,
            ScschSiMastJobCode: null,
            ScschSiNum: null,
            ScschSiRevisNum: null,
            ScschSubcontrCode: "NONE",
            ScschTaskCode: "001",
            ScschTaskName: null,
            ScschTax1Code: null,
            ScschTax1TaxableFlag: "Y",
            ScschTax2Code: null,
            ScschTax2TaxableFlag: null,
            ScschTax3Code: null,
            ScschTax3TaxableFlag: "Y",
            ScschTax4Code: null,
            ScschTax4TaxableFlag: null,
            ScschTax5Code: null,
            ScschTax5TaxableFlag: null,
            ScschTendpackLineNum: null,
            ScschUnit: null,
            ScschUnitRate: 0,
            ScschVenCode: "ZZ-WMT",
            ScschWbsvCode1: null,
            ScschWbsvCode2: null,
            ScschWbsvCode3: null,
            ScschWbsvCode4: null,
            ScschWbsvPciLineOraseq: null,
            ScschWmCode: "$",
            ScschVUuid: "CA3B275288B436C4E05313061FAC23CB",
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA3B275288B436C4E05313061FAC23CB",
                    name: "scsched",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched/CA3B275288B436C4E05313061FAC23CB",
                    name: "scsched",
                    kind: "item",
                },
            ],
        },
    ],
    count: 6,
    hasMore: false,
    limit: 50,
    offset: 0,
    links: [
        {
            rel: "self",
            href:
                "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/scsched",
            name: "scsched",
            kind: "collection",
        },
    ],
};

SUBdummyData.items.forEach(i => i._type = 'SUB');

export default SUBdummyData;
