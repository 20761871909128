/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { MarkupBaseJson, MarkupFillType } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { RotateTooltipElement } from '../markup-canvas-elements/markup.rotate-tooltip.element';
import { TextboxElementCanvas } from '../markup-canvas-elements/markup.textbox-canvas.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupTextBoxItem extends MarkupBaseItem {
    private _uniqueId: string;
    private textboxHTML: TextboxElementCanvas;
    private _textboxElementId: string | null = null;

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupText';
        this.shapeName = 'Text Box';
        this._uniqueId = this.uniqueId;
        const rectangleItem = this;
        this.rotateTooltip = new RotateTooltipElement();
        this.textboxHTML = new TextboxElementCanvas(
            () => this.blurTextArea(),
            () => this.triggerOnMarkupUpdated(),
        );
        this.textboxHTML.canRotateOnFocus = true;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(true);
        this.redlineBounding.setGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                rectangleItem.gripPointDragStartCallback(point, type);
                this.blurTextArea()
            },
            (point: Communicator.Point2) => {
                rectangleItem.gripPointDragMoveCallback(point);
            },
            (point: Communicator.Point2) => {
                if (this.redlineBounding) this.redlineBounding.setTextboxBoundingPointerEvent(false);
                rectangleItem.gripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                rectangleItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2, tooltipPos: Communicator.Point2) => {
                rectangleItem.rotateGripPointDragMoveCallback(point, tooltipPos);
            },
            (point: Communicator.Point2) => {
                rectangleItem.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingboxDoubleClickCallback(() => {
            if (!this._isCanEdit) return;
            rectangleItem.focusTextArea();
        })
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }
    public setTextContent(content: string): void {
        this.textboxHTML.setTextContent(content);
    }
    private _update(): void {
        if (!this._point1) return;
        this.textboxHTML.setStrokeWidth(this._lineWeight);
        this.textboxHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.textboxHTML.setFillType(this._fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None);
        this.textboxHTML.setFillColor(new Communicator.Color(this._fillColor.r, this._fillColor.g, this._fillColor.b));
        this.textboxHTML.setFillOpacity(this._lineOpacity);

        this.textboxHTML.setTextColor(MathHelper.RGBToHexA(this._textColor));
        this.textboxHTML.setTextBold(this._textIsBold);
        this.textboxHTML.setTextItalic(this._textIsItalic);
        this.textboxHTML.setTextUnderline(this._textIsUnderline);
        this.textboxHTML.setTextFontFamily(this._textFontFamily);
        this.textboxHTML.setTextFontSize(this._textFontSize);

        this.textboxHTML.setRotation(this._rotation);
        if (this.redlineBounding) {
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._point1.copy()),
        );
        let secondPoint = firstPoint.copy();
        if (this._point2 !== null) {
            secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._point2.copy()),
            );

            // Calculate size of rectangle
            const b = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
            const a = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
            const size = Communicator.Point2.subtract(a, b);

            this.textboxHTML.setFirstPoint(b);
            this.textboxHTML.setSize(size);
            this._isReady = true;
            this.vertices = [b, new Communicator.Point2(a.x, b.y),
                a, new Communicator.Point2(b.x, a.y)];
        }

        this.updateBoundingBox(firstPoint, secondPoint);
    }
    updateBoundingBox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        if (!firstPoint || !secondPoint) return;
        const pos = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
        const size = this.textboxHTML.getSize();
        const stroke = this.textboxHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - stroke / 2, pos.y - stroke / 2);
        if (this.redlineBounding) {
            this.redlineBounding.setCenter(firstPoint, secondPoint);
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
        }
    }

    blurTextArea(): void {
        if (this.redlineBounding) {
            this.redlineBounding.setBoundingBoxPointerEvent(true);
            this.redlineBounding.setEditingTextbox(false);
        }
        this.textboxHTML.blurTextArea();
    }

    focusTextArea(): void {
        if (this.redlineBounding) {
            this.redlineBounding.setBoundingBoxPointerEvent(false);
            this.redlineBounding.setEditingTextbox(true);
        }
        this.textboxHTML.focusTextArea();
    }
    remove(): void {
        if (this._textboxElementId) {
            this._viewer.markupManager.removeMarkupElement(this._textboxElementId);
            this._textboxElementId = null;
        }
        super.remove();
    }
    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._textboxElementId = this._viewer.markupManager.addMarkupElement(
                    this.textboxHTML.getTextboxCanvas(),
                );
                if (this.rotateTooltip) {
                    this.rotateTooltipId = this._viewer.markupManager.addMarkupElement(
                        this.rotateTooltip.getRotateTooltip(),
                    );
                }
            }
        } else {
            this._redlineElementId && this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
            this._textboxElementId && this._viewer.markupManager.removeMarkupElement(this._textboxElementId);
            this._textboxElementId = null;
            this.rotateTooltipId && this._viewer.markupManager.removeMarkupElement(this.rotateTooltipId);
            this.rotateTooltipId = null;
        }
        this.handleBoundingRectInteraction();
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupTextBoxItem";
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineWeight: this._lineWeight,
            lineOpacity: this._lineOpacity,
            fillColor: this._fillColor,
            fillColorOption: this._fillColorOption,
            textFontSize: this._textFontSize,
            textColor: this._textColor,
            textFontFamily: this._textFontFamily,
            textIsBold: this._textIsBold,
            textIsItalic: this._textIsItalic,
            textIsUnderline: this._textIsUnderline,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            point1: this._point1?.copy(),
            point2: this._point2?.copy(),
            textContent: this.textboxHTML.getTextContent(),
            uniqueId: this.uniqueId,
            rotation: this._rotation,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
            title: this.title,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineWeight = data.lineWeight;
        this._lineOpacity = data.lineOpacity;
        this._fillColor = data.fillColor
        this._fillColorOption = data.fillColorOption;
        this._textFontSize = data.textFontSize;
        this._textColor = data.textColor;
        this._textFontFamily = data.textFontFamily;
        this._textIsBold = data.textIsBold;
        this._textIsItalic = data.textIsItalic;
        this._textIsUnderline = data.textIsUnderline;
        this.textboxHTML.setTextContent(data.textContent);
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._point1 = data.point1;
        this._point2 = data.point2;
        this._rotation = data.rotation;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
        this.title = data.title;
    }

    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.updateOppositePoint();
        this.textboxHTML.setTransitionDuration(0);
    }
    rotateGripPointDragMoveCallback(point: Communicator.Point2, tooltipPos: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.getHTMLElement().getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) + Math.PI / 2) * 180 / Math.PI - 180) % 360;
            this.setRotation(rotateAngle);
            if (this.rotateTooltip) {
                this.rotateTooltip.setPosition(tooltipPos);
                this.rotateTooltip.setRotateValue(rotateAngle);
                this.rotateTooltip.setVisible(true);
                this.rotateTooltip.draw();
            }
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
                this.redlineBounding.updateGripPoints();
            }
        }
    }
    updateRotateTransform(rotateAngle: number) {
        super.updateRotateTransform(rotateAngle);
        if (this.textboxHTML.textboxCanvas) this.textboxHTML.textboxCanvas.style.transform = `rotate(${rotateAngle}deg)`;
    }

    onDragStart(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this._point1!.add(c);
            this._point2!.add(c);
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }
    hit(point: Communicator.Point2) {
        if (this.vertices.length < 1) return false;
        let ct = Communicator.Point2.zero();
        if (this.redlineBounding) ct = this.redlineBounding.center;
        const rotatedVerties = this.vertices.map(v => MathHelper.convertPointWithRotation(v, ct, this._rotation));
        return MathHelper.polyPointCollide(rotatedVerties, point);
    }

    setMarkupVisible(visible: boolean): void {
        this.textboxHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        if (this.textboxHTML.textboxCanvas) this.textboxHTML.textboxCanvas.style.display = visible ? 'initial' : 'none';
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.textboxHTML.textboxCanvas!;
    }

    onAutoSizeCallback(): void {
        const a = this._viewer.view;
        const fPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point1!.copy()));
        const sPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point2!.copy()));
        const size = new Communicator.Point2(Math.abs(fPoint.x - sPoint.x), Math.abs(fPoint.y - sPoint.y));
        const deg = this.redlineBounding!.getRotation();

        const offset = -size.y + this.textboxHTML.getStrokeWidth() + this.textboxHTML.autoSize.y;
        const p2 = new Communicator.Point2(
            sPoint.x,
            sPoint.y + Math.cos(deg) * offset)

        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
        const nVec = cVec.scale(1 / (cVec.length()));
        const newMPoint2 = this.mPoint2.copy().add(nVec.scale(offset));
        this.mPoint2.assign(newMPoint2);

        this._point2!.assign(a.getCamera().getCameraPlaneIntersectionPoint(p2, a)!);
        this._update()
        this.textboxHTML.getTextboxCanvas();
    }
}

