import { BookmarkCustom, FilesTree, iStatesObject, iStatesObjectResponse, PayloadView, SaveView, ValueProperties, Views } from "common/define";
import { SystemConstants } from "common/system-constants";
import { MarkupEntity, MarkupViewEntities, ResponseMarkups } from "common/type-markup";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Utils from "utils/utils";
import HttpClient from "./http-client";
import {APIHosts, ApiReduxHelpers} from "./reduxHelpers";

interface ModelTreeResponse {
    id: string;
    ProductOccurence: FilesTree;
}
// interface ModelTreeRvtResponse {
//     id: string;
//     modelRoot: FilesTree
// }

class BimApiController extends ApiReduxHelpers {
    constructor() {
        super();
    }
    ApiHost = APIHosts.Bim
    ApiStreamHost = APIHosts.Stream;
    // begin model tree
    getTreeStringWithExtension(modelFileId: string, extension: string): Observable<FilesTree | null> {
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_MODEL_STRING_WITH_EX(modelFileId, extension);//"60ed644f9d0beef79337199c", extension);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(re => {
            if (re) {
                if (extension === 'rvt' || extension === 'ifc' || extension === 'nwd') {
                    try {
                        const re2 = JSON.parse(re as string);
                        const firstNode: FilesTree = {
                            id: '-1',
                            name: 'Root node',
                            childs: re2 as FilesTree[]
                        }
                        return firstNode
                    }
                    catch (error) {
                        console.log(error);
                    }
                    const newRe = (re as string).replaceAll("ObjectId(", "").replaceAll(")", "");
                    try {
                        const re2 = JSON.parse(newRe);
                        const firstNode: FilesTree = {
                            id: '-1',
                            name: 'Root node',
                            childs: re2 as FilesTree[]
                        }
                        return firstNode
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    try {
                        const parsedObj = JSON.parse(re as string);
                        const res2 = parsedObj as ModelTreeResponse;
                        return res2.ProductOccurence
                    }
                    catch (error) {
                        console.log(error);
                    }
                    const newRe = (re as string).replaceAll("ObjectId(", "").replaceAll(")", "");
                    try {
                        const parsedObj = JSON.parse(newRe);
                        const res2 = parsedObj as ModelTreeResponse;
                        return res2.ProductOccurence
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            }
            return null
        }));
    }

    getFlatModelTree(modelFileId: ModelFileId, extension: string): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_FLAT_MODEL_TREE(modelFileId, extension);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }

    getStateObject(modelFileId: ModelFileId): Observable<iStatesObjectResponse> {
        const api = SystemConstants.SERVER_BIM.API.STATES_OBJECT.GET(modelFileId);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as iStatesObjectResponse));
    }

    deleteStateObject(modelFileId: ModelFileId): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.STATES_OBJECT.DELETE(modelFileId);
        return HttpClient.delete(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }
    updateStateObject(data: iStatesObject[], modelFileId: ModelFileId): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.STATES_OBJECT.POST_UPDATE(modelFileId);
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, JSON.stringify(JSON.stringify(data))).pipe(map(res => res as string));
    }

    // end model tree

    // getModelFile
    getModelFileId(data: PayloadView): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_MODEL_FILE_ID;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, data).pipe(
            map(res => {
                return res as string
            }));
    }
    // CreateModelFile
    createModelFileId(data: PayloadView): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.CREAT_MODEL_FILE_ID;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, data, { timeout: 30000 }).pipe(
            map(res => {
                return res as string
            }));
    }

    // VIEW
    getSaveAndCadViews(data: PayloadView): Observable<Views | undefined> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.GET_ALL;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, data).pipe(
            map(res => {
                if (typeof res === 'object') {
                    return res as Views
                } else if (typeof res === 'string') {
                    const parse = Utils.parseJson(res);
                    if (parse) {
                        return parse as Views
                    }
                }
                return undefined
            }));
    }
    getView3DJson(streamLocation: string): Observable<any> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.GET_VIEW3D_JSON(streamLocation);
        return HttpClient.get(`${this.getHost(this.ApiStreamHost)}/${api}`);
    }
    getFileInfoJson(streamLocation: string): Observable<any> {
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_FILE_INFO_JSON(streamLocation);
        return HttpClient.get(`${this.getHost(this.ApiStreamHost)}/${api}`);
    }
    deleteView(id: string, modelFileId: ModelFileId): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.DELETE(id);
        return HttpClient.delete(`${this.getHost(this.ApiHost)}/${api}?ModelFileId=${modelFileId}`).pipe(map(res => res as string));
    }
    updateView(data: SaveView): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.POST_UPDATE(data.ModelFileId);
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, JSON.stringify(JSON.stringify(data))).pipe(map(res => res as string));
    }
    updateMarkupView(data: MarkupViewEntities): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.POST_UPDATE(data.modelFileId);
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, JSON.stringify(JSON.stringify(data))).pipe(map(res => res as string));
    }

    getAllMarkupView(modelFileId: ModelFileId): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.VIEWS.GET(modelFileId);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }
    // END VIEW

    //OLD PDF API
    getMarkups(modelFileId: string): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MARKUPS.GET_ALL(modelFileId);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }
    saveMarkups(modelFileId: string, data: ResponseMarkups): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MARKUPS.SAVE_ALL(modelFileId);
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, JSON.stringify(JSON.stringify(data))).pipe(map(res => res as string));
    }
    //OLD PDF API
    getPropertiesNode(modelFileId: ModelFileId, persistentId: NodeId, modelFileIdSheet?: ModelFileId): Observable<ValueProperties> {
        const externalPropertyInfo = {
            modelFileId,
            persitentId: `${persistentId}`,
            sheetModelId: modelFileIdSheet ?? ''
        }
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_PROPERTYS;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, externalPropertyInfo).pipe(
            map(properties => Utils.convertPropertiesResponse(properties))
        )
    }
    getPropertiesByArrNode(mainModelId: ModelFileId, nodeIds: NodeId[], subModelId?: ModelFileId, isSheetNodeId?: boolean): Observable<ValueProperties[]> {
        const postData = {
            modelFileId: mainModelId,
            nodeIds: nodeIds.map(v => '' + v),
            sheetModelId: subModelId ?? '',
            isSheetNodeId: isSheetNodeId ?? false
        }
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_PROPERTIES_BY_NODE;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, postData).pipe(
            map(arrProperties => Utils.convertArrPropertiesResponse(arrProperties))
        )
    }
    getExtProperty(postData: {modelFileId: ModelFileId, persistentIds: string[]}[]): Observable<ValueProperties[]> {
        // const postData = [{
        //     modelFileId: mainModelId,
        //     persistentIds: persistentIdsFinal,
        // }]
        const api = SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_PROPS;
        const bimHost = this.getHost(this.ApiHost)
        return HttpClient.post(`${bimHost}/${api}`, postData).pipe(
            map(arrProperties => Utils.convertArrPropertiesResponse(arrProperties))
        )
    }
    cudBookmark(modelFileId: string, data: BookmarkCustom[]): Observable<unknown> {
        const api = SystemConstants.SERVER_BIM.API.BOOKMARKS.POST(modelFileId);
        const mapData = JSON.stringify(JSON.stringify(data));
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, mapData)
    }
    getBookmarks(modelFileId: string): Observable<BookmarkCustom[]> {
        const api = SystemConstants.SERVER_BIM.API.BOOKMARKS.GET_ALL(modelFileId);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(
            map(re => {
                if (re && typeof re === 'string') {
                    let parseRe = JSON.parse(re);
                    parseRe = Array.isArray(parseRe) ? parseRe : [parseRe];
                    return parseRe as BookmarkCustom[]
                }
                return []
            })
        )
    }

    // markupView CRUD

    // markup 3d

    getMarkup3D(modelFileId: ModelFileId): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MARKUP_ENTITIES.GET_ALL(modelFileId);
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }
    updateMarkup3D(modelFileId: ModelFileId, markup: MarkupEntity): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MARKUP_ENTITIES.UPDATE(markup.uniqueId);
        return HttpClient.put(`${this.getHost(this.ApiHost)}/${api}?modelfileId=${modelFileId}`, JSON.stringify(JSON.stringify(markup))).pipe(map(res => res as string));
    }
    deleteMarkup3D(modelFileId: ModelFileId, uniqueIds: string[]): Observable<string> {
        const api = SystemConstants.SERVER_BIM.API.MARKUP_ENTITIES.DELETE;
        return HttpClient.put(`${this.getHost(this.ApiHost)}/${api}?modelfileId=${modelFileId}`, JSON.stringify(JSON.stringify(uniqueIds))).pipe(map(res => res as string));
    }
    // end markup 3d
}

export const BimApi = new BimApiController();
