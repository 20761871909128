// old data is no longer used
// replaced with LinkedObjectdummy.ts
const PCIdummyData = {
    items: [
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000041",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-20T10:19:47-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Please review attached",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-21",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "RFI-0010",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C17580E7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17580E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17580E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000031",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-10-28T12:18:08-04:00",
            CmmIuUpdateDate: "2016-02-23T11:32:53-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Potential place holder for change order",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0060",
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "STW",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-10-28",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2015-10-28T12:18:48-04:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "GORD",
            CmmVUuid: "CE338C17580F7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17580F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17580F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "R",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0046",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: "2015-10-28",
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-10-28T15:55:58-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Demolition",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-10-28",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C1758107635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758107635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758107635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0001",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-11-20T09:10:13-05:00",
            CmmIuUpdateDate: "2018-06-12T10:10:28-04:00",
            CmmJobCode: "15002",
            CmmLumpsumFlag: null,
            CmmName: "Demolish Warehouse",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-11-20",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Demolish old parts storage warehouse",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C1758117635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758117635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758117635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00019",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-12-04T11:59:11-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "HVAC Work - Not sure of scope",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-12-04",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000837",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758167635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758167635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758167635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00017",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-25T14:37:00-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "FW: RFP for Mod 5 Rev 2",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "DMISSUE",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPPROC",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-25",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "IS00046",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758187635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758187635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758187635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000010",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-22T11:36:18-05:00",
            CmmIuUpdateDate: "2017-12-12T14:56:42-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End HVAC Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0009",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "DOCREV",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-22",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "DW15.0022 - 7",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-01-22T11:46:04-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758197635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758197635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758197635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0002",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-25T14:45:29-05:00",
            CmmIuUpdateDate: "2017-02-23T09:55:08-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End HVAC Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-25",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Higher End HVAC Unit",
            CmmSourceText: null,
            CmmSrcId: "RFI-0042",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-01-25T15:05:20-05:00",
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17581A7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000017",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-02T14:45:41-05:00",
            CmmIuUpdateDate: "2016-03-07T16:07:11-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Hardwood flooring upgrade",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-02",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Hardwood flooring upgrade",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C17581C7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "O",
            CmmClvValueCode1: "Sort Data ",
            CmmClvValueCode2: "Sort Data ",
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0006",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-09-06T15:42:13-04:00",
            CmmIuUpdateDate: "2022-05-26T11:33:53-04:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "all lights on a timer",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-09-09",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "See spec sheet. ",
            CmmSourceText: null,
            CmmSrcId: "00000218",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "DEMO",
            CmmVUuid: "CE338C17581D7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00015",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-12-17T14:43:52-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher end Plumbing Fixture ",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-12-17",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17581E7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000038",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-05T16:55:55-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Water damage on the 3rd floor",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-06",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17581F7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17581F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00016",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-06T12:18:36-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Exhibit #2129919",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMCMN",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-06",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "COM00002",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "N",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758207635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758207635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758207635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: "2016-01-06",
            CmmChgSignedByOwnerDate: "2016-01-05",
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00020",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: "2016-01-04",
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-07T15:31:54-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName:
                "Sub-grade to ensure 450mm depth of Granular Road Base\r\n",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: "2016-01-05",
            CmmPropSignedByOwnerDate: "2016-01-05",
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-01",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Due to soil conditions, Sub-grade has not maintained it's depth. ",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-01-07T14:08:39-05:00",
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C1758227635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758227635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758227635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000013",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-27T15:39:58-05:00",
            CmmIuUpdateDate: "2018-02-26T08:53:21-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "RFP for Mod 5 Rev 2",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0002",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: "2016-01-27",
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-27",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-05-20T14:30:11-04:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758247635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758247635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758247635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0018",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-10-25T13:38:33-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Additional Labor for Room 100A",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-10-25",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Additional Labor for Room 100A",
            CmmSourceText: null,
            CmmSrcId: "00000250",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "DEMO",
            CmmVUuid: "CE338C1758257635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758257635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758257635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00001",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: 5,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-04-12T17:51:57-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Flooring Change in Main Reception Area (With Backcharge)",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: "2015-04-12",
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: "Materials",
            CmmRefCmmCode: null,
            CmmRefDate: "2015-04-12",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Flooring Change in Main Reception Area - Client requested we lay 12x12 marble tile in the reception area. This will replace the carpet in the design sheets. ",
            CmmSourceText: "Client",
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758277635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758277635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758277635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: "2015-02-23",
            CmmChgSignedByOwnerDate: "2015-02-26",
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00011",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: "2015-02-27",
            CmmEffectiveEndDate: "2015-03-12",
            CmmEffectiveStartDate: "2015-03-02",
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: 5,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-02-19T09:50:56-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Flooring Change in Main Reception Area (With Backcharge)",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "DOCREV",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: "2015-02-23",
            CmmPropSignedByOwnerDate: "2015-03-23",
            CmmReasonText: "Materials",
            CmmRefCmmCode: null,
            CmmRefDate: "2015-02-19",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Flooring Change in Main Reception Area - Client requested we lay 12x12 marble tile in the reception area. This will replace the carpet in the design sheets.",
            CmmSourceText: "Client",
            CmmSrcId: "DW15.0004 - 1",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2015-03-04T14:41:59-05:00",
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758287635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758287635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758287635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000001",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-02-27T14:33:09-05:00",
            CmmIuUpdateDate: "2016-09-26T11:16:12-04:00",
            CmmJobCode: "CM1001",
            CmmLumpsumFlag: null,
            CmmName: "Extra outlets",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-02-27",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Add two extra outlets in boardroom for AV components",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C1758297635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758297635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758297635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "O",
            CmmClvValueCode1: "Sort Data A",
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0043",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-05-26T11:34:46-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-10-15T21:42:14-04:00",
            CmmIuUpdateDate: "2022-05-26T11:34:46-04:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Installed Ten Additional Light Fixtures",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-10-15",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "GORD",
            CmmVUuid: "CE338C17582D7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0044",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-10-15T21:48:28-04:00",
            CmmIuUpdateDate: "2016-02-26T08:06:23-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Additional Light Fixture",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-10-15",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2015-10-15T21:56:55-04:00",
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "GORD",
            CmmVUuid: "CE338C17582E7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0045",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-10-15T21:58:28-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "fixtures for bedroom",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-10-15",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "GORD",
            CmmVUuid: "CE338C17582F7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17582F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000018",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-03T14:33:14-05:00",
            CmmIuUpdateDate: null,
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Additional raceway boxes",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-03",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C1758317635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758317635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758317635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000040",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-14T16:03:12-05:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Additional HVAC Work",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-14",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C1758337635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758337635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758337635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000043",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-08T14:22:10-05:00",
            CmmIuUpdateDate: "2016-02-08T14:23:58-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Higher End Equipment",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "DMISSUE",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-08",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Higher End Equipment",
            CmmSourceText: null,
            CmmSrcId: "IS00024",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758357635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758357635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758357635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000019",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: 5,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-09T10:25:22-05:00",
            CmmIuUpdateDate: "2017-12-12T13:13:12-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End HVAC Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-09",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000031",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758367635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758367635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758367635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000020",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-17T15:27:48-05:00",
            CmmIuUpdateDate: "2017-12-12T13:35:42-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "HVAC New Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-17",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "HVAC New Unit",
            CmmSourceText: null,
            CmmSrcId: "00000033",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-17T15:31:25-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758387635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758387635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758387635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000008",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-18T14:48:14-05:00",
            CmmIuUpdateDate: "2018-02-12T13:19:36-05:00",
            CmmJobCode: "16551",
            CmmLumpsumFlag: null,
            CmmName: "Please review the attached steel door sample colors",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0003",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-19",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-26T08:06:15-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C1758397635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758397635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758397635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000001",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-04-06T16:02:02-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "14600",
            CmmLumpsumFlag: null,
            CmmName: "RFI 0061 Wall Width Changes to Accommodate Plumbing  ",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-04-06",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Fur Out of Several Walls to Accommodate Plumbing ",
            CmmSourceText: null,
            CmmSrcId: "00000001",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17583A7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: "2016-02-17",
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000042",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-04T13:54:15-05:00",
            CmmIuUpdateDate: "2016-02-04T14:05:52-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "COR for Repairs",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: "2016-02-05",
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-05",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Contractor requesting additional repairs. need to approve. ",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-04T14:05:52-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C17583B7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583B7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583B7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "T",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "TCO00005",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: 0,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-03-10T16:30:07-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Green screen labor to casual sub",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-03-10",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "We bought it out with a local sub, who can perform work cheaper then we can.",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "TFR",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17583C7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00001",
            CmmCompCode: "20",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:28-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-03-11T10:00:27-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "BE001TP",
            CmmLumpsumFlag: null,
            CmmName: "Owner wants more SQF of Green Roof",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDINGF",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-03-11",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2015-03-11T10:00:47-04:00",
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17583D7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0005",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-04-30T11:18:46-04:00",
            CmmIuUpdateDate: "2017-10-15T17:00:44-04:00",
            CmmJobCode: "10001",
            CmmLumpsumFlag: null,
            CmmName: "Extra flooring required",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-04-30",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Extra flooring required",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C17583E7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000057",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2017-02-03T11:04:10-05:00",
            CmmIuUpdateDate: "2017-12-12T13:06:43-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End HVAC Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2017-02-03",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "See attached for details...",
            CmmSourceText: null,
            CmmSrcId: "RFI-0321",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C17583F7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17583F7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000022",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-19T16:29:57-05:00",
            CmmIuUpdateDate: "2017-12-12T13:12:14-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "HVAC Unit Installed ",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-19",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "HVAC Unit Installed ",
            CmmSourceText: null,
            CmmSrcId: "RFI-0087",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-19T16:32:04-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758417635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758417635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758417635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000023",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-23T11:53:49-05:00",
            CmmIuUpdateDate: "2017-10-30T13:23:39-04:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "EXAMPLE CHANGE: RFP for Mod 5 Rev 2",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-23",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "RFI-0096",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-23T11:55:37-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758427635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758427635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758427635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000025",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-26T09:38:54-05:00",
            CmmIuUpdateDate: "2017-10-30T13:23:40-04:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End HVAV Unit",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-26",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-03-01T14:23:39-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758437635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758437635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758437635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000026",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-26T15:44:30-05:00",
            CmmIuUpdateDate: "2017-12-12T13:30:37-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher end HVAC Unit 002",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-26",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Higher end HVAC Unit 002",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758447635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758447635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758447635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00018",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: 5,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-02-25T15:00:04-05:00",
            CmmIuUpdateDate: "2017-10-30T13:23:40-04:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Additional Unit to complete work.",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-02-25",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-02-25T15:04:46-05:00",
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758457635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758457635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758457635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000027",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-03-01T11:02:51-05:00",
            CmmIuUpdateDate: "2017-12-12T15:05:41-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "RFP for Mod 5 Rev 2",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0007",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-03-02",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "RFI-0084",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-03-01T11:09:10-05:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C1758467635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758467635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758467635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00019",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-03-01T15:32:00-05:00",
            CmmIuUpdateDate: "2017-12-12T14:56:58-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Broken exhibit TV",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0011",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-03-01",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000015",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-04-18T13:28:50-04:00",
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "CHET",
            CmmVUuid: "CE338C1758477635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758477635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758477635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "OCO00015",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-08-21T13:51:40-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "10001",
            CmmLumpsumFlag: null,
            CmmName: "Notification Summary (54)",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-08-21",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000103",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "OCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "DEMO",
            CmmVUuid: "CE338C1758487635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758487635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758487635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0060",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:27-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-03-07T09:52:28-05:00",
            CmmIuUpdateDate: null,
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Extra junction boxes",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-03-07",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C1758497635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758497635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758497635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: "2016-03-15",
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0003",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: "2016-03-11",
            CmmEffectiveEndDate: "2016-03-31",
            CmmEffectiveStartDate: "2016-03-21",
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: 35,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-03-07T12:44:54-05:00",
            CmmIuUpdateDate: "2018-02-26T08:53:21-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Additional framing",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: "OCO.0002",
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: "2016-03-15",
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-03-07",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Additional framing",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-07-05T11:06:11-04:00",
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C17584A7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584A7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SCO00075",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-04-24T17:04:52-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Project Drawings - artist rendition",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "N",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PREPEND",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-04-24",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000194",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "MARCEL",
            CmmVUuid: "CE338C17584B7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584B7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584B7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SCO00077",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-05-22T13:20:04-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "PCI document",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-05-22",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C17584C7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584C7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SCO00078",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-05-22T13:20:37-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Document for PCI",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-05-22",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "BILL",
            CmmVUuid: "CE338C17584D7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584D7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "N",
            CmmAutoCalcMarkupFlag: "N",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "SIC0003",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2018-12-13T04:47:19-05:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2013-08-30T13:14:21-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "1503",
            CmmLumpsumFlag: null,
            CmmName: "Can you give me a quote for structural support 001",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "P",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "PENDING",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2013-08-30",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: null,
            CmmSourceText: null,
            CmmSrcId: "00000213",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: null,
            CmmTypeCode: "SCO",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "DEMO",
            CmmVUuid: "CE338C17584E7635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C17584E7635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000034",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2015-03-10T16:10:19-04:00",
            CmmIuUpdateDate: "2016-01-28T14:46:39-05:00",
            CmmJobCode: "13530",
            CmmLumpsumFlag: null,
            CmmName: "Higher Quality Stone in for NW Patio",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: null,
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2015-03-10",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc:
                "Client requests a higher quality stone to be used in the NW patio. Approximately 500 SQF. They would like to use Cambridge Pavingstone #1101119",
            CmmSourceText: null,
            CmmSrcId: null,
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2015-03-11T17:37:12-04:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758517635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758517635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758517635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
        {
            CmmActionCode: null,
            CmmAllowance: "N",
            CmmAprvFlag: "Y",
            CmmAutoCalcMarkupFlag: "Y",
            CmmBchNum: null,
            CmmBiditemFlag: null,
            CmmBillCatCode: null,
            CmmBillCode: null,
            CmmBillMethCode: "D",
            CmmBillName: null,
            CmmBillPhsCode: null,
            CmmBillRevisAmt: null,
            CmmCalcProjAmtMethod: "A",
            CmmChgSentToOwnerDate: null,
            CmmChgSignedByOwnerDate: null,
            CmmClassCode: "E",
            CmmClvValueCode1: null,
            CmmClvValueCode2: null,
            CmmClvValueCode3: null,
            CmmClvValueCode4: null,
            CmmClvValueCode5: null,
            CmmClvValueCode6: null,
            CmmCode: "EXT0000009",
            CmmCompCode: "10",
            CmmCtrlJobCode: "ALL",
            CmmCycleNum: null,
            CmmDueToOwnerDate: null,
            CmmEffectiveEndDate: null,
            CmmEffectiveStartDate: null,
            CmmFundsourceCode: null,
            CmmGlobalUpdateDate: "2022-10-11T03:32:39-04:00",
            CmmImpactDays: null,
            CmmInvCode: null,
            CmmIuCreateDate: "2016-01-15T15:41:44-05:00",
            CmmIuUpdateDate: "2017-02-08T18:44:47-05:00",
            CmmJobCode: "15300",
            CmmLumpsumFlag: null,
            CmmName: "Higher End Plumbing Fixtures",
            CmmOrigCmmCode: null,
            CmmOrigOwnerAllowanceAmt: null,
            CmmOwnerAllowanceAmt: null,
            CmmOwnerChgCode: null,
            CmmPendFlag: "A",
            CmmPmFlag: "Y",
            CmmPmObjectCode: "PMRFI",
            CmmPostContrFlag: "N",
            CmmPostDate: null,
            CmmPostDateDummy: null,
            CmmPostingFlag: "UNPOSTED",
            CmmPrepostStatCode: "APPROVED",
            CmmPropIssuedToOwnerDate: null,
            CmmPropSignedByOwnerDate: null,
            CmmReasonText: null,
            CmmRefCmmCode: null,
            CmmRefDate: "2016-01-15",
            CmmRfqDueDate: null,
            CmmRfqLimitAmt: null,
            CmmRfqProceedFlag: null,
            CmmScopeDesc: "Higher End Plumbing Fixtures",
            CmmSourceText: null,
            CmmSrcId: "RFI-0025",
            CmmStatCode: null,
            CmmStatCodeUpdateDate: "2016-04-15T14:18:14-04:00",
            CmmTypeCode: "EXT",
            CmmUpdPcComplFlag: "Y",
            CmmUpdProjAmtFlag: "Y",
            CmmUser: "STEVE",
            CmmVUuid: "CE338C1758537635E05313061FAC8DDE",
            CmmWorkflowStatus: null,
            links: [
                {
                    rel: "self",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758537635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
                {
                    rel: "canonical",
                    href:
                        "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast/CE338C1758537635E05313061FAC8DDE",
                    name: "cmmast",
                    kind: "item",
                },
            ],
        },
    ],
    count: 50,
    hasMore: true,
    limit: 50,
    offset: 0,
    links: [
        {
            rel: "self",
            href:
                "https://solutionsr12mob.cmicpaas.com:443/cmictest/cm-rest-api/rest/1/cmmast",
            name: "cmmast",
            kind: "collection",
        },
    ],
};

PCIdummyData.items = PCIdummyData.items.map(i => {i._type = 'PCI'; return i});

export default PCIdummyData;
