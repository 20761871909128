import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BimApi } from "api/bim.api";
import { ConversionApi } from "api/conversion.api";
import { FileInfo, FileInfoResponse, PayloadOriginFile } from "common/define";
import { RootEpic } from "common/type-state";
import { merge, Observable } from "rxjs";
import { catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { setAppLoading } from "./app.slice";
import { FileListHelper } from './helper';
import { closeViewerObser } from "./helper/multiViewer.helper";
import { closeViewerComplete, firstFileDisplay } from "./multiViewer.slice";
import { GlobalState } from "common/global";

const initState: FileListHelper.FilesListState = {
    filesList: [],
    filesOrigin: [],
    loading: false,
    filesListThumnail: [],
    refreshFileList: '',
    fileInfoJsonMap: {}
};

const filesListSlice = createSlice({
    name: 'filesList',
    initialState: initState,
    reducers: {
        getCacheFilesList: (state, action: PayloadAction<PayloadOriginFile[]>) => {
            state.loading = true;
        },
        deleteFile(state, action: PayloadAction<ViewId>) {
            return
        },
        updateFileListComplete(state, action: PayloadAction<FileListHelper.ResultFileListState>) {
            const { newFileList, newFileThumnail, newFilesOrigin } = action.payload;
            newFileList && (state.filesList = newFileList);
            newFileThumnail && (state.filesListThumnail = newFileThumnail);
            newFilesOrigin && (state.filesOrigin = newFilesOrigin);
            state.loading = false
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        errorFilesList: (state) => {
            state.loading = false;
        },
        uploadThumnail: (state, action: PayloadAction<{ file: FileInfo, thumnailSrc: string}>) => { return },
        refreshFilesList: (state, action: PayloadAction<undefined>) => {
            state.refreshFileList = Communicator.UUID.create();
        },
        getFileInfoJson(state, action) {
            state.loading = true;
        },
        setFileInfoJson(state, action: PayloadAction<{modelFileId: string, data: any}>) {
            const { modelFileId, data } = action.payload;
            state.fileInfoJsonMap[modelFileId] = data;
            GlobalState.setFileInfoJson(modelFileId, data);
            state.loading = false;
        },
    }
});

/**
 * Effect get cache file list + transform file item
 * @param action$
 * @param state$
 * @returns
 */
const filesList$: RootEpic = (action$, state$) => action$.pipe(
    filter(getCacheFilesList.match),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const list = action.payload;
        const preList = state.filesList.filesOrigin;
        const arrDiff = FileListHelper.filterListAdd(list, preList);
        if (arrDiff) {
            return ConversionApi.getCacheByListFile(arrDiff).pipe(
                concatMap(
                    (result) => new Observable<FileInfoResponse[]>(subscriber => {
                        const newResult: FileInfoResponse[] = [];
                        result.forEach(async x => {
                            if (x.modelFileId == null || x.modelFileId === '') {
                                if (x.streamLocation) {
                                    x.fileLocation = `${x.streamLocation}/${x.filename.split('.')[0]}.assemblytree.xml`;
                                    x.modelFileId = await BimApi.getModelFileId(x).toPromise();
                                    if (x.modelFileId == null || x.modelFileId === "")
                                        x.modelFileId = await BimApi.createModelFileId(x).toPromise();
                                    if (x.modelFileId != null && x.modelFileId !== "")
                                        ConversionApi.updateModelId({
                                            fileName: x.filename,
                                            baseFileId: x.baseFileId,
                                            baseMinorRev: x.baseMinorRev ?? 0,
                                            baseMajorRev: x.baseMajorRev ?? 0,
                                            modelFileId: x.modelFileId,

                                        }).subscribe();
                                }
                            }
                            newResult.push(x);
                            if (newResult.length === result.length) {
                                subscriber.next(newResult);
                                subscriber.complete();
                            }
                        });
                    })
                ),
                concatMap(
                    (newResult) => FileListHelper.addFilesList$(newResult, state.filesList)
                ),
                mergeMap(
                    resultAdd => {
                        if (preList.length > 0) {
                            return [
                                filesListSlice.actions.updateFileListComplete(resultAdd)
                            ]
                        } else {
                            return [
                                filesListSlice.actions.updateFileListComplete(resultAdd),
                                firstFileDisplay()
                            ]
                        }
                    }
                ),
                catchError(err => [errorFilesList()])
            )
        }
        return [filesListSlice.actions.setLoading(false)]
    })
)
const deleteFile$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteFile.match),
    withLatestFrom(state$),
    concatMap(([{ payload: viewId }, state]) => {
        return merge(
            [setAppLoading(true)],
            closeViewerObser(viewId, state.multiViewer).pipe(
                mergeMap(resultClose => {
                    return merge(
                        [closeViewerComplete(resultClose)],
                        FileListHelper.deleteFileObser(viewId, state.filesList).pipe(
                            mergeMap(resultDelete => [
                                setAppLoading(false),
                                filesListSlice.actions.updateFileListComplete(resultDelete)
                            ]),
                            catchError(err => [setAppLoading(false)])
                        )
                    )
                })
            )
        )
    }),

)

const uploadThumnail$: RootEpic = (action$, state$) => action$.pipe(
    filter(uploadThumnail.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { file, thumnailSrc } = action.payload;
        return ConversionApi.uploadThumnail(file, thumnailSrc).pipe(
            mergeMap(res => {
                console.log(res);
                return[]
            }),
            catchError(err => {
                console.log(err);
                return []
            })
        )
    })
)

const getFileInfoJson$: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(getFileInfoJson.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const viewId = action.payload;
            const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
            if (!fileInfo) {
                return [];
            }
            const info = state.filesList.fileInfoJsonMap[fileInfo.modelFileId];
            if (info) {
                return [];
            }
            return BimApi.getFileInfoJson(fileInfo.streamLocation).pipe(
                map(jsonRes => {
                    return setFileInfoJson({ modelFileId: fileInfo.modelFileId, data: jsonRes });
                }),
                catchError(err => {
                    console.log(err)
                    return [setLoading(false)];
                })
            )
        })
    );
}

export const FilesListEpics = [
    filesList$,
    deleteFile$,
    uploadThumnail$,
    getFileInfoJson$
];

export const {
    getCacheFilesList,
    errorFilesList,
    deleteFile,
    uploadThumnail,
    refreshFilesList,
    getFileInfoJson,
    setFileInfoJson,
    setLoading
} = filesListSlice.actions;
export default filesListSlice.reducer;
