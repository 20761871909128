import { KeyCode } from "common/define";
import { GlobalState } from "common/global";
import Utils from "utils/utils";
import ModelHelper from "../model/model.helper";

export default class CTurnTableOperator extends Communicator.Operator.CameraTurntableOperator {
    _viewer: Communicator.WebViewer;

    // private _meshId: number | null = null;

    meshInstanceData: Communicator.MeshInstanceData | undefined;

    isHandleFocus = false; // fix conflict with handle

    private rotateTarget = new Communicator.Point3(0, 0, 0);
    private _viewId : string | undefined = '';

    translationMatrix: Communicator.Matrix | undefined;
    private _sphereRadius = 0.008;

    private isLeftMouseDown = false;

    private angleRotateStep = Math.PI/10;
    
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this._viewer = viewer;
        this._viewId = viewer.getViewElement().parentElement?.id;
    }

    private callbacks: Communicator.CallbackMap = {
        camera: async (camera) => {
            if(!this._viewId) return;
            const nodeId = GlobalState.getTargetPointID(this._viewId)
            if(!nodeId) return;
            //translate
            const curMatrix = this._viewer.model.getNodeMatrix(nodeId);
            curMatrix.setTranslationComponent(this.rotateTarget.x, this.rotateTarget.y, this.rotateTarget.z);
            this._viewer.model.setNodeMatrix(nodeId, curMatrix);
            Utils.delay(500).then(() => {
                this._viewer.view.setPointSize(7, Communicator.PointSizeUnit.ScreenPixels);
            })
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    unSetCallback() {
        this._viewer.unsetCallbacks(this.callbacks);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async initialize() {
        const camera = this._viewer.view.getCamera();
        const target = camera.getTarget();
        this.UpdateTargetPoint(target);
        this._viewer.setCallbacks(this.callbacks)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        //    
        this._ptCurrent = event.getPosition();
        if (this.isLeftMouseDown) {
            if (!this._ptPrevious) {
                this._ptPrevious = this._ptCurrent;
            }
            const dis = this._ptCurrent.copy().subtract(this._ptPrevious);
            const dx = Math.abs(dis.x);
            const dy = Math.abs(dis.y);
            if (dx + dy > 5) // 5 px
            {
                this._ptPrevious = this._ptCurrent;
                const d = dx > dy ? dis.x : dis.y;
                this.rotateAroundAxis(this.rotateTarget, this._rotationAxis, this.angleRotateStep * -d);
            }
            
        }
            
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async onMousewheel(event: Communicator.Event.MouseWheelInputEvent){
        if (event.shiftDown()) {
            super.onMousewheel(event);
        }
        else {
            const panOperator = this._viewer.operatorManager.getOperator(Communicator.OperatorId.Navigate);
            panOperator.onMousewheel(event);
        }
        const newTarget = await this.calTargetPoint(event.getPosition());
        newTarget && this.UpdateTargetPoint(newTarget);
    }

    async onMouseDown(event: Communicator.Event.MouseInputEvent): Promise<void> {
        this._ptCurrent = event.getPosition();
        if (event.getButton() === 0) this.isLeftMouseDown = true;
        else this.isLeftMouseDown = false;
        if (GlobalState.markupMode === 'viewMode') {
            this._viewer.getViewElement().classList.add('rotation-cursor')
            super.onMouseDown(event)
        }
    }

    rotateAroundAxis(origin: Communicator.Point3, axis: Communicator.Point3, amount: number): void {
        const view = this._viewer.view;
        const camera = view.getCamera();

        const eye = camera.getPosition();
        const up = camera.getUp().normalize();
        const at = camera.getTarget();
        const delta = origin;

        const matrixAxis = Communicator.Matrix.createFromOffAxisRotation(axis, amount);
        const matrixOrigin = new Communicator.Matrix().setTranslationComponent(-delta.x, -delta.y, -delta.z);
        const matrixAxisToOrigin = Communicator.Matrix.multiply(matrixOrigin, matrixAxis);
        const matrixOrigin2 = new Communicator.Matrix().setTranslationComponent(delta.x, delta.y, delta.z);
        const matrixFinal = Communicator.Matrix.multiply(matrixAxisToOrigin, matrixOrigin2);
        
        matrixFinal.transform(eye, eye);
        matrixFinal.transform(at, at);
        matrixAxis.transform(up, up);
        up.normalize();

        camera.setPosition(eye);
        camera.setTarget(at);
        camera.setUp(up);
        view.setCamera(camera);
    }

    rotateZByKeyPress(key: KeyboardEvent) {
        if (key.code === KeyCode.arrowLeft) {
            this.rotateAroundAxis(this.rotateTarget, this._rotationAxis, this.angleRotateStep * 12);
        }
        else if (key.code === KeyCode.arrowRight) {
            this.rotateAroundAxis(this.rotateTarget, this._rotationAxis, this.angleRotateStep * -12);
        }
    }
    async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        this.isLeftMouseDown = false;
        this._viewer.getViewElement().classList.remove('rotation-cursor')
        super.onMouseUp(event)        
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public UpdateTargetPoint(newTarget: Communicator.Point3) {
        this.insertGeometry(newTarget);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async calTargetPoint(center2: Communicator.Point2) {
        const { view } = this._viewer;

        const pickConfig = new Communicator.PickConfig(
            Communicator.SelectionMask.All,
        );
        const listNodeId = ModelHelper.getNodeIdCuttingPlane(this._viewer);
        listNodeId.length > 0 && this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, listNodeId, true);
        let newTarget = await view.pickFromPoint(center2, pickConfig).then((select) => {
            if (select.isFaceSelection()) return Utils.getRayCastPoint(select, center2, this._viewer);
            return select.getPosition();
        });
        listNodeId.length > 0 && this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, listNodeId, false);
        if (newTarget == null) {
            newTarget = this.AdjustPositionToPlane(
                view,
                center2,
                view.getCamera().getTarget(),
            );
        }
        return newTarget;
    }
    
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public AdjustPositionToPlane(
        view: Communicator.View,
        position2: Communicator.Point2,
        pointInPlane: Communicator.Point3,
    ) {
        const nPointInPlane = view.projectPoint(pointInPlane);
        const nPosition = view.unprojectPoint(
            position2,
            nPointInPlane.z,
        );
        return nPosition;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async DeleteTargetPointMarker() {
        if(!this._viewId) return;
        const nodeId = GlobalState.getTargetPointID(this._viewId);
        try {
            if (nodeId !== 0 && nodeId !== undefined) {
                // console.log(this._viewer.getViewElement().id, nodeId);
                GlobalState.setTargetPointID(this._viewId, 0);
                await this._viewer.model.deleteMeshInstances([nodeId]);
                // nodeId = 0;
                
            }
        }
        catch (error) {
            return error
        }
    }

    private async insertGeometry(position: Communicator.Point3) {
        function createPointMeshData(position: Communicator.Point3) {
            const points = [];
            const pointCube = new Communicator.MeshData();
            points.push(position.x, position.y, position.z);
            pointCube.addPoints(points);
            return pointCube;
        }
        if (!this._viewId) return;
        this.rotateTarget = position;
        const nodeId = GlobalState.getTargetPointID(this._viewId)
        if (nodeId !== 0 && nodeId !== undefined) {
            //translate
            const curMatrix = this._viewer.model.getNodeMatrix(nodeId);
            curMatrix.setTranslationComponent(position.x, position.y, position.z);
            this._viewer.model.setNodeMatrix(nodeId, curMatrix);
        } else {
            this.translationMatrix = new Communicator.Matrix();
            this.translationMatrix.setTranslationComponent(position.x, position.y, position.z);
            this.translationMatrix.setScaleComponent(this._sphereRadius, this._sphereRadius, this._sphereRadius);
            const meshData = createPointMeshData(position);
            const meshId = await this._viewer.model.createMesh(meshData);
            this.meshInstanceData = new Communicator.MeshInstanceData(meshId);
            this.meshInstanceData.setMatrix(this.translationMatrix);
            this.meshInstanceData.setPointColor(Communicator.Color.green());
            this._viewer.view.setPointShape(Communicator.PointShape.Sphere);
            this._viewer.view.setPointSize(7, Communicator.PointSizeUnit.ScreenPixels);
            this.meshInstanceData.setPointOpacity(1.0);
            const newNodeId = await this._viewer.model.createMeshInstance(this.meshInstanceData);
            GlobalState.setTargetPointID(this._viewId,  newNodeId);

            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotExplode, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotCut, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, [newNodeId], true);
            // this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.SuppressCameraScale, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.OverrideSceneVisibility, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotOutlineHighlight, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.ExcludeBounding, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotUseVertexColors, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.AlwaysDraw, [newNodeId], true);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, [newNodeId], true);

            this._viewer.redraw();
        }
    }
}