import { AnyAction } from "@reduxjs/toolkit";
import { PresetColorType, PresetStatusColorType } from "antd/lib/_util/colors";
import { LiteralUnion } from "antd/lib/_util/type";
import { ButtonOperatorBase } from "helper/operator.helper";
import { Epic } from "redux-observable";
import { RootState } from "redux/rootReducers";
import { CadView, CMICPropertyItem, iStatesObject, Layout, LinkedObjectAPI, SaveView, TreeNode, ValueProperties } from "./define";
import { Callback3DViewerKey, CuttingType, OperatorType, PlaneBoxChild } from "./type-viewer";

export type RootEpic = Epic<AnyAction, AnyAction, RootState>;
export interface User {
    userName: string;
    email: string;
    phoneNumber: string;
    id: string;
    roles: string[];
    setting: unknown;
}
export interface Login {
    loginName: string;
    password: string;
}
export interface StateLogin {
    loading: boolean;
    errorMsg: string;
    success: boolean;
    user: User | null;
    token: string | null;
}
export interface Register {
    userName: string,
    email: string,
    phoneNumber: string,
    password: string
}
export type HostType = keyof Omit<SystemConfig, "protocol" | "modeRender">;
export interface SystemConfig {
    protocol: 'http' | 'https';
    hostStream: string;
    hostFile: string;
    hostConversion: string;
    hostBim: string;
    hostIdentity: string;
    modeRender: 'csr' | 'ssr';
    baseName: string;
    serverDomain: string;
}
export interface BootstrapState {
    systemConfig: SystemConfig;
    isSuccess: boolean;
}
export type ModeLeftPanel = 'FilesList' | 'TreeViews' | 'ThumnailBookmark';
export interface FileItemThumnail {
    viewId: string;
    srcThumnail: string;
    fileName: string;
    baseMajorRev?: number;
    baseMinorRev?: number;
}
export interface NavOperatorState {
    currentOperator: OperatorType[]; //operator active
    listOperatorDisable?: OperatorType[];
    listCurrentOperator?: ButtonOperatorBase[][];
    currentValueExplode?: number;
}
export type ModeLayerMarkupPanel = 'main' | 'detail';
export type TypeGroup = 'full' | 'less' | 'filter' | 'sort';
export type TypeViews = 'table' | 'grid' | 'list';
export type StatusIssueType = 'approved' | 'rejected';
export type MarkupTabType = 'RFI' | 'PCI' | 'Submittals' | 'Issues';
export type ColorsTag = LiteralUnion<PresetColorType | PresetStatusColorType, string>;
export type ModeRightPanel = 'bidsters' | 'drive' | 'grit' | 'markups' | 'measurement' | 'stamp' | 'signatures' | 'formBuilder' | 'pinMarkers' | 'ObjectProperties' | 'sheets';
export type TypeActionClipping = 'turnOffClipping' | 'visible' | 'unVisible' | 'turnOnClipping';
export type PayloadViewId = { viewId: ViewId, mergeViewId?: ViewId, childViewIds?: ViewId[], childsDelete?: CombineChild[] };
export type PayloadModelFileId = { modelFileId: ModelFileId };
export interface PayloadSetOperatorActive extends PayloadViewId {
    operator: OperatorType;
}
export interface PayloadSetListOperatorDisable extends PayloadViewId {
    listOperator?: OperatorType[],
}
export interface PayloadExplodeValue extends PayloadViewId {
    value: number
}
export interface PayloadLoadingValue extends PayloadViewId {
    value: boolean
}
export interface PayloadSetListOperator extends PayloadViewId {
    listOperator: ButtonOperatorBase[][];
}
export interface SheetData {
    id: number | string;
    name: string;
    nodeIds: number[];
    viewId?: ViewId;
    is3D?: boolean;
}
export interface PayloadSheetData extends PayloadViewId {
    data: SheetData[]
}
export interface PayloadActiveSheetMerge extends PayloadViewId {
    sheetData: SheetData
}
export interface PayloadActiveSheet extends PayloadViewId {
    id: number | string
}
export interface PayloadChecked extends PayloadViewId {
    value: boolean
    isReplaceViewActive?: boolean
}
export interface PayloadSelectedFileAndLayout {
    layout: Layout;
    listFiles: ViewId[]
}
export interface PayloadUpdateStateCallback extends PayloadViewId {
    key: Callback3DViewerKey;
    value: unknown
}
export interface PayloadCursor extends PayloadViewId {
    cursor: Cursors
}
export interface PayloadCuttingState extends PayloadViewId {
    cuttingState: CuttingPlaneState
}
export interface PayloadCuttingBoxState extends PayloadViewId {
    planeBoxChildState: PlaneBoxChild[]
    isClipping: boolean
    actionClipping?: TypeActionClipping
}
export interface CuttingPlaneState {
    active?: CuttingType[];
    disable?: CuttingType[];
    planeBoxChildState?: {
        planeBoxChild: PlaneBoxChild[]
        isClipping: boolean,
        boundingBox?: Communicator.Box | null,
        eightCorners?: Communicator.Point3[]
    },
}
export interface PlaneBoxChildState {
    active?: PlaneBoxChild[];
}
export interface PayloadSelectionItem3D extends PayloadViewId {
    selectionArray: NodeId[],
    type: SelectedNodeType,
}
export interface PayloadTreeData {
    modelFileId: ModelFileId,
    data: TreeNode[]
}
export interface PayloadTreeDataExtra extends TreeDataExtra {
    modelFileId: ModelFileId
}
export interface ExtraTree {
    maxLevel?: number;
    mapAllParentNode?: NodeId[];
    mapAttr?: Map<number, ValueProperties>
}
export interface TreeDataExtra {
    data: TreeNode[],
    extra: ExtraTree
}
export interface PayloadSelectedCadView {
    viewId: string;
    selectedCadView: CadView;
}
export interface PayloadSelectedSavedView {
    viewId: string;
    selectedSavedView: SaveView;
}
export interface PayloadSelectedSavedViewGrid {
    viewId: string;
    selectedFolder: string;
    selectedSavedView: SaveView;
}
export interface PayloadSelectedItemShowPropertiesViews {
    viewId: string;
    seletedItem: string;
}
export interface PayloadHidedItemNode3D extends PayloadViewId {
    hidedItems: NodeId[]
}
export interface PayloadRemoveHidedItemNode3D extends PayloadViewId {
    node?: NodeId[];
    removeAll: boolean
}
export interface StringViewId extends PayloadViewId {
    payload: string;
}
export interface NumberViewId extends PayloadViewId {
    payload: number;
}
export interface NumbersViewId extends PayloadViewId {
    payload: number[];
}
export interface BooleanViewId extends PayloadViewId {
    payload: boolean;
}
export type StringModelFileId = PayloadModelFileId & { payload: string };
export type NumberModelFileId = PayloadModelFileId & { payload: number };
export type BooleanModelFileId = PayloadModelFileId & { payload: boolean };

export interface RFIData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: LinkedObjectAPI[],
    count: number,
    hasMore: boolean,
    limit: number,
    offset: number,
    links: RFILink[],
    type?: string,
}
export interface RFILink {
    rel: string,
    href: string,
    name: string,
    kind: string,
}
export enum SortOrder {
    AToZ = 0,
    ZToA,
    Default
}

export interface CMICProperty {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: CMICPropertyItem[],
    count: number,
    hasMore: boolean,
    limit: number,
    offset: number,
    links: RFILink[],
    type?: string,
}

export interface RFISDATA {
    rfiId: string;
    rfiSubject: string;
    status: string;
    compCode: string;
    rfiGUID: string;
    rfiOraseq: string;
    _type?: string;
}
export interface SBMDATA {
    sbmId: string;
    sbmName: string;
    status: string;
    compCode: string;
    sbmGUID: string;
    sbmOraseq: string;
    _type?: string;
}
export interface PCIDATA {
    pciCode: string;
    pciName: string;
    pciJobCode: string;
    status: string;
    compCode: string;
    pciGUID: string;
    pciOraseq: string;
    _type?: string;
}
export interface LinkedObjectModel {
    modelId: string;
    rfisData?: RFISDATA[];
    sbmData?: SBMDATA[];
    pciData?: PCIDATA[];
}
export interface CMICLinkedObjects {
    data: LinkedObjectModel[]
}

export interface LinkedObjectFormat{
    modelId: string,
    RFI: RFIData,
    PCI: RFIData,
    SUB: RFIData,
    DOC: RFIData,
}

export interface RFIstatusState {
    data: {
        RFI: RFIData | undefined | null,
        PCI: RFIData | undefined | null,
        SUB: RFIData | undefined | null,
        DOC: RFIData | undefined | null,
    },
    LinkedObject?: {
        modelId?: string,
        RFI: RFIData | undefined | null,
        PCI: RFIData | undefined | null,
        SUB: RFIData | undefined | null,
        DOC: RFIData | undefined | null,
    },
    isFetching: boolean,
    itemInfo: string | undefined,
    isDummyData: boolean,
}

export enum LinkedObjectType {
    RFI='PMRFI',
    PCI='PMPCI',
    SUB='PMSBM',
    DOC='PMDOC',
}

export interface StateObjectState {
    data: {
        StatesObject : iStatesObject[]
    },
    StatesObjectData?: {
        modelId?: string,
        StatesObject : iStatesObject[]
    },
    isFetching: boolean,
    itemInfo: string | undefined,
    isDummyData: boolean,
}