/* eslint-disable @typescript-eslint/no-explicit-any */
// import { GlobalState } from "common/global";
// import RectangleRedline from "../operator/rectangle.redline";

export default class SelectWindow extends Communicator.Operator.AreaSelectionOperator {

    // private _markupItem: RectangleRedline; // do not custom, using AreaSelection of HOOP
    // private firstSelectPoint: Communicator.Point2 | null;
    // private secondSelectPoint: Communicator.Point2 | null;
    // isMouseDown = false;
    // selectionArray: number[] = [];
    // parentArray: number[] = [];
    constructor(private viewer: Communicator.WebViewer) {
        super(viewer);
        // this._markupItem = new RectangleRedline(viewer);// do not custom, using AreaSelection of HOOP
        // this._markupItem.registerMarkup();
        // this.firstSelectPoint = null;
        // this.secondSelectPoint = null;
    }

    onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        // do not custom, using AreaSelection of HOOP
        // if (event.getButton() === Communicator.Button.Left &&
        //     event.controlDown() === false &&
        //     event.shiftDown() === false
        // ) {
        //     const mousePos = event.getPosition();
        //     this._markupItem.setFirstPoint(mousePos);
        //     this.firstSelectPoint = mousePos;
        //     this._markupItem.setVisible(true);
        //     this.isMouseDown = true;
        // } else {
        //     this.isMouseDown = false;
        //     super.onMouseDown(event);
        // }
        super.onMouseDown(event);
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        // do not custom, using AreaSelection of HOOP
        // if (this.isMouseDown) {
        //     this._markupItem.setSecondPoint(event.getPosition());
        //     this.viewer.markupManager.refreshMarkup();
        //     event.setHandled(true);
        // }
        super.onMouseMove(event);
    }

    onMouseUp(event: Communicator.Event.MouseInputEvent): void {
        // do not custom, using AreaSelection of HOOP
        // this.isMouseDown = false;
        // this.secondSelectPoint = event.getPosition();
        // this._markupItem.setVisible(false);
        // this.viewer.markupManager.refreshMarkup();
        // if (this.firstSelectPoint && Communicator.Point2.distance(this.firstSelectPoint, this.secondSelectPoint) < 10) {
        //     this.firstSelectPoint = null;
        //     this.secondSelectPoint = null;
        //     return;
        // }
        // this.handleHighlightSelectedArea();
        super.onMouseUp(event);
    }

    // async handleHighlightSelectedArea(): Promise<void> {// do not custom, using AreaSelection of HOOP
    //     // laggy, out of memory, for further customization
    //     if (this.firstSelectPoint && this.secondSelectPoint) {
    //         GlobalState.isAreaSelect = true;
    //         const conf = new Communicator.IncrementalPickConfig();
    //         const isFully = this.firstSelectPoint.x <= this.secondSelectPoint.x;

    //         conf.mustBeFullyContained = isFully;
    //         // conf.respectVisibility = true;
    //         // conf.forceEffectiveVisibilityMask = 7;
    //         conf.allowLines = true;
    //         conf.allowPoints = true;
    //         conf.allowFaces = true;
    //         conf.onlyStreamedInstances = false;
    //         conf.ignoreUnrequestedInstances = false;

    //         const pixelMin = this.firstSelectPoint;
    //         const pixelMax = this.secondSelectPoint;
    //         const selectionManager = this._viewer.selectionManager;

    //         const handle = await selectionManager.beginScreenSelectByArea(pixelMin, pixelMax, conf);
    //         let selection = await selectionManager.advanceIncrementalSelection(handle);
    //         while (selection) {
    //             selection = await selectionManager.advanceIncrementalSelection(handle);
    //         }
    //         await selectionManager.endIncrementalSelection(handle);
    //         GlobalState.isAreaSelect = false;
    //     }
    //     this.firstSelectPoint = null;
    //     this.secondSelectPoint = null;
    // }
}
