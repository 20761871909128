/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { GlobalState } from 'common/global';
import { LineStyle, MarkupBaseJson } from 'common/type-markup';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { PolylineElementCanvas } from '../markup-canvas-elements/markup.polyline-canvas.element';
import { MarkupBaseItem } from './markup.base.item';

interface PointScale {
    point: Communicator.Point3;
    scale: number;
}
export class MarkupSignatureItem extends MarkupBaseItem {
    public _points: Communicator.Point3[] = [];
    private _uniqueId: string;
    private dragInfoX: PointScale[] = [];
    private dragInfoY: PointScale[] = [];
    private freehandHTML: PolylineElementCanvas = new PolylineElementCanvas();
    private _boundingPos = Communicator.Point2.zero();
    private _boundingSize = Communicator.Point2.zero();
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupSignature';
        this.shapeName = 'Freehand';
        this._uniqueId = this.uniqueId;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                this.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2) => {
                this.gripPointDragMoveCallback(point);
            },
            (point: Communicator.Point2) => {
                this.gripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                this.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2, tooltipPos: Communicator.Point2) => {
                this.rotateGripPointDragMoveCallback(point, tooltipPos);
            },
            (point: Communicator.Point2) => {
                this.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }

    removeElement(id: string) {
        if (id) {
            this._viewer.markupManager.removeMarkupElement(id);
        }
        return null;
    }


    private _update(): void {
        const strokeWidth = this._lineWeight;
        this.freehandHTML.setStrokeWidth(strokeWidth);
        this.freehandHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        if (this._lineStyle !== LineStyle.cloud && this._lineStyle !== LineStyle.zigzag) {
            this.freehandHTML.setLineStyle(this._lineStyle);
        }
        this.freehandHTML.lineCap = 'round';
        this.freehandHTML.lineJoin = 'round';

        this.freehandHTML.setRotation(this._rotation);
        if (this.redlineBounding) {
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        this.freehandHTML.clearPoints();
        this._points.forEach((value) => {
            const pnt = Communicator.Point2.fromPoint3(view.projectPoint(value));
            this.freehandHTML.pushPoint(pnt);
        });
        if (this.redlineBounding) {
            const vis = this.freehandHTML.getPoints().length > 1 && this.getMarkupVisible() && GlobalState.markupMode === 'editMode';
            this.redlineBounding.setVisibleGripPoints(vis);
        }
        this._isReady = true;
        this.updateBoundingBox();
    }

    updateBoundingBox() {
        const points = this.freehandHTML.getPoints();
        if (!points || !points.length) return;
        const stroke = this.freehandHTML.getStrokeWidth();
        let maxX = points[0].x;
        let maxY = points[0].y;
        let minX = points[0].x;
        let minY = points[0].y;

        points.forEach((value) => {
            if (value.x > maxX) maxX = value.x;
            if (value.y > maxY) maxY = value.y;
            if (value.x < minX) minX = value.x;
            if (value.y < minY) minY = value.y;
        });
        if (!this.redlineBounding) return;
        const pos = new Communicator.Point2(minX - stroke / 2, minY - stroke / 2);
        this.redlineBounding.setPosition(pos);
        this.setBoundingPos(pos)
        const size = new Communicator.Point2(maxX - minX + stroke, maxY - minY + stroke);
        this.redlineBounding.setSize(size);
        this.setBoundingSize(size);
        this.redlineBounding.setCenter(
            new Communicator.Point2(minX, minY),
            new Communicator.Point2(maxX, maxY),
        );
        this.center = this.redlineBounding.center;
    }

    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.freehandHTML.getCanvas(),
                );
            } else {
                this._redlineElementId = this.removeElement(this._redlineElementId!);
            }
        } else {
            this._redlineElementId = this.removeElement(this._redlineElementId!);
        }
        this.handleBoundingRectInteraction();
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupSignatureItem";
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineStyle: this._lineStyle === LineStyle.cloud || this._lineStyle === LineStyle.zigzag ? LineStyle.continous : this._lineStyle,
            lineWeight: this._lineWeight,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            points: this._points.map(v => v.toJson()),
            uniqueId: this.uniqueId,
            boundingPos: this._boundingPos.copy(),
            boundingSize: this._boundingSize.copy(),
            dragPoint: this._previousDragPlanePosition.copy(),
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
            title: this.title
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineStyle = data.lineStyle;
        this._lineWeight = data.lineWeight;
        const points = data.points;
        this._points = [];
        if (points && points.length > 1) {
            for (let i = 0; i < points.length; i++) {
                this.addPoint(Communicator.Point3.fromJson(points[i]));
            }
        }
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._boundingPos = data.boundingPos;
        this._boundingSize = data.boundingSize;
        this._previousDragPlanePosition = data.dragPoint;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
        this.title = data.title;
    }
    onDragStart(point: Communicator.Point2) {
        this.isUpdate = false;
        if (!this._isCanEdit) return false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c: any = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this._points.forEach((value) => value.add(c));
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }
    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.previousGripDragPoint = point;
        this.initDragInfo();
    }

    initDragInfo() {
        const points = this.freehandHTML.getPoints();

        this.dragInfoX = [];
        this.dragInfoY = [];

        let maxX = points[0].x;
        let maxY = points[0].y;
        let minX = points[0].x;
        let minY = points[0].y;

        for (let i = 0; i < points.length; i++) {
            const value = points[i];
            if (value.x > maxX) maxX = value.x;
            if (value.y > maxY) maxY = value.y;
            if (value.x < minX) minX = value.x;
            if (value.y < minY) minY = value.y;
        }

        const deltaX = maxX - minX;
        const deltaY = maxY - minY;

        switch (this.gripPointDragType) {
            case GripPoint.top:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scale = (maxY - pnt2d.y) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale });
                    }
                    break;
                }
            case GripPoint.bottom:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scale = (pnt2d.y - minY) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale });
                    }
                    break;
                }
            case GripPoint.left:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scale = (maxX - pnt2d.x) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale });
                    }
                    break;
                }
            case GripPoint.right:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scale = (pnt2d.x - minX) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale });
                    }
                    break;
                }
            case GripPoint.topLeft:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scaleX = (maxX - pnt2d.x) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale: scaleX });
                        const scaleY = (maxY - pnt2d.y) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale: scaleY });
                    }
                    break;
                }
            case GripPoint.topRight:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scaleX = (pnt2d.x - minX) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale: scaleX });
                        const scaleY = (maxY - pnt2d.y) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale: scaleY });
                    }
                    break;
                }
            case GripPoint.bottomLeft:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scaleX = (maxX - pnt2d.x) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale: scaleX });
                        const scaleY = (pnt2d.y - minY) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale: scaleY });
                    }
                    break;
                }
            case GripPoint.bottomRight:
                {
                    for (let i = 0; i < points.length; i++) {
                        const pnt2d = points[i];
                        const scaleX = (pnt2d.x - minX) / deltaX;
                        this.dragInfoX.push({ point: this._points[i].copy(), scale: scaleX });
                        const scaleY = (pnt2d.y - minY) / deltaY;
                        this.dragInfoY.push({ point: this._points[i].copy(), scale: scaleY });
                    }
                    break;
                }
            default:
                break;
        }
    }

    gripPointDragMoveCallback(point: Communicator.Point2) {
        if (!this._isCanEdit) return;
        if (this.gripPointDragType === null) return;
        this.isUpdate = true;

        switch (this.gripPointDragType) {
            case GripPoint.top:
                {
                    this.dragGripPointY(point, true);
                    break;
                }
            case GripPoint.bottom:
                {
                    this.dragGripPointY(point, false);
                    break;
                }
            case GripPoint.right:
                {
                    this.dragGripPointX(point, true);
                    break;
                }
            case GripPoint.left:
                {
                    this.dragGripPointX(point, false);
                    break;
                }
            case GripPoint.topRight:
                {
                    this.dragGripPointXY(point, true, true);
                    break;
                }
            case GripPoint.topLeft:
                {
                    this.dragGripPointXY(point, true, false);
                    break;
                }
            case GripPoint.bottomRight:
                {
                    this.dragGripPointXY(point, false, true);
                    break;
                }
            case GripPoint.bottomLeft:
                {
                    this.dragGripPointXY(point, false, false);
                    break;
                }
            default:
                break;
        }
        this._viewer.markupManager.refreshMarkup();
    }

    dragGripPointY(point: Communicator.Point2, top: boolean) {
        const { view } = this._viewer;
        if (!this.previousGripDragPoint) return;
        // Update grip point when crossing line
        const pad = this.redlineBounding!.padding + this._lineWeight / 2;
        const points = this.freehandHTML.getPoints();
        const minY = Math.min(...points.map(p => p.y));
        const check = top ?
            (point.y - minY <= (pad * 2) + this.getLineWeight() ? 0 : -1)
            : (point.y - minY <= (pad * 2) + this.getLineWeight() ? -1 : 0)
        const dir = top ? 1 : -1

        const a = new Communicator.Point2(this.previousGripDragPoint.x, point.y + pad * 2 * check * dir);
        const b = view.getCamera().getCameraPlaneIntersectionPoint(this.previousGripDragPoint, view);
        const c = view.getCamera().getCameraPlaneIntersectionPoint(a, view);
        const vec = Communicator.Point3.subtract(c!.copy(), b!.copy());
        for (let i = 0; i < this.dragInfoY.length; i++) {
            const basePoint = this.dragInfoY[i].point.copy();
            const { scale } = this.dragInfoY[i];
            const moveVec = new Communicator.Point3(vec.x * scale, vec.y * scale, vec.z * scale);
            basePoint.add(moveVec);
            this._points[i].assign(basePoint);
        }
    }

    dragGripPointX(point: Communicator.Point2, right: boolean) {
        const { view } = this._viewer;
        if (!this.previousGripDragPoint) return;
        // Update grip point when crossing line
        const pad = this.redlineBounding!.padding + this._lineWeight / 2;
        const points = this.freehandHTML.getPoints();
        const minX = Math.min(...points.map(p => p.x));
        const check = right ?
            (point.x - minX <= (pad * 2) + this.getLineWeight() ? -1 : 0)
            : (point.x - minX <= (pad * 2) + this.getLineWeight() ? 0 : -1)
        const dir = right ? 1 : -1

        const a = new Communicator.Point2(point.x - pad * 2 * check * dir, this.previousGripDragPoint.y);
        const b = view.getCamera().getCameraPlaneIntersectionPoint(this.previousGripDragPoint, view);
        const c = view.getCamera().getCameraPlaneIntersectionPoint(a, view);
        const vec = Communicator.Point3.subtract(c!.copy(), b!.copy());
        for (let i = 0; i < this.dragInfoX.length; i++) {
            const basePoint = this.dragInfoX[i].point.copy();
            const { scale } = this.dragInfoX[i];
            const moveVec = new Communicator.Point3(vec.x * scale, vec.y * scale, vec.z * scale);
            basePoint.add(moveVec);
            this._points[i].assign(basePoint);
        }
    }

    dragGripPointXY(point: Communicator.Point2, top: boolean, right: boolean) {
        const { view } = this._viewer;
        if (!this.previousGripDragPoint) return;
        // Update grip point when crossing line
        const pad = this.redlineBounding!.padding + this._lineWeight / 2;
        const points = this.freehandHTML.getPoints();
        const minX = Math.min(...points.map(p => p.x));
        const minY = Math.min(...points.map(p => p.y));
        const checkRight = right ?
            (point.x - minX <= (pad * 2) + this.getLineWeight() ? -1 : 0)
            : (point.x - minX <= (pad * 2) + this.getLineWeight() ? 0 : -1);
        const checkTop = top ?
            (point.y - minY <= (pad * 2) + this.getLineWeight() ? 0 : -1)
            : (point.y - minY <= (pad * 2) + this.getLineWeight() ? -1 : 0);
        const hDir = right ? 1 : -1;
        const vDir = top ? 1 : -1;

        const a = new Communicator.Point2(point.x - pad * 2 * checkRight * hDir, this.previousGripDragPoint.y);
        const b = new Communicator.Point2(this.previousGripDragPoint.x, point.y + pad * 2 * checkTop * vDir);
        const pntX = view.getCamera().getCameraPlaneIntersectionPoint(a, view);
        const pntY = view.getCamera().getCameraPlaneIntersectionPoint(b, view);
        const pntBase = view.getCamera().getCameraPlaneIntersectionPoint(this.previousGripDragPoint, view);
        const vecX = Communicator.Point3.subtract(pntX!.copy(), pntBase!.copy());
        const vecY = Communicator.Point3.subtract(pntY!.copy(), pntBase!.copy());

        for (let i = 0; i < this.dragInfoX.length; i++) {
            const basePoint = this.dragInfoX[i].point.copy();
            const scaleX = this.dragInfoX[i].scale;
            const scaleY = this.dragInfoY[i].scale;
            const moveVec = new Communicator.Point3(vecX.x * scaleX + vecY.x * scaleY,
                vecX.y * scaleX + vecY.y * scaleY, vecX.z * scaleX + vecY.z * scaleY);
            basePoint.add(moveVec);
            this._points[i].assign(basePoint);
        }
    }

    gripPointDragEndCallback(point: Communicator.Point2) {
        this.gripPointDragType = null;
        this.previousGripDragPoint = null;
        if (this.isUpdate) {
            this.isUpdate = false;
            this.triggerOnMarkupUpdated();
        }
    }
    rotateGripPointDragMoveCallback(point: Communicator.Point2, tooltipPos: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.getHTMLElement().getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) + Math.PI / 2) * 180 / Math.PI - 180) % 360;
            this.setRotation(rotateAngle);
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
            }
        }
    }

    addPoint(point: Communicator.Point3) {
        this._points.push(point.copy());
    }

    hit(mousePos: Communicator.Point2) {
        const points = this.freehandHTML.getPoints();
        if (points.length <= 1) return false;
        for (let i = 1; i < points.length; i++) {
            const point = new Communicator.Point2(mousePos.x, mousePos.y);
            if (Communicator.Util.isPointOnLineSegment2d(point, points[i - 1], points[i], this._lineWeight)) return true;
        }
        return false;
    }
    setBoundingPos(point: Communicator.Point2) {
        this._boundingPos = point;
    }
    getBoundingPos(): Communicator.Point2 {
        return this._boundingPos;
    }
    setBoundingSize(point: Communicator.Point2) {
        this._boundingSize = point;
    }
    getBoundingSize(): Communicator.Point2 {
        return this._boundingSize;
    }
    getPoints(): Communicator.Point3[] {
        return this._points;
    }
    setMarkupVisible(visible: boolean): void {
        this.freehandHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.freehandHTML.baseCanvas;
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.redlineBounding && this.redlineBounding.setVisibleGripPoints(vis)
    }
}

