// old data is no longer used
// replaced with LinkedObjectdummy.ts
const dummyText = {
    "items": [
        {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000001",
            "PmrfiSubject": "HVAC Drawings",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-06-07",
            "PmrfiRequiredDate": "2011-06-16",
            "PmrfiAnsweredDate": "2012-01-20",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": "empty",
            "PmrfiQuestion": "Where are the HVAC drawings for the basement of building C?",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "Will be sending tomorrow.  Waiting for final approval.",
            "PmrfiCoRespondentList": "empty",
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "<a href =\"http://google.ca\" target=\"_blank\">response</a>",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "BILL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": "COMM11001",
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": 0,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": 0,
            "PmrfiSubmittedDate": "2011-06-07T12:03:46-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-06-07T12:03:56-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB34775E05313061FACF2CF",
            "VUuid": "8130B39E9EB34775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB34775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB34775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000002",
            "PmrfiSubject": "Need to move pipes in building D",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-06-07",
            "PmrfiRequiredDate": "2011-06-16",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "I need to move the pipes in order to get around some insulated duct work in building D, floor 2 and room 221.",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": "SCO00002",
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "Move pipes around the insulated ducts.",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "Y",
            "PmrfiSuggCostAmt": 5000,
            "PmrfiSuggSchedImpactFlag": "Y",
            "PmrfiSuggSchedDaysNum": 1,
            "PmrfiSubmittedDate": "2011-06-07T12:44:44-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-06-07T12:44:54-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB44775E05313061FACF2CF",
            "VUuid": "8130B39E9EB44775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000003",
            "PmrfiSubject": "what color to paint room 33B?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-06-08T12:00:00-04:00",
            "PmrfiRequiredDate": "2011-06-17",
            "PmrfiAnsweredDate": "2011-06-08",
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": "104194",
            "PmrfiQuestion": "what color to paint room 33B?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "DEMO",
            "PmrfiAnswer": "Ok. Blue (blue code #123A)",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "N",
            "PmrfiSuggestion": "null",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "MARCEL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "N",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": null,
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": null,
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2016-05-01T01:27:22-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-06-08T13:10:37-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB54775E05313061FACF2CF",
            "VUuid": "8130B39E9EB54775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB54775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB54775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000008",
            "PmrfiSubject": "New Quetion",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-04",
            "PmrfiRequiredDate": "2011-08-15",
            "PmrfiAnsweredDate": "2015-09-17T13:38:00-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "GORD",
            "PmrfiRefId": "HOLMES-0000005",
            "PmrfiQuestion": "This is a question\\r\\n",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "JR",
            "PmrfiAnswer": "Answer here\\n\\n\\n\\nJess Robinson \\nKHS&S Contractors",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "I need an answer",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "JR",
            "PmrfiEnteredByPartnCode": "10",
            "PmrfiEnteredByPartnTCode": "C",
            "PmrfiEnteredByContactCode": "MARCEL",
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2015-09-17T13:32:28-04:00",
            "PmrfiCoauthorPartnCode": "HOLMES",
            "PmrfiCoauthorPartnTypeCode": "P",
            "PmrfiCoauthorContactCode": "MHOLMES",
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-04T15:34:51-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB64775E05313061FACF2CF",
            "VUuid": "8130B39E9EB64775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB64775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB64775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000011",
            "PmrfiSubject": "Important meeting tomorrow at 3p",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-23",
            "PmrfiRequiredDate": "2011-09-01",
            "PmrfiAnsweredDate": "2011-08-23T10:05:13-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Can you make a very important meeting tomorrow at 3P?",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "Yes, see you at 3p.\\n \\nMonty\\n \\n\\nDate: Tue, 23 Aug 2011 10:04:12 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "HOLMES",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "MHOLMES",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-23T10:04:07-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-23T10:04:36-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB74775E05313061FACF2CF",
            "VUuid": "8130B39E9EB74775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB74775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB74775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000012",
            "PmrfiSubject": "Where are the stored materials for the project you're working on?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-23",
            "PmrfiRequiredDate": "2011-09-01",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Where are the stored materials for the project you're working on?",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "They should be in Storage Bin #3.  Let me know if you can't find it.",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-23T10:09:34-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-23T10:10:03-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EB84775E05313061FACF2CF",
            "VUuid": "8130B39E9EB84775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB84775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EB84775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000023",
            "PmrfiSubject": "GLUE RFI Roof Duct Penetration",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-23",
            "PmrfiRequiredDate": "2011-10-04",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "DEMO",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Verify that the duct on the roof needs a wall penetration",
            "PmrfiToPartnCode": "ZZ",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "DA",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": null,
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": null,
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-23T11:01:56-04:00",
            "PmrfiCoauthorPartnCode": "ZZ",
            "PmrfiCoauthorPartnTypeCode": "C",
            "PmrfiCoauthorContactCode": "DA",
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-23T11:02:04-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EBA4775E05313061FACF2CF",
            "VUuid": "8130B39E9EBA4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBA4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBA4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000010",
            "PmrfiSubject": "where are the bridge specs?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-27",
            "PmrfiRequiredDate": "2011-10-06",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "where are the bridge specs?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "N",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "N",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-27T10:57:14-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-27T10:57:18-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EBB4775E05313061FACF2CF",
            "VUuid": "8130B39E9EBB4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBB4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBB4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000011",
            "PmrfiSubject": "Please provide me with the bridge specs asap!",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-27",
            "PmrfiRequiredDate": "2011-10-06",
            "PmrfiAnsweredDate": "2011-09-27",
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Please provide me with the bridge specs asap!",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "Please see attached.",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "MARCEL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "N",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "N",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-27T10:59:46-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-27T10:59:50-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "VUuid": "8130B39E9EBC4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBC4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBC4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000026",
            "PmrfiSubject": "where are the bridge specs for route 44S",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-27",
            "PmrfiRequiredDate": "2011-10-06",
            "PmrfiAnsweredDate": "2011-09-27T12:29:49-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": "HOLMES-0000012",
            "PmrfiQuestion": "where are the bridge specs for route 44S",
            "PmrfiToPartnCode": "1159",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "JMALDEN",
            "PmrfiAnswer": "see attached\\n \\nJohn\\n \\n\\nDate: Tue, 27 Sep 2011 12:27:39 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "1159",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "JMALDEN",
            "PmrfiEnteredByPartnCode": "10",
            "PmrfiEnteredByPartnTCode": "C",
            "PmrfiEnteredByContactCode": "MARCEL",
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-27T12:27:18-04:00",
            "PmrfiCoauthorPartnCode": "HOLMES",
            "PmrfiCoauthorPartnTypeCode": "P",
            "PmrfiCoauthorContactCode": "MHOLMES",
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-27T12:26:13-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EBD4775E05313061FACF2CF",
            "VUuid": "8130B39E9EBD4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBD4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBD4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000032",
            "PmrfiSubject": "3rd floor balcony",
            "PmrfiStatusCode": "PENDING",
            "PmrfiCreateDate": "2011-10-14",
            "PmrfiRequiredDate": "2011-10-25",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "We're still waiting for the railings for the 3rd floor balcony, when will they be in?",
            "PmrfiToPartnCode": null,
            "PmrfiToPartnTypeCode": null,
            "PmrfiToContactCode": null,
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": null,
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-10-14T09:55:36-04:00",
            "PmrfiIuUpdateDate": "2019-12-16T14:56:53-05:00",
            "PmrfiVUuid": "8130B39E9EBF4775E05313061FACF2CF",
            "VUuid": "8130B39E9EBF4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBF4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EBF4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000041",
            "PmrfiSubject": "Broken pipe",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-12-12",
            "PmrfiRequiredDate": "2011-12-21",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "DEMO",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Broken pipe",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "BILL",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": 0,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": 0,
            "PmrfiSubmittedDate": "2014-12-16T10:36:48-05:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-12-12T12:07:07-05:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:07-05:00",
            "PmrfiVUuid": "8130B39E9EC14775E05313061FACF2CF",
            "VUuid": "8130B39E9EC14775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC14775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC14775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000044",
            "PmrfiSubject": "Can we meet at 1p tomorrow?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2012-01-11",
            "PmrfiRequiredDate": "2012-01-20",
            "PmrfiAnsweredDate": "2012-01-11T08:08:21-05:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Can we meet at 1p tomorrow?",
            "PmrfiToPartnCode": "1159",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "JMALDEN",
            "PmrfiAnswer": "ok.\\n \\n\\nDate: Wed, 11 Jan 2012 08:07:45 -0500",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "1159",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "JMALDEN",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2012-01-11T08:07:36-05:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2012-01-11T08:07:35-05:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9EC44775E05313061FACF2CF",
            "VUuid": "8130B39E9EC44775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000001",
            "PmrfiSubject": "Collision issue with insulated duct",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-06-07",
            "PmrfiRequiredDate": "2011-06-10",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "I have run into a collision issue with insulated duct in building A, floor 3 and room 305.  Please provide me with instructions on how to correct the problem.",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "See attached.",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": null,
            "PmrfiSuggestion": "Install piping around duct, then have framers and drywallers extend wall around it.",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": null,
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "Y",
            "PmrfiSuggCostAmt": 1000,
            "PmrfiSuggSchedImpactFlag": "Y",
            "PmrfiSuggSchedDaysNum": 1,
            "PmrfiSubmittedDate": "2011-06-07T11:17:19-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-06-07T11:05:55-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9EC94775E05313061FACF2CF",
            "VUuid": "8130B39E9EC94775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC94775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9EC94775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000004",
            "PmrfiSubject": "Where are the drawings for room 28C?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-03",
            "PmrfiRequiredDate": "2011-08-12",
            "PmrfiAnsweredDate": "2011-08-09T07:41:29-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Where are the drawings for room 28C?",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "I will upload them shortly.",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "HOLMES",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "MHOLMES",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-03T10:19:02-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-03T10:19:01-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ECB4775E05313061FACF2CF",
            "VUuid": "8130B39E9ECB4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECB4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECB4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000005",
            "PmrfiSubject": "Have you received the drawings for area 52B?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-03",
            "PmrfiRequiredDate": "2011-08-12",
            "PmrfiAnsweredDate": "2011-08-23T10:01:09-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Have you received the drawings for area 52B?  Please confirm asap!",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "Yes, we're good.\\n \\nMonty\\n \\n\\nDate: Tue, 23 Aug 2011 09:59:10 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "HOLMES",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "MHOLMES",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-03T11:13:29-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-03T11:13:28-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ECC4775E05313061FACF2CF",
            "VUuid": "8130B39E9ECC4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECC4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECC4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000013",
            "PmrfiSubject": "Roof  - Type of antenna",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-25",
            "PmrfiRequiredDate": "2011-09-05",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "DEMO",
            "PmrfiRefId": null,
            "PmrfiQuestion": "What type of antenna is this (use the bi-directional linking feature to view)?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "Type 29-D-Class 3",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": null,
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": null,
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-25T11:51:37-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-25T09:55:34-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ECD4775E05313061FACF2CF",
            "VUuid": "8130B39E9ECD4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECD4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECD4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000006",
            "PmrfiSubject": "where are the drawings?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-08-26",
            "PmrfiRequiredDate": "2011-09-06",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "where are the drawings?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-08-26T09:33:24-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-08-26T09:33:58-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ECE4775E05313061FACF2CF",
            "VUuid": "8130B39E9ECE4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECE4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECE4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000018",
            "PmrfiSubject": "Are the materials on site?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-12",
            "PmrfiRequiredDate": "2011-09-21",
            "PmrfiAnsweredDate": "2011-09-12T09:36:19-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Are the materials on site regarding the work you're starting tomorrow?",
            "PmrfiToPartnCode": "HOLMES",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "MHOLMES",
            "PmrfiAnswer": "Yes, we're all set to go.",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "HOLMES",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "MHOLMES",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-12T09:32:37-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-12T09:32:39-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ECF4775E05313061FACF2CF",
            "VUuid": "8130B39E9ECF4775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECF4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ECF4775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000008",
            "PmrfiSubject": "what color to paint room 33F?",
            "PmrfiStatusCode": "CLOSED",
            "PmrfiCreateDate": "2011-09-12",
            "PmrfiRequiredDate": "2011-09-21",
            "PmrfiAnsweredDate": "2011-09-12",
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "what color to paint room 33F?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "Red (R243443) \\nJohn\\n \\n\\nDate: Mon, 12 Sep 2011 13:02:27 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "Red.",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "MARCEL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "N",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "N",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-12T12:59:06-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-12T12:59:08-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ED04775E05313061FACF2CF",
            "VUuid": "8130B39E9ED04775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED04775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED04775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000021",
            "PmrfiSubject": "what color to paint room 22C?",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-09-22",
            "PmrfiRequiredDate": "2011-10-03",
            "PmrfiAnsweredDate": "2011-09-22T08:28:28-04:00",
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "MARCEL",
            "PmrfiRefId": "HOLMES-0000009",
            "PmrfiQuestion": "what color to paint room 22C?",
            "PmrfiToPartnCode": "1159",
            "PmrfiToPartnTypeCode": "P",
            "PmrfiToContactCode": "JMALDEN",
            "PmrfiAnswer": "Red is fine.\\n \\nJohn\\n \\n\\nDate: Thu, 22 Sep 2011 08:26:09 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "Red?",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "1159",
            "PmrfiAnswerByPtCode": "P",
            "PmrfiAnswerByContactCode": "JMALDEN",
            "PmrfiEnteredByPartnCode": "10",
            "PmrfiEnteredByPartnTCode": "C",
            "PmrfiEnteredByContactCode": "MARCEL",
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-09-22T08:25:56-04:00",
            "PmrfiCoauthorPartnCode": "HOLMES",
            "PmrfiCoauthorPartnTypeCode": "P",
            "PmrfiCoauthorContactCode": "MHOLMES",
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-09-22T08:24:31-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ED14775E05313061FACF2CF",
            "VUuid": "8130B39E9ED14775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED14775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED14775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "90223-0000001",
            "PmrfiSubject": "Trim for Main Entrance",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-10-04T12:16:41-04:00",
            "PmrfiRequiredDate": "2011-10-07",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "90223",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "FMALCOLM",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Has a decision been made regarding the trim for the main entrance?",
            "PmrfiToPartnCode": null,
            "PmrfiToPartnTypeCode": null,
            "PmrfiToContactCode": null,
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": "Stick with the same color pallet but go a shade darker.",
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": "90223",
            "PmrfiEnteredByPartnTCode": "P",
            "PmrfiEnteredByContactCode": "FMALCOLM",
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-10-04T12:16:41-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-10-04T12:16:46-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ED34775E05313061FACF2CF",
            "VUuid": "8130B39E9ED34775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED34775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED34775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000013",
            "PmrfiSubject": "Where are the drawings for bridge 3?",
            "PmrfiStatusCode": "CLOSED",
            "PmrfiCreateDate": "2011-10-14",
            "PmrfiRequiredDate": "2011-10-25",
            "PmrfiAnsweredDate": "2011-10-14",
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "Where are the drawings for bridge 3?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "See attached.\\n \\n",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "MARCEL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-10-14T09:45:50-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-10-14T09:45:58-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ED44775E05313061FACF2CF",
            "VUuid": "8130B39E9ED44775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED44775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "HOLMES-0000014",
            "PmrfiSubject": "where are the drawings for bridge 44C?",
            "PmrfiStatusCode": "CLOSED",
            "PmrfiCreateDate": "2011-10-14",
            "PmrfiRequiredDate": "2011-10-25",
            "PmrfiAnsweredDate": "2011-10-14",
            "PmrfiFromPartnCode": "HOLMES",
            "PmrfiFromPartnTypeCode": "P",
            "PmrfiFromContactCode": "MHOLMES",
            "PmrfiRefId": null,
            "PmrfiQuestion": "where are the drawings for bridge 44C?",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "MARCEL",
            "PmrfiAnswer": "Please see attached.\\n \\nJohn\\n \\n\\nDate: Fri, 14 Oct 2011 11:39:28 -0400",
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": "10",
            "PmrfiAnswerByPtCode": "C",
            "PmrfiAnswerByContactCode": "MARCEL",
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "P",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "P",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-10-14T11:32:42-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-10-14T11:32:14-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:57-05:00",
            "PmrfiVUuid": "8130B39E9ED54775E05313061FACF2CF",
            "VUuid": "8130B39E9ED54775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED54775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED54775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }, {
            "PmrfiCompCode": "10",
            "PmrfiProjCode": "                    ",
            "PmrfiRfiId": "00000034",
            "PmrfiSubject": "Glue RFI duct clash with mech on the roof",
            "PmrfiStatusCode": "OPEN",
            "PmrfiCreateDate": "2011-10-20",
            "PmrfiRequiredDate": "2011-10-31",
            "PmrfiAnsweredDate": null,
            "PmrfiFromPartnCode": "10",
            "PmrfiFromPartnTypeCode": "C",
            "PmrfiFromContactCode": "DEMO",
            "PmrfiRefId": null,
            "PmrfiQuestion": "verify the location of the duct bend see this area: https://b3.autodesk.com/Jo2x9X",
            "PmrfiToPartnCode": "10",
            "PmrfiToPartnTypeCode": "C",
            "PmrfiToContactCode": "DEMO",
            "PmrfiAnswer": null,
            "PmrfiCoRespondentList": null,
            "PmrfiCostAmt": null,
            "PmrfiCmCode": null,
            "PmrfiCostImpactFlag": "P",
            "PmrfiSuggestion": null,
            "PmrfiAcceptSuggestionFlag": null,
            "PmrfiProceedFlag": null,
            "PmrfiAnswerByPartnCode": null,
            "PmrfiAnswerByPtCode": null,
            "PmrfiAnswerByContactCode": null,
            "PmrfiEnteredByPartnCode": null,
            "PmrfiEnteredByPartnTCode": null,
            "PmrfiEnteredByContactCode": null,
            "PmrfiScheduleImpactFlag": "P",
            "PmrfiScheduleDaysNum": null,
            "PmrfiSrcCode": null,
            "PmrfiSuggCostImpactFlag": "N",
            "PmrfiSuggCostAmt": null,
            "PmrfiSuggSchedImpactFlag": "N",
            "PmrfiSuggSchedDaysNum": null,
            "PmrfiSubmittedDate": "2011-10-20T15:53:36-04:00",
            "PmrfiCoauthorPartnCode": null,
            "PmrfiCoauthorPartnTypeCode": null,
            "PmrfiCoauthorContactCode": null,
            "PmrfiClvValueCode1": null,
            "PmrfiClvValueCode2": null,
            "PmrfiClvValueCode3": null,
            "PmrfiClvValueCode4": null,
            "PmrfiClvValueCode5": null,
            "PmrfiClvValueCode6": null,
            "PmrfiIuCreateDate": "2011-10-20T15:53:53-04:00",
            "PmrfiIuUpdateDate": "2018-01-24T11:21:08-05:00",
            "PmrfiVUuid": "8130B39E9ED64775E05313061FACF2CF",
            "VUuid": "8130B39E9ED64775E05313061FACF2CF",
            "links": [{
                "rel": "self",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED64775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }, {
                "rel": "canonical",
                "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi/8130B39E9ED64775E05313061FACF2CF",
                "name": "pmrfi",
                "kind": "item"
            }],
            "_type": "RFI"
        }],
    "count": 25,
    "hasMore": true,
    "limit": 25,
    "offset": 0,
    "links": [{
        "rel": "self",
        "href": "https://solutionsr12mob.cmicpaas.com:443/cmictest/pm-rest-api/rest/1/pmrfi",
        "name": "pmrfi",
        "kind": "collection"
    }]
}


export default dummyText;
