import { SystemConstants } from "common/system-constants";
import { RFIData } from "common/type-state";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Utils from "utils/utils";
import HttpClient from "./http-client";



export default class RFIAPI {
    static host = '';

    static getRFI(): Observable<RFIData> {
        const data = {
            projCode: '1503',
            compCode: '10',
        }
        const { compCode, projCode } = data;
        const api = SystemConstants.SERVER_RFI.PROJECT_COMPANY.GET(compCode, projCode);
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('RFI'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as RFIData));
    }

    static getSUB(): Observable<RFIData> {
        const data = {
            compCode: '10',
            contCodeVar: 'PAYREQ-0014,PAYREQ-0015,PAYREQ-0016',
            jobCodeSOV: 'PAYREQ',
        }
        const { compCode, contCodeVar, jobCodeSOV } = data;
        const api = SystemConstants.SERVER_RFI.SUB_PM_BY_SOV.GET(compCode, contCodeVar, jobCodeSOV);
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('SUB'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as RFIData));
    }

    static getPCI(): Observable<RFIData> {
        const api = SystemConstants.SERVER_RFI.PCI.GET();
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('PCI'),
            }
        }
        //eslint-disable-next-line
        return HttpClient.get(api, options).pipe(map(res => res as any))
    }
    static getDOC(data: string): Observable<string> {
        const api = SystemConstants.SERVER_RFI.DOC.GET(data);
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('DOC'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as string))
    }
    static getDocument(data: string): Observable<string> {
        const api = SystemConstants.SERVER_RFI.DOC.GET(data);
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('DOC'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as string))
    }

    static CMiC = {
        getRFI(modelId: string, projGuid: string): Observable<RFIData> {
            const api = SystemConstants.CMIC_PROPERTY.PM_RFI.GET(modelId, projGuid);
            const options = {
                headers: {
                    'Authorization': Utils.getAuthField('RFI'),
                    'X-projectGUID': projGuid,
                }
            }
            return HttpClient.get(api, options).pipe(map(res => res as RFIData));
        },

        getSUB(modelId: string, projGuid: string): Observable<RFIData> {
            const api = SystemConstants.CMIC_PROPERTY.PM_SUB.GET(modelId, projGuid);
            const options = {
                headers: {
                    'Authorization': Utils.getAuthField('SUB'),
                    'X-projectGUID': projGuid,
                }
            }
            return HttpClient.get(api, options).pipe(map(res => res as RFIData));
        },

        getPCI(modelId: string, projGuid: string): Observable<RFIData> {
            const api = SystemConstants.CMIC_PROPERTY.PM_PCI.GET(modelId, projGuid);
            const options = {
                headers: {
                    'Authorization': Utils.getAuthField('PCI'),
                    'X-projectGUID': projGuid,
                }
            }
            return HttpClient.get(api, options).pipe(map(res => res as RFIData));
        }
    }
}