/* eslint-disable @typescript-eslint/no-unused-vars */
import { DragAndDropResult, FileInfo, Layout, ViewActive } from "common/define";
import Utils from "utils/utils";
import _, { clone } from "lodash";
import { Observable } from "rxjs";
import { GlobalState } from "common/global";
import { map, take } from "rxjs/operators";

/** interface, type */
export interface ResultHandleMultiView {
    newListViewerDisplay?: FileInfo[];
    newViewActive?: ViewActive;
    newListViewerVisible?: ViewId[];
    newListViewerBlank?: ViewId[];
    newLayout?: Layout;
    newListViewOnly?: ViewId[]
}
export interface MultiViewerState {
    listViewerDisplay: FileInfo[];
    viewActive: ViewActive;
    layout: Layout;
    listViewerVisible: ViewId[]; //max length = 4
    listViewerBlank: ViewId[];
    listViewOnly: ViewId[];
    isMarkupDragging: boolean;
    synchronized: boolean;
}

/** functions in scope */
function createBlankView(): FileInfo {
    return {
        viewId: `blank-${Communicator.UUID.create()}`,
        filename: 'Blank view',
        baseFileId: '',
        modelFileId: '',
        streamLocation: ''
    }
}
function createViewOnlyFileInfo(rootView: FileInfo): FileInfo {
    const cloneRootView = _.cloneDeep(rootView);
    return {
        ...cloneRootView,
        viewId: `view-only-${Communicator.UUID.create()}`,
    }
}
function getListViewBlankVisible(listBlank: ViewId[], listVisible: ViewId[]): ViewId[] {
    return listBlank.filter(v => listVisible.includes(v));
}
function getListViewBlankNotVisible(listBlank: ViewId[], listVisible: ViewId[]): ViewId[] {
    return listBlank.filter(v => !listVisible.includes(v));
}
function isBlankView(viewId: ViewId, listViewBlank: ViewId[]) {
    return listViewBlank.includes(viewId);
}
function removeAllViewBlankNotVisible(
    result: ResultHandleMultiView,
    listViewerBlank: ViewId[],
    listViewerDisplay: FileInfo[],
    listViewerVisible: ViewId[]
): void {
    const listViewBlankNotVisible = getListViewBlankNotVisible(listViewerBlank, listViewerVisible);
    if (listViewBlankNotVisible.length > 0) {
        result.newListViewerBlank = _.remove(listViewerBlank, f => !listViewBlankNotVisible.includes(f));
        result.newListViewerDisplay = _.remove(listViewerDisplay, f => !listViewBlankNotVisible.includes(f.viewId))
    }
}
function closeMultiView(
    viewsIdClose: ViewId[],
    cloneListViewVisible: ViewId[],
    cloneListViewDisplay: FileInfo[],
    cloneListViewBlank: ViewId[],
    result: ResultHandleMultiView
) {
    const tempListViewVisible = [...cloneListViewVisible];
    let tempListViewDisplay = [...cloneListViewDisplay];
    let tempListViewBlank = [...cloneListViewBlank];
    viewsIdClose.forEach(viewId => {
        const indexViewIdClose = tempListViewVisible.findIndex(v => v === viewId);
        const newBlankView = createBlankView();
        const tempListDisplay = [...tempListViewDisplay, newBlankView];
        tempListViewDisplay = tempListDisplay;
        tempListViewBlank = [...cloneListViewBlank, newBlankView.viewId];
        tempListViewVisible.splice(indexViewIdClose, 1, newBlankView.viewId);
    });
    result.newListViewerDisplay = tempListViewDisplay;
    result.newListViewerBlank = tempListViewBlank;
    result.newListViewerVisible = [...tempListViewVisible]
}
function closeBlankView(
    viewId: ViewId,
    cloneListViewDisplay: FileInfo[],
    cloneListViewBlank: ViewId[],
    cloneListViewVisible: ViewId[],
    viewActive: ViewActive,
    result: ResultHandleMultiView
) {
    const resultListViewDisplay = _.remove(cloneListViewDisplay, v => v.viewId !== viewId);
    result.newListViewerBlank = _.remove(cloneListViewBlank, v => v !== viewId);
    result.newListViewerVisible = _.remove(cloneListViewVisible, v => v !== viewId);
    if (viewActive.viewId === viewId) {
        const [firstViewVisible,] = result.newListViewerVisible;
        result.newViewActive = createViewActive(firstViewVisible, resultListViewDisplay);
    } else {
        const previousActiveId = viewActive.viewId;
        result.newViewActive = createViewActive(previousActiveId, resultListViewDisplay);
    }
    result.newListViewerDisplay = resultListViewDisplay;
    result.newLayout = Utils.getFirstLayoutByNumber(result.newListViewerVisible.length);
}
function closeNotBlankView(
    viewId: ViewId,
    cloneListViewDisplay: FileInfo[],
    cloneListViewBlank: ViewId[],
    cloneListViewVisible: ViewId[],
    viewActive: ViewActive,
    result: ResultHandleMultiView,
    listViewOnly: ViewId[]
) {
    const listViewBlankNotVisible = getListViewBlankNotVisible(cloneListViewBlank, cloneListViewVisible);
    const isInListVisible = cloneListViewVisible.includes(viewId);
    const indexViewActive = cloneListViewVisible.findIndex(f => f === viewActive.viewId);
    if (isInListVisible) {
        const indexViewIdClose = cloneListViewVisible.findIndex(v => v === viewId);
        if (listViewBlankNotVisible.length > 0) {
            const [firstViewBlank,] = listViewBlankNotVisible;
            cloneListViewVisible.splice(indexViewIdClose, 1, firstViewBlank);
            result.newListViewerVisible = [...cloneListViewVisible];
        } else {
            const newBlankView = createBlankView();
            const tempListDisplay = [...cloneListViewDisplay, newBlankView];
            result.newListViewerDisplay = tempListDisplay;
            result.newListViewerBlank = [...cloneListViewBlank, newBlankView.viewId];
            cloneListViewVisible.splice(indexViewIdClose, 1, newBlankView.viewId);
            result.newListViewerVisible = [...cloneListViewVisible];
        }
    }
    const tempListViewDisplay = result.newListViewerDisplay ?? cloneListViewDisplay;
    const mapListIdViewDisplay = tempListViewDisplay.map(f => f.viewId);
    // close merge file
    if (GlobalState.mapListMerge(mapListIdViewDisplay).includes(viewId)) {
        const listDelete = GlobalState.filterFileMerger(viewId, mapListIdViewDisplay) ?? [viewId];
        result.newListViewerDisplay = _.remove(tempListViewDisplay, f => !listDelete.includes(f.viewId));
        GlobalState.deleteViewer(listDelete)
    }
    // Close view only
    if (GlobalState.isRootViewOnly(viewId)) {
        const childrenViewOnly = GlobalState.getChildrenByRootId(viewId);
        const cloneListViewOnly = [...listViewOnly];
        if (childrenViewOnly.length > 0) {
            const tempListViewDisplay = result.newListViewerDisplay ?? cloneListViewDisplay;
            const listViewOnlyChildrenVisible = result.newListViewerVisible?.filter(f => childrenViewOnly.includes(f));
            result.newListViewerDisplay = _.remove(tempListViewDisplay, f => !childrenViewOnly.includes(f.viewId));
            result.newListViewOnly = _.remove(cloneListViewOnly, v => !childrenViewOnly.includes(v));
            if (listViewOnlyChildrenVisible && listViewOnlyChildrenVisible?.length > 0) {
                closeMultiView(
                    listViewOnlyChildrenVisible,
                    result.newListViewerVisible ?? cloneListViewVisible,
                    result.newListViewerDisplay ?? cloneListViewDisplay,
                    result.newListViewerBlank ?? cloneListViewBlank,
                    result
                )
            }
            GlobalState.deleteMapRootViewOnly(viewId)
        }
    }
    // remove all blank view not visible
    removeAllViewBlankNotVisible(
        result,
        result.newListViewerBlank ?? cloneListViewBlank,
        result.newListViewerDisplay ?? cloneListViewDisplay,
        result.newListViewerVisible ?? cloneListViewVisible
    )
    if (viewActive.viewId === viewId) {
        const tempListViewVisible = result.newListViewerVisible ?? cloneListViewVisible;
        const viewIdReplace = tempListViewVisible.find((v, i) => i === indexViewActive);
        if (viewIdReplace) {
            result.newViewActive = createViewActive(viewIdReplace, result.newListViewerDisplay ?? cloneListViewDisplay);
        }
    }
}
function updateResultViewOnly(
    result: ResultHandleMultiView,
    viewIdOnly: ViewId,
    listBlankVisible: ViewId[],
    cloneListViewBlank: ViewId[],
    cloneListViewDisplay: FileInfo[],
    listViewerVisible: ViewId[],
    insertNewViewOnly: boolean
) {
    const cloneListViewVisible = [...listViewerVisible];
    const [firstBlankVisible,] = listBlankVisible;
    const newListViewerBlank = _.remove(cloneListViewBlank, f => f !== firstBlankVisible);
    result.newListViewerBlank = newListViewerBlank;
    const indexViewBlankVisible = listViewerVisible.findIndex(f => f === firstBlankVisible);
    cloneListViewVisible.splice(indexViewBlankVisible, 1, viewIdOnly);
    result.newListViewerVisible = cloneListViewVisible;
    if (!insertNewViewOnly) {
        const newListViewerDisplay = _.remove(cloneListViewDisplay, f => f.viewId !== firstBlankVisible);
        result.newListViewerDisplay = newListViewerDisplay;
    }
}
function insertViewOnly(
    result: ResultHandleMultiView,
    cloneListViewVisible: ViewId[],
    viewIdOnly: ViewId,
    layout: Layout
) {
    result.newListViewerVisible = [...cloneListViewVisible, viewIdOnly];
    let numberLayout = Utils.getNumberFromTypeLayout(layout) + 1;
    numberLayout = numberLayout > 4 ? 4 : numberLayout;
    const newLayout = Utils.getFirstLayoutByNumber(numberLayout);
    result.newLayout = newLayout;
}
function insertViewOnlyExtend(
    result: ResultHandleMultiView,
    cloneListViewDisplay: FileInfo[],
    cloneListViewVisible: ViewId[],
    listViewerVisible: ViewId[],
    listBlankVisible: ViewId[],
    cloneListViewBlank: ViewId[],
    listViewOnly: ViewId[],
    viewIdActive: ViewId,
    layout: Layout,
): void {
    const rootViewInfo = cloneListViewDisplay.find(f => f.viewId === viewIdActive);
    if (rootViewInfo) {
        const newViewOnlyByViewActive = createViewOnlyFileInfo(rootViewInfo);
        const tempListViewDisplay = [...cloneListViewDisplay, newViewOnlyByViewActive];
        result.newListViewerDisplay = tempListViewDisplay;
        if (listBlankVisible.length > 0) {
            updateResultViewOnly(result, newViewOnlyByViewActive.viewId, listBlankVisible, cloneListViewBlank, tempListViewDisplay, listViewerVisible, false);
        } else {
            insertViewOnly(result, cloneListViewVisible, newViewOnlyByViewActive.viewId, layout)
        }
        GlobalState.addNewChildViewOnly(rootViewInfo.viewId, newViewOnlyByViewActive.viewId);
        result.newListViewOnly = [...listViewOnly, newViewOnlyByViewActive.viewId];
    }
}
function getListViewOnlyVisible(multiViewerState: MultiViewerState): FileInfo[] {
    const { listViewOnly, listViewerDisplay, listViewerVisible } = multiViewerState;
    const list = listViewOnly.filter(f => listViewerVisible.includes(f));
    return listViewerDisplay.filter(f => list.includes(f.viewId));
}


/** functions export */
export function createViewActive(viewId: ViewId, listViewer: FileInfo[]): ViewActive {
    const value = Utils.getFirstItem(listViewer, v => v.viewId === viewId);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { item: view, index } = value!;
    const viewActive: ViewActive = {
        viewId,
        baseFileId: view?.baseFileId,
        index,
        name: view.originalFile,
        formatViewer: view.converter,
        parentIdMerge: GlobalState.getParentMerge(viewId),
        extension: Utils.getFileExtension(view.originalFile),
        modelFileId: view.modelFileId,
    }
    return viewActive;
}

export function createViewActiveExt(viewId: ViewId, listViewer: FileInfo[], viewCurrent: ViewActive): ViewActive {
    const value = Utils.getFirstItem(listViewer, v => v.viewId === viewId);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { item: view, index } = value!;
    const viewActive: ViewActive = {
        viewId,
        baseFileId: viewCurrent.baseFileId,
        index,
        name: viewCurrent.name,
        formatViewer: view.converter,
        parentIdMerge: GlobalState.getParentMerge(viewId),
        extension: viewCurrent.extension,
        modelFileId: view.modelFileId
    }
    return viewActive;
}
export function setViewActive$(viewId: ViewId, multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const result: ResultHandleMultiView = {};
        const { 
            viewActive, 
            listViewerDisplay, 
            // listViewerVisible 
        } = multiViewerState;

        // save select state for PropertyGrid
        const temp = GlobalState.getSelectedStateTemp(viewActive.viewId)
        GlobalState.setSelectedState(viewActive.viewId, temp)
        const nodesSelected: number[] = [];
        Object.keys(temp).forEach(function (id) {
            if (temp[id]) {
                nodesSelected.push(Number.parseInt(id));
            }
        })
        GlobalState.setIdsSelectedGrid(viewId, nodesSelected);


        if(viewActive.viewId !== viewId){
            result.newViewActive = createViewActive(viewId, listViewerDisplay)
        }
        GlobalState.viewActive = result.newViewActive || null;
        obser.next(result);
        obser.complete()
    })
}
export function selectFilesAndLayoutObser(layout: Layout, listFileAdd: FileInfo[], multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    const numberLayout = Utils.getNumberFromTypeLayout(layout);
    const listViewOnlyVisible = getListViewOnlyVisible(multiViewerState);
    const cloneListFileAddTemp = [...listFileAdd, ...listViewOnlyVisible];

    // bo qua cac file combine
    const cloneListFileAdd = cloneListFileAddTemp.filter(v => {
        const baseViedId = GlobalState.getViewId(v.viewId);
        const baseMergeViewId = GlobalState.mapCombineFileChildParent.get(baseViedId);
        if (!baseMergeViewId) return v;
        return undefined;
    })
    const lengthList = cloneListFileAdd.length;
    const fileBlankAdd: ViewId[] = [];
    if (numberLayout < lengthList) {
        cloneListFileAdd.length = numberLayout
    } else if (numberLayout > lengthList) {
        const delta = numberLayout - lengthList;
        for (let i = 0; i < delta; i++) {
            const tempBlank = createBlankView();
            fileBlankAdd.push(tempBlank.viewId)
            cloneListFileAdd.push(tempBlank);
        }
    }
    return addViewerDisplayObser(cloneListFileAdd, multiViewerState).pipe(
        map(result => {
            result.newLayout = layout;
            if (fileBlankAdd.length > 0) {
                const cloneListViewBlank = [...multiViewerState.listViewerBlank];
                result.newListViewerBlank = [...cloneListViewBlank, ...fileBlankAdd];
            }
            return result;
        })
    )
}
export function selectViewerMergeObser(fileMerge: FileInfo, multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const result: ResultHandleMultiView = {};
        const listMerge = GlobalState.getParentChildrenMergeCombine(fileMerge.viewId);
        if (listMerge) {
            const { listViewerVisible, listViewerDisplay } = multiViewerState;
            const { children: listChildrenMerge } = listMerge;
            const viewMergeVisibleCurrentIndex = listViewerVisible.findIndex(v => listChildrenMerge.includes(v));
            if (viewMergeVisibleCurrentIndex !== -1) {
                if (!listViewerDisplay.map(v => v.viewId).includes(fileMerge.viewId)) {
                    result.newListViewerDisplay = [...listViewerDisplay, fileMerge];
                }
                const cloneListViewVisible = [...listViewerVisible];
                cloneListViewVisible.splice(viewMergeVisibleCurrentIndex, 1, fileMerge.viewId);
                result.newListViewerVisible = cloneListViewVisible;
                result.newViewActive = createViewActiveExt(fileMerge.viewId, result.newListViewerDisplay ?? listViewerDisplay, multiViewerState.viewActive);
            }
        }
        obser.next(result);
        obser.complete();
    })
}
export function addViewerDisplayObser(listFileAdd: FileInfo[], multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const { listViewerDisplay, viewActive } = multiViewerState;
        const result: ResultHandleMultiView = {};
        const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
        const mapViewIdDisplay = listViewerDisplay.map(v => v.viewId);
        const mapViewerCurrentListDisplayMerge = mapViewIdDisplay.map(v => {
            const parentId = GlobalState.getViewId(v);
            if (mapViewIdDisplay.includes(parentId)) {
                return v
            }
            return parentId
        })
        if (listFileAdd.length > 4) {
            listFileAdd.length = 4;
        }
        const mapViewIdFileAdd = listFileAdd.map(v => v.viewId);
        const arrDiff = _.difference(mapViewIdFileAdd, mapViewerCurrentListDisplayMerge);
        if (arrDiff.length > 0) {
            const arrFileInfoDiff = listFileAdd.filter(f => arrDiff.includes(f.viewId));
            result.newListViewerDisplay = [...cloneListViewDisplay, ...arrFileInfoDiff];
        }
        result.newListViewerVisible = mapViewIdFileAdd;
        if (!mapViewIdFileAdd.includes(GlobalState.getViewId(viewActive.viewId))) {
            result.newViewActive = createViewActive(mapViewIdFileAdd[0], result.newListViewerDisplay ?? listViewerDisplay);
        }
        // keep the currently sheet, after add file or change layout
        multiViewerState.listViewerVisible.forEach(vId => {
            if (!mapViewIdFileAdd.includes(vId) && mapViewIdFileAdd.length > 1) {
                const indexViewId = mapViewIdFileAdd.indexOf(GlobalState.getViewId(vId));
                if (indexViewId > -1) mapViewIdFileAdd[indexViewId] = vId;
                result.newListViewerVisible = mapViewIdFileAdd
            }
        });
        obser.next(result);
        obser.complete();
    })
}
export function closeViewerObser(viewId: ViewId, multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const { listViewerDisplay, listViewerBlank, listViewerVisible, viewActive, listViewOnly } = multiViewerState;
        const result: ResultHandleMultiView = {};
        const cloneListViewVisible = [...listViewerVisible];
        const cloneListViewBlank = [...listViewerBlank];
        const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
        const isBlankViewer = isBlankView(viewId, listViewerBlank);
        // if (!isBlankViewer) {
        // closeNotBlankView(viewId, cloneListViewDisplay, cloneListViewBlank, cloneListViewVisible, viewActive, result, listViewOnly)
        // } else {
        closeBlankView(viewId, cloneListViewDisplay, cloneListViewBlank, cloneListViewVisible, viewActive, result)
        // }
        obser.next(result);
        obser.complete();
    })
}
export function replaceViewerByBlank(viewId: ViewId, multiViewerState: MultiViewerState): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const { listViewerVisible, listViewerBlank, listViewerDisplay, viewActive } = multiViewerState;
        const result: ResultHandleMultiView = {};
        const findViewReplaced = listViewerVisible.find(v => GlobalState.getViewId(v) === viewId);
        if (findViewReplaced) {
            const listViewBlankNotVisible = getListViewBlankNotVisible(listViewerBlank, listViewerVisible);
            const indexFileReplace = listViewerVisible.findIndex(v => v === findViewReplaced);
            const cloneListViewVisible = [...listViewerVisible];
            let viewIdReplace = '';
            let viewIdBlank = '';
            if (listViewBlankNotVisible.length > 0) {
                const [firstBlank,] = listViewBlankNotVisible;
                viewIdBlank = firstBlank;
                viewIdReplace = firstBlank;
            } else {
                const newBlankFile = createBlankView();
                const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
                result.newListViewerDisplay = [...cloneListViewDisplay, newBlankFile];
                result.newListViewerBlank = [...listViewerBlank, newBlankFile.viewId];
                viewIdBlank = newBlankFile.viewId;
                viewIdReplace = newBlankFile.viewId;
            }
            cloneListViewVisible.splice(indexFileReplace, 1, viewIdBlank);
            result.newListViewerVisible = cloneListViewVisible;
            if (GlobalState.getViewId(viewActive.viewId) === viewId) {
                result.newViewActive = createViewActive(viewIdReplace, result.newListViewerDisplay ?? listViewerDisplay);
            }
        }
        obser.next(result);
        obser.complete();
    })
}
export function replaceBlankByViewer(
    viewId: ViewId,
    multiViewerState: MultiViewerState,
    listViewBlankVisible: ViewId[],
    fileInfoChecked: FileInfo | undefined
): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const { listViewerVisible, listViewerBlank, listViewerDisplay, viewActive } = multiViewerState;
        const result: ResultHandleMultiView = {};
        const [firstBlankVisible,] = listViewBlankVisible;
        const existFileChecked = listViewerDisplay.find(f => f.viewId === viewId);
        const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
        const cloneListViewBlank = [...listViewerBlank];
        const cloneListViewVisible = [...listViewerVisible];
        const indexFirstBlankVisible = listViewerVisible.findIndex(v => v === firstBlankVisible);
        let viewIdReplace = '';
        if (existFileChecked) {
            const newListViewerDisplay = _.remove(cloneListViewDisplay, v => v.viewId !== firstBlankVisible);
            result.newListViewerDisplay = newListViewerDisplay;
            cloneListViewVisible.splice(indexFirstBlankVisible, 1, existFileChecked.viewId);
            result.newListViewerVisible = cloneListViewVisible;
            viewIdReplace = existFileChecked.viewId;
        } else {
            if (fileInfoChecked) {
                const newListViewerDisplay = [...cloneListViewDisplay, fileInfoChecked];
                result.newListViewerDisplay = newListViewerDisplay;
                cloneListViewVisible.splice(indexFirstBlankVisible, 1, fileInfoChecked.viewId);
                result.newListViewerVisible = cloneListViewVisible;
                viewIdReplace = fileInfoChecked.viewId;
            }
        }
        result.newListViewerBlank = _.remove(cloneListViewBlank, v => v !== firstBlankVisible);
        if (viewActive.viewId === firstBlankVisible) {
            result.newViewActive = createViewActive(viewIdReplace, result.newListViewerDisplay ?? listViewerDisplay);
        }
        obser.next(result);
        obser.complete();
    })
}
export function createViewOnlyObser(multiViewerState: MultiViewerState, byViewId = '', isDragDrop = false): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(obser => {
        const result: ResultHandleMultiView = {};
        const { viewActive, layout, listViewerBlank, listViewerVisible, listViewOnly, listViewerDisplay } = multiViewerState;
        if (layout !== Layout.TwoAndTwo || isDragDrop) { // layout == Layout.TwoAndTwo is ignored. why?
            const viewIdRoot = byViewId || viewActive.viewId;
            const cloneListViewBlank = [...listViewerBlank];
            const cloneListViewVisible = [...listViewerVisible];
            const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
            const listChildrenViewOnlyByViewActive = GlobalState.getChildrenByRootId(viewIdRoot);
            const listViewOnlyByViewActive = listViewOnly.filter(o => listChildrenViewOnlyByViewActive.includes(o));
            const listBlankVisible = getListViewBlankVisible(listViewerBlank, listViewerVisible);
            if (listViewOnlyByViewActive.length > 0) {
                const listViewOnlyByViewActiveVisible = listViewOnlyByViewActive.filter(v => listViewerVisible.includes(v));
                if (listViewOnlyByViewActiveVisible.length === 0) {
                    const listViewOnlyByViewActiveNotVisible = listViewOnlyByViewActive.filter(v => !listViewerVisible.includes(v));
                    const [firstViewOnly,] = listViewOnlyByViewActiveNotVisible;
                    if (listBlankVisible.length > 0) {
                        updateResultViewOnly(result, firstViewOnly, listBlankVisible, cloneListViewBlank, cloneListViewDisplay, listViewerVisible, false);
                    } else {
                        insertViewOnly(result, cloneListViewVisible, firstViewOnly, layout)
                    }
                } else {
                    insertViewOnlyExtend(
                        result,
                        cloneListViewDisplay,
                        cloneListViewVisible,
                        listViewerVisible,
                        listBlankVisible,
                        cloneListViewBlank,
                        listViewOnly,
                        viewIdRoot,
                        layout
                    )
                }
            } else {
                insertViewOnlyExtend(
                    result,
                    cloneListViewDisplay,
                    cloneListViewVisible,
                    listViewerVisible,
                    listBlankVisible,
                    cloneListViewBlank,
                    listViewOnly,
                    viewIdRoot,
                    layout
                )
            }
        }
        obser.next(result);
        obser.complete();
    })
}
export function dragAndDropViewerHelper(dndResult: DragAndDropResult, multiViewerState: MultiViewerState, fileInfo: FileInfo | undefined): Observable<ResultHandleMultiView> {
    return new Observable<ResultHandleMultiView>(subscriber => {
        const result: ResultHandleMultiView = {};
        const { from: fromViewId, to: toViewId, place } = dndResult;
        const { listViewerVisible } = multiViewerState;
        const cloneListViewVisible = [...listViewerVisible];
        const fromIndex = cloneListViewVisible.findIndex(v => v === fromViewId);
        const toIndex = cloneListViewVisible.findIndex(v => v === toViewId);
        if (place === 'content-viewer') {
            if (fromIndex !== -1 && toIndex !== -1) {
                const newListViewerVisible = Utils.moveItemInArray(cloneListViewVisible, fromIndex, toIndex);
                if (newListViewerVisible) {
                    result.newListViewerVisible = newListViewerVisible;
                    subscriber.next(result)
                }
            }
        } else {
            if (fileInfo) {
                const { listViewerBlank, listViewerDisplay,viewActive } = multiViewerState;
                const cloneListViewDisplay = _.cloneDeep(listViewerDisplay);
                const toViewIdParent = GlobalState.getParentMerge(toViewId);
                const mapListViewVisibleParent = GlobalState.mapListMerge(cloneListViewVisible);
                if (toViewIdParent === fromViewId) {
                    result.newViewActive = createViewActive(toViewId, cloneListViewDisplay);
                } else {
                    const cloneFileInfo = _.cloneDeep(fileInfo);
                    const cloneListViewBlank = [...listViewerBlank];
                    const mapListIdViewDisplay = cloneListViewDisplay.map(v => v.viewId);
                    if (listViewerBlank.includes(toViewId)) { // neu keo vao viewer blank (toViewId la view blank)
                        if (mapListIdViewDisplay.includes(fromViewId)) {
                            if (!mapListViewVisibleParent.includes(fromViewId)) {
                                // neu file drag ko nam trong nhung file dang hien thi
                                cloneListViewVisible.splice(toIndex, 1, fromViewId);
                                result.newListViewerVisible = cloneListViewVisible;
                                result.newViewActive = createViewActive(fromViewId, cloneListViewDisplay);
                            } else {
                                // tao view only cho file drag
                                createViewOnlyObser(multiViewerState, fromViewId, true).pipe(take(1)).subscribe({
                                    next: (nextResultViewOnly) => subscriber.next(nextResultViewOnly),
                                    complete: () => subscriber.complete()
                                })
                            }
                        } else {
                            // neu file drag ko co trong list display
                            const tempListDisplay = _.remove(cloneListViewDisplay, f => f.viewId !== toViewId);
                            result.newListViewerDisplay = [...tempListDisplay, cloneFileInfo];
                            cloneListViewVisible.splice(toIndex, 1, fromViewId);
                            result.newListViewerVisible = cloneListViewVisible
                            if(toViewId === viewActive.viewId){
                                result.newViewActive = createViewActive(fromViewId, result.newListViewerDisplay ?? cloneListViewDisplay)
                            }
                        }
                        result.newListViewerBlank = _.remove(cloneListViewBlank, v => v !== toViewId);
                    } else {
                        // neu drag vao 1 viewer khac
                        if (mapListViewVisibleParent.includes(fromViewId)) {
                            // neu file drag dang hien thi => active
                            const viewIdChild = cloneListViewVisible.find(v => GlobalState.getViewId(v) === fromViewId);
                            if (viewIdChild) {
                                result.newViewActive = createViewActive(viewIdChild, cloneListViewDisplay);
                            }
                        } else {
                            // neu file drag dang khong hien thi
                            if (!mapListIdViewDisplay.includes(fromViewId)) {
                                // neu file drag ko co trong list display
                                result.newListViewerDisplay = [...cloneListViewDisplay, cloneFileInfo];
                            }
                            cloneListViewVisible.splice(toIndex, 1, fromViewId);
                            result.newListViewerVisible = cloneListViewVisible;                           
                            result.newViewActive = createViewActive(fromViewId, result.newListViewerDisplay ?? cloneListViewDisplay)
                        }
                    }
                }
                subscriber.next(result)
                subscriber.complete();
            }
        }
        subscriber.complete();
    })
}