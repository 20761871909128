const ConstantsRoot = {
    nameCustomer: 'Vulcan',
    widthMenu: 55,
}

export const CONSTANTS_VIEWER = {
    HOOP: {
        DELTA_EXPLODE: 30
    }
}

export enum StyleConstants {
    NAV_BAR_HEIGHT = '60px',
}

export const ABSOLUTE_TREE_ROOT = ['root', '0'];
export const defaultModelId = 'MODEL001';
export const defaultProjGuid = '9D73CB3DC867BF19E0536900A8C05238';
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export default ConstantsRoot;