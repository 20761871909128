/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DateTimeSize, DisplayUnits, FileExtension, FileInfo, Layout, LinkedObjectAPI, NotepinCategory, PanelFocus, PinMarkerColor, PinMarkerType, ValueProperties } from "common/define";
import { GlobalState } from "common/global";
import { MarkupEntity, MarkupMode, MarkupStatus, MarkupViewEntities, UserInfo } from "common/type-markup";
import { BootstrapState, CMICLinkedObjects, HostType, LinkedObjectFormat, LinkedObjectType, PCIDATA, RFISDATA, SBMDATA } from "common/type-state";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { format as fnsFormat } from 'date-fns';
import _ from 'lodash';
import { ObjectPropertyData } from "pages/main-viewer/right-panel/property/object/object.helper";
import { defer, Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { RFIstatusState } from 'common/type-state';
import { cloneDeep } from "lodash";
import { GUID_EMPTY } from "common/constants";

export const Lodash = _;

const mapNumberLayout: { [key: number]: Layout[] } = {
    1: [Layout.Full],
    2: [Layout.OneAndOne, Layout.OneOnOne],
    3: [Layout.OneAndTwo, Layout.OneOnTwo, Layout.TwoAndOne, Layout.TwoOnOne],
    4: [Layout.TwoAndTwo],
};
const CACHE_STATUS: { [key: number]: string } = {
    1: 'Caching',
    2: 'Cached',
    3: 'Error'
}

class Utils {
    public static BloomRatioCoefficient = 0.5;
    public static isAvaiableId(uniqueViewId: any) {
        return (uniqueViewId !== null && uniqueViewId !== undefined);
    }
    static setLocalStorage(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static getValueLocalStorage(key: string): any | null {
        const value = localStorage.getItem(key);
        let re = null;
        value && (re = Utils.parseJson(value));
        return re;
    }
    static removeItemLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
    static parseJson(str: string): any | null {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }
    static getHostUrlFromState(state: BootstrapState, host: HostType): string | null {
        const { systemConfig } = state;
        if (systemConfig) {
            return `${systemConfig.protocol}://${systemConfig[host]}`;
        }
        return null;
    }
    static getFileNameWithoutExtension(fileOrigin: string | undefined): string {
        if (fileOrigin) {
            const lastDot = fileOrigin.lastIndexOf('.');
            if (lastDot !== -1) {
                return fileOrigin.substring(0, fileOrigin.lastIndexOf('.'))
            } else {
                return fileOrigin
            }
        }
        return '';
    }
    static getFileExtension(fileName: string | undefined): string {
        if (fileName) {
            const lastDot = fileName.lastIndexOf('.');
            const endName = fileName.length;
            if (lastDot > 0 && lastDot !== (endName - 1)) {
                return fileName.substring(lastDot + 1, endName).toLowerCase()
            }
        }
        return ''
    }
    static parseUrl(obj: { [key: string]: string }): URLSearchParams {
        const params = new URLSearchParams();
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (key && value) {
                params.set(key, value)
            }
        })
        return params;
    }
    static getNumberFromTypeLayout(layout: Layout): number {
        let re = 1;
        Object.keys(mapNumberLayout).some((key) => {
            if (mapNumberLayout[+key].includes(layout)) {
                re = +key;
                return true;
            }
            return false;
        })
        return re;
    }
    static getLayoutsByNumber(num: number): Layout[] {
        return mapNumberLayout[num];
    }
    static getFirstLayoutByNumber(num: number): Layout {
        return _.head(mapNumberLayout[num]) ?? Layout.Full;
    }
    static getFirstItem<T>(list: T[], condition: (item: T) => boolean): { item: T, index: number } | null {
        let re: { item: T, index: number } | null = null;
        list.some((item, index) => {
            if (condition(item)) {
                re = { item, index };
                return true;
            }
            return false;
        });
        return re;
    }
    static isRectangleEnoughLarge(x1: number, y1: number, x2: number, y2: number): boolean {
        return (Math.abs(x2 - x1) >= 2 && Math.abs(y2 - y1) >= 2);
    }
    static getModelFileIdFromViewId(viewId: ViewId, fileList: FileInfo[]): { modelFileId: ModelFileId | undefined, fileInfo: FileInfo | undefined } {
        if (viewId && fileList.length > 0) {
            const file = fileList.find(f => f.viewId === viewId);
            if (file) {
                return {
                    modelFileId: file.modelFileId,
                    fileInfo: file
                }
            }
        }
        return {
            modelFileId: undefined,
            fileInfo: undefined
        }
    }

    static formatTimeAgo(createdDate: string): string {
        let result: string;
        const now = new Date().getTime();
        const newDate = new Date(createdDate);
        const delta = (now - newDate.getTime());
        const totalSeconds = Math.round(delta / 1000);
        if (totalSeconds > 0) {
            if (totalSeconds < 60) {
                result = `${totalSeconds} ${totalSeconds === 1 ? 'second' : 'seconds'}`;
            } else if (totalSeconds >= 60 && totalSeconds < 3600) {
                const totalMinutes = Math.round(delta / 1000 / 60);
                result = `${totalMinutes} ${totalMinutes === 1 ? 'minute' : 'minutes'}`;
            } else if (totalSeconds >= 3600 && totalSeconds < 86400) {
                const totalHours = Math.round(delta / 1000 / 60 / 60);
                result = `${totalHours} ${totalHours === 1 ? 'hour' : 'hours'}`;
            } else if (totalSeconds < 31536000 && totalSeconds >= 2592000) {
                const totalMonths = Math.round(delta / 1000 / 60 / 60 / 24 / 30);
                result = `${totalMonths} ${totalMonths === 1 ? 'month' : 'months'}`;
            } else {
                const totalDays = Math.round(delta / 1000 / 60 / 60 / 24);
                result = `${totalDays} ${totalDays === 1 ? 'day' : 'days'}`;
            }
        } else {
            return '';
        }
        return `${result} ago`;
    }

    static formatDate(json: string): string {
        const date = new Date(JSON.parse(json));
        if (date instanceof Date) {
            return fnsFormat(date, 'MMM dd, yyyy');
        }
        return ''
    }

    static convertBytes(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) {
            return '';
        }
        const a = Math.log(bytes) / Math.log(1024);
        const i = parseInt(Math.floor(a).toString(), 10);

        if (i === 0) {
            return `${bytes} ${sizes[i]}`;
        }

        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    }

    static convertStatus(cacheStatus: number): string {
        return CACHE_STATUS[cacheStatus] ?? 'No-cache'
    }
    static createParamDownload(fileInfo: FileInfo, ext = 'pdf'): { path: string } {
        const { streamLocation, filename } = fileInfo;
        const fileNameSub = filename.substring(0, filename.lastIndexOf('.'));
        return {
            path: `${streamLocation}/${fileNameSub}.${ext}`
        }
    }
    static moveItemInArray<T>(array: T[], fromIndex: number, toIndex: number): T[] | undefined {
        const itemFrom = array.find((f, i) => i === fromIndex);
        const itemTo = array.find((f, i) => i === toIndex);
        if (itemFrom && itemTo) {
            array.splice(toIndex, 1, itemFrom);
            array.splice(fromIndex, 1, itemTo);
            return array;
        }
        return undefined
    }
    static ConvertDateNowToString(): string {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const date = now.getDate();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const second = now.getSeconds();
        return `${year}-${month + 1}-${date} ${hours}:${minutes}:${second}`;
    }
    static setPartialState<T>(state: T, part: Partial<T>): T {
        return { ...state, ...part };
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static stringValueObject(obj: any): { [key: string]: string } | undefined {
        if (obj) {
            if (typeof obj === 'object') {
                return obj
            }
        }
        return
    }
    static splitLongString(str: string, numberSplit = 25): string {
        if (str) {
            const numS = numberSplit - 3;
            const length = str.length;
            if (length > numS) {
                const sub = str.substring(0, numberSplit - 3);
                return `${sub}...`
            }
            return str
        }
        return ''
    }
    static getInfoDateSizeFileInfo(fileInfo: FileInfo): DateTimeSize {
        const size = fileInfo.originalSize;
        const dateTime = fileInfo.createdDate;
        let date = '';
        let time = '';
        let sizeFormat = '';
        if (dateTime) {
            const dateAs = new Date(dateTime);
            date = fnsFormat(dateAs, 'MM/dd/yyyy');
            time = fnsFormat(dateAs, 'h:ma');
            sizeFormat = Utils.convertBytes(size as number);
        }
        return {
            date,
            time,
            size: sizeFormat
        }
    }
    static createArrayByLength(num: number): number[] {
        return Array.from(Array(num).keys());
    }
    static getValueNumberFromValueProperties(valueProperties: ValueProperties, key: string): number | undefined {
        if (valueProperties) {
            const resultKey = valueProperties[key];
            if (resultKey) {
                const id = Number(resultKey);
                if (!Number.isNaN(id)) {
                    return id;
                }
            }
        }
        return
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static convertPropertiesResponse(object: any): ValueProperties {
        let input = object;
        if (typeof object === 'string') {
            input = Utils.parseJson(object)
        }
        if (input && typeof input === 'object') {
            if (Object.entries(input).length > 0) {
                Object.keys(input).forEach(key => {
                    const keyConvert$ = key.replace(/%36/gi, '$');
                    const keyConvertDot = keyConvert$.replace(/%46/gi, '.');
                    key = keyConvertDot
                })
                if (input['_id']) {
                    delete input['_id']
                }
                return input
            }
        }
        return
    }
    static newPropsConverter(object: any): ObjectPropertyData | undefined{
        let input = object;
        if (typeof object === 'string') {
            input = Utils.parseJson(object)
        }
        if (Object.entries(input).length > 0) {
            Object.keys(input).forEach(key => {
                const keyConvert$ = key.replace(/%36/gi, '$');
                const keyConvertDot = keyConvert$.replace(/%46/gi, '.');
                key = keyConvertDot
            })
            if (input['_id']) {
                delete input['_id']
            }
            return input
        }
        return
    }
    static convertArrPropertiesResponse(arrObject: any): ValueProperties[] {
        let input = arrObject;
        if (typeof arrObject === 'string') {
            input = Utils.parseJson(arrObject)
        }
        if (Array.isArray(input)) {
            return input.map(pro => Utils.convertPropertiesResponse(pro))
        }
        return []
    }
    static unsetPathObject<T>(obj: T, keyUnSet: string): T | undefined {
        const cloneObj = _.cloneDeep(obj);
        const re = _.unset(cloneObj, keyUnSet);
        if (re) {
            return cloneObj
        }
    }
    static delay(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    static regexStreamLocation(location: string): string {
        const regex = /(.*)(\\\\|\\|\/|\/\/).*$/mg;
        const match = regex.exec(location);
        if (match && match.length === 3) {
            return match[1]
        }
        return ''
    }
    static getModelNameByStreamLocation(fileInfo: FileInfo): string {
        const { streamLocation, filename } = fileInfo;
        const regex = /.*(\\\\|\\|\/|\/\/)(.*)$/mg;
        const matchNameLocation = regex.exec(streamLocation);
        if (matchNameLocation && matchNameLocation.length === 3) {
            const modelName = Utils.getFileNameWithoutExtension(filename);
            return `${matchNameLocation[2]}\\${modelName}`
        }
        return ''
    }
    static calDistanceMove(eDown: React.MouseEvent, eMove: React.MouseEvent): DeltaXY {
        const { clientX: xDown, clientY: yDown } = eDown;
        const { clientX: xMove, clientY: yMove } = eMove;
        const xDelta = xMove - xDown;
        const yDelta = yMove - yDown;
        return {
            xDelta, yDelta
        }
    }
    static transformArrPropertyTable(arrOrigin: ValueProperties[]): { data: any[], maxCol: number } {
        let maxLength = 0;
        const transform1: any[] = [];
        arrOrigin.forEach(val => {
            if (val) {
                const newval = Object.entries(val);
                maxLength = Math.max(maxLength, newval.length);
                transform1.push(newval)
            }
        });
        const result: any[] = [];
        let maxCol = 1;
        const arr: number[] = [];
        if (maxLength > 0) {
            for (let i = 0; i < maxLength; i++) {
                let temp = { key: i };
                transform1.forEach((item, index) => {
                    const newItem = item[i];
                    if (newItem) {
                        const newVal = {
                            [`key${index}`]: newItem
                        }
                        temp = { ...temp, ...newVal };
                        arr.push(index + 1)
                    }
                })
                result.push(temp)
            }
            maxCol = Math.max(...arr);
        }
        return {
            data: result,
            maxCol
        }
    }

    public static newGuid() {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    public static getAuthField(type: 'ALL' | 'RFI' | 'SUB' | 'DOC' | 'PCI' | 'CostCode' | 'CostType' | 'Other') {
        let customerKey = 'da';
        let customerSecret = '4850keele';
        if (type === 'Other') {
            customerKey = "BBICKER"
            customerSecret = "Apple987#"
        }
        const credential = customerKey + ":" + customerSecret;
        const encodedCredential = Buffer.from(credential).toString('base64');
        const authorizedField = "Basic " + encodedCredential;
        return authorizedField;
    }
    static firstUpperCase(str: string): string {
        return str.length > 0 ? `${str[0].toUpperCase()}${str.slice(1, str.length)}` : ''
    }
    public static hexToRgb(hexStr: string) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        const hex = hexStr.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    }

    public static rgbToHex(red: number, green: number, blue: number) {
        return `#${red >= 16 ? red.toString(16) : `0${red.toString(16)}`}${green >= 16 ? green.toString(16) : `0${green.toString(16)}`}${blue >= 16 ? blue.toString(16) : `0${blue.toString(16)}`}`;
    }
    static isCaseMultiStreamUseOneTree(viewId: ViewId, fileName: string): string {
        const viewIdFinal = GlobalState.getViewId(viewId);
        if (viewIdFinal !== viewId) {
            const ext = Utils.getFileExtension(fileName);
            if (ext === FileExtension.Revit) {
                return viewIdFinal
            }
        }
        return viewId
    }
    static getUserInfo(): UserInfo {
        const userInfo: UserInfo = {
            id: Utils.getValueLocalStorage('userID'),
            userName: Utils.getValueLocalStorage('userName'),
        }
        return userInfo;
    }

    static mergeArrWithoutOverwrite(arr1: any[], arr2: any[], extension: string): any[] {
        const REAL_NODE_ID = 'RealNodeId';
        // if (extension === 'rvt' || extension === 'nwd'){
        //     const result = arr2.map(item => {
        //         const match = arr1.find(hoop => hoop.persistentId === item.persistentId)
        //         return {
        //             ...item,
        //             RealNodeId: match[REAL_NODE_ID]?? ''
        //         }
        //     })
        //     return result
        // }
        const newHoopsProp = arr1.map(v => {
            let newObj: any = {};
            if (!v) return newObj;
            const objValues = Object.values(v);
            const valArr2 = arr2.find(x => x.persistentId === v.persistentId);
            const newKeys = Object.keys(v).map((val, index) => {
                if (!valArr2) return val;
                const checkKeyDup = Object.keys(valArr2).includes(val);
                if (checkKeyDup) {
                    const checkValueDup = valArr2 && (valArr2!)[val] === objValues[index];
                    if (checkValueDup) return val;
                    return `*${val}`;
                }
                return val;
            })
            newKeys.forEach((val, idx) => newObj = { ...newObj, ...{ [val]: Object.values(v)[idx] } });
            return newObj;
        });
        const result: any[] = [];
        for (let i = 0; i < arr2.length; i++) {
            const valHoop = newHoopsProp.find(x => x.persistentId === arr2[i].persistentId);
            if (valHoop) result.push({ ...arr2[i], ...valHoop });
        }
        const mapNodeIds = result.map(v => v[REAL_NODE_ID]) as string[];
        const remainHoops = arr1.filter(v => !mapNodeIds.includes(v[REAL_NODE_ID]));
        const finalResult = result.concat(remainHoops)
        return finalResult;
    }

    static parseAddtionalField(object: any) {
        let fields: string[] = ['Component', 'Category', 'Family', 'Type'];
        const notPrioritizeEquality = ['Category'];
        const objValues = Object.values(object);
        const objKeys = Object.keys(object);
        let newObj = {};
        objKeys.forEach((key, i) => {
            const field = fields.find(f => f.toLowerCase() === key.toLowerCase());
            if (!field || notPrioritizeEquality.includes(field)) return;
            newObj = { ...newObj, ...{ [`_${field}`]: objValues[i] } };
            fields = fields.filter(v => v !== field);
        })
        Array(2).fill(0).slice().forEach((val, index) => {
            objKeys.forEach((key, i) => {
                fields.forEach(field => {
                    if (key.toLowerCase().includes(field.toLowerCase()) && (isNaN(Number(objValues[i])) || index === 1)) {
                        newObj = { ...newObj, ...{ [`_${field}`]: objValues[i] } };
                        fields = fields.filter(v => v !== field);
                    }
                })
            });
        })
        return newObj;
    }

    static getRayCastPoint(
        // temp fix for hoops pick face selection
        selection: Communicator.Selection.FaceSelectionItem,
        point: Communicator.Point2,
        viewer: Communicator.WebViewer,
    ): Communicator.Point3 | null {
        const { min, max } = selection.getFaceEntity().getBounding();
        const pos = selection.getPosition();

        const checkPos = (pos.x >= min.x && pos.x <= max.x) &&
            (pos.y >= min.y && pos.y <= max.y) &&
            (pos.z >= min.z && pos.z <= max.z);
        if (checkPos) return pos;

        const plane = Communicator.Plane.createFromPointAndNormal(min, selection.getFaceEntity().getNormal());
        const ray = viewer.view.raycastFromPoint(point);
        if (!ray) return null;
        const intersectionPoint = pos.copy();
        if (plane.intersectsRay(ray, intersectionPoint)) return intersectionPoint;
        return null;
    }
    static async getPickSelectionPoint(position: Communicator.Point2, viewer: Communicator.WebViewer): Promise<Communicator.Point3 | null> {
        const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.All);
        let selectionPos: Communicator.Point3 | null = null;
        if (viewer.view.getNavCube().insideOverlay(position)) return null;
        const selection = await viewer.view.pickFromPoint(position, pickConfig)
        if (selection.isFaceSelection()) {
            selectionPos = Utils.getRayCastPoint(selection, position, viewer);
        } else {
            selectionPos = selection.getPosition();
        }
        return selectionPos;
    }


    static createMarkupEntity(originData: any, markupViewEntity: MarkupViewEntities): MarkupEntity {
        const markupEntity: MarkupEntity = {
            uniqueId: markupViewEntity.uniqueId,
            status: MarkupStatus.Open,
            uniqueViewId: markupViewEntity.uniqueId,
            type: originData.className,
            createdDate: markupViewEntity.createdDate,
            modifiedDate: markupViewEntity.modifiedDate,
            createdBy: markupViewEntity.lastModifiedBy,
            lastModifiedBy: markupViewEntity.lastModifiedBy,
            uniqueGroupId: '',
            originData: originData,
            modeMarkup: MarkupMode.Mode2d,
        }
        return markupEntity;
    }

    static createCubeMeshData() {
        const length = -0.5;
        const width = 0.5;
        const axisX = new Communicator.Point3(1, 0, 0);
        const axisY = new Communicator.Point3(0, 1, 0);
        const axisZ = new Communicator.Point3(0, 0, 1);
        const invAxisX = new Communicator.Point3(-1, 0, 0);
        const invAxisY = new Communicator.Point3(0, -1, 0);
        const invAxisZ = new Communicator.Point3(0, 0, -1);
        const p1 = new Communicator.Point3(-width, width, width);
        const p2 = new Communicator.Point3(-width, length, width);
        const p3 = new Communicator.Point3(width, length, width);
        const p4 = new Communicator.Point3(width, width, width);
        const p5 = new Communicator.Point3(-width, width, -width);
        const p6 = new Communicator.Point3(-width, length, -width);
        const p7 = new Communicator.Point3(width, length, -width);
        const p8 = new Communicator.Point3(width, width, -width);
        const axisFaces = [
            p1, p2, p3,
            p3, p4, p1,
            p6, p5, p8,
            p8, p7, p6,
            p1, p5, p6,
            p6, p2, p1,
            p4, p3, p7,
            p7, p8, p4,
            p5, p1, p4,
            p4, p8, p5,
            p2, p6, p7,
            p7, p3, p2,
        ];
        const axisNormals = [
            axisZ,
            invAxisZ,
            axisY,
            invAxisY,
            axisX,
            invAxisX,
        ];
        const faceData: number[] = [];
        axisFaces.forEach((p) => {
            faceData.push(p.x);
            faceData.push(p.y);
            faceData.push(p.z);
        });
        const normalData: number[] = [];
        axisNormals.forEach((p) => {
            for (let i = 0; i < 6; ++i) {
                normalData.push(p.x);
                normalData.push(p.y);
                normalData.push(p.z);
            }
        });
        const axisMeshData = new Communicator.MeshData();
        axisMeshData.addFaces(faceData, normalData);
        axisMeshData.setFaceWinding(Communicator.FaceWinding.Clockwise);
        axisMeshData.setBackfacesEnabled(true);
        return axisMeshData;
    }

    static numberWithCommas(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static calcFloatPanelIndex(floatFocus: PanelFocus[], title: PanelFocus) {
        return floatFocus.indexOf(title) + 900;
    }

    static arrayPartition(array: any[], predicate: (item: any) => boolean): [any[], any[]] {
        return array.reduce(
            (pair, item) => {
                const condition = predicate(item);
                (condition ? pair[0] : pair[1]).push(item);
                return pair;
            },
            [[], []]
        )
    }

    static getGUIDName(obj: any) {
        let id = 'id';
        if (typeof obj === 'object') {
            Object.keys(obj).forEach(x => {
                if (x.toLocaleLowerCase().includes('guid')) {
                    id = x;
                }
            });
        }
        return id;
    }

    static getIDName(type: PinMarkerType) {
        return LinkedObjectIDName[type];
    }
    static getNormal(p1: Communicator.Point3, p2: Communicator.Point3, p3: Communicator.Point3) {
        const v = p2.copy().subtract(p1);
        const u = p3.copy().subtract(p1);
        const n = new Communicator.Point3(0, 0, 0);
        n.x = u.y * v.z - u.z * v.y;
        n.y = u.z * v.x - u.x * v.z;
        n.z = u.x * v.y - u.y * v.x;
        return n.normalize();
    }

    static crossUV(u: Communicator.Point3, v: Communicator.Point3) {
        const n = new Communicator.Point3(0, 0, 0);
        n.x = u.y * v.z - u.z * v.y;
        n.y = u.z * v.x - u.x * v.z;
        n.z = u.x * v.y - u.y * v.x;
        return n.normalize();
    }

    static distancePointAndSegment(A: Communicator.Point3, B: Communicator.Point3, E: Communicator.Point3) {
        // vector AB
        const AB = new Communicator.Point2(B.x - A.x, B.y - A.y);

        // vector BP
        const BE = new Communicator.Point2(E.x - B.x, E.y - B.y);
        // vector AP
        const AE = new Communicator.Point2(E.x - A.x, E.y - A.y);

        // Calculating the dot product
        const AB_BE = (AB.x * BE.x + AB.y * BE.y);
        const AB_AE = (AB.x * AE.x + AB.y * AE.y);

        // Minimum distance from
        // point E to the line segment
        let reqAns = 0;

        // Case 1
        if (AB_BE > 0) {
            // Finding the magnitude
            const y = E.y - B.y;
            const x = E.x - B.x;
            reqAns = Math.sqrt(x * x + y * y);
        }
        // Case 2
        else if (AB_AE < 0) {
            const y = E.y - A.y;
            const x = E.x - A.x;
            reqAns = Math.sqrt(x * x + y * y);
        }
        // Case 3
        else {
            // Finding the perpendicular distance
            const x1 = AB.x;
            const y1 = AB.y;
            const x2 = AE.x;
            const y2 = AE.y;
            const mod = Math.sqrt(x1 * x1 + y1 * y1);
            reqAns = Math.abs(x1 * y2 - y1 * x2) / mod;
        }
        return reqAns;
    }
    static isPointInPolygon (p: any, polygon: any[]) {
        const x = p.x; const y = p.y;

        let inside = false
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].x; const yi = polygon[i].y;
            const xj = polygon[j].x; const yj = polygon[j].y

            const intersect = ((yi > y) !== (yj > y)) &&
                  (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
            if (intersect) inside = !inside
        }

        return inside
    }

    static UnitConverter(value: number, from: 'mm', to: DisplayUnits, unitType: 'volume' | 'area' | 'length', precision: number) {
        let valNumber: string = value.toString();
        switch (unitType) {
        case 'length':
            valNumber = this.LengthConverter(value, to, precision);
            break;
        case 'area':
            valNumber = this.AreaConverter(value, to, precision);
            break;
        case 'volume':
            valNumber = this.VolumeConverter(value, to, precision);
            break;
        default:
            break;
        }
        return valNumber;
    }

    static LengthConverter(value: number, to: DisplayUnits, precision: number) {
        let valNumber: string = value.toString();
        switch (to) {
        case DisplayUnits.Milimeter:
            valNumber = `${Lodash.round(value, precision)} mm`;
            break;
        case DisplayUnits.Centimeter:
            valNumber = `${Lodash.round(value*0.1, precision)} cm`;
            break;
        case DisplayUnits.Meter:
            valNumber = `${Lodash.round(value*0.001, precision)} m`;
            break;
        case DisplayUnits.Feet:
            valNumber = `${Lodash.round(value*0.0032808399, precision)} ft`;
            break;
        default:
            break;
        }
        return valNumber;
    }

    static AreaConverter(value: number, to: DisplayUnits, precision: number) {
        let valNumber: string = value.toString();
        switch (to) {
        case DisplayUnits.Milimeter:
            valNumber = `${Lodash.round(value, precision)} mm²`;
            break;
        case DisplayUnits.Centimeter:
            valNumber = `${Lodash.round(value*0.01, precision)} cm²`;
            break;
        case DisplayUnits.Meter:
            valNumber = `${Lodash.round(value*Math.pow(10,-6), precision)} m²`;
            break;
        case DisplayUnits.Feet:
            valNumber = `${Lodash.round(value*1.07639104*Math.pow(10,-5), precision)} ft²`;
            break;
        default:
            break;
        }
        return valNumber;
    }

    static VolumeConverter(value: number, to: DisplayUnits, precision: number) {
        let valNumber: string = value.toString();
        switch (to) {
        case DisplayUnits.Milimeter:
            valNumber = `${Lodash.round(value, precision)} mm³`;
            break;
        case DisplayUnits.Centimeter:
            valNumber = `${Lodash.round(value*0.001, precision)} cm³`;
            break;
        case DisplayUnits.Meter:
            valNumber = `${Lodash.round(value*Math.pow(10,-9), precision)} m³`;
            break;
        case DisplayUnits.Feet:
            valNumber = `${Lodash.round(value*3.53146667*Math.pow(10,-8), precision)} ft³`;
            break;
        default:
            break;
        }
        return valNumber;
    }
    static GetLinkedObjectData(CMICData: CMICLinkedObjects, link?: string, name?: string): LinkedObjectFormat {
        function getData(object: RFISDATA[] | SBMDATA[] | PCIDATA[] | undefined, type: PinMarkerType) {
            const arrayData: LinkedObjectAPI[] = [];
            if (object && Array.isArray(object)) {
                for (let i = 0; i < object?.length; i += 1) {
                    const value = cloneDeep(object[i]) as LinkedObjectAPI;
                    if (value) {
                        if (type) {
                            value._type = type
                        }
                        arrayData.push(value)
                    }
                }
            }
            return arrayData;
        }
        const LinkedObject: LinkedObjectFormat = {
            modelId: CMICData.data[0]?.modelId,
            RFI: {
                "items": [],
                "count": 0,
                "hasMore": true,
                "limit": 50,
                "offset": 0,
                "links": [
                    {
                        "rel": "self",
                        "href": link || '',
                        "name": name || '',
                        "kind": "collection"
                    }
                ]
            },
            PCI: {
                "items": [],
                "count": 0,
                "hasMore": true,
                "limit": 50,
                "offset": 0,
                "links": [
                    {
                        "rel": "self",
                        "href": link || '',
                        "name": name || '',
                        "kind": "collection"
                    }
                ]
            },
            SUB: {
                "items": [],
                "count": 0,
                "hasMore": true,
                "limit": 50,
                "offset": 0,
                "links": [
                    {
                        "rel": "self",
                        "href": link || '',
                        "name": name || '',
                        "kind": "collection"
                    }
                ]
            },
            DOC: {
                "items": [],
                "count": 0,
                "hasMore": true,
                "limit": 50,
                "offset": 0,
                "links": [
                    {
                        "rel": "self",
                        "href": link || '',
                        "name": name || '',
                        "kind": "collection"
                    }
                ]
            },
        }
        if (LinkedObject) {
            CMICData.data.forEach((value) => {
                try {
                    if (value) {
                        const lstRFI = getData(value.rfisData, PinMarkerType.RFI);
                        if (lstRFI && LinkedObject.RFI && LinkedObject.RFI.items) {
                            const newList = LinkedObject.RFI.items.concat(lstRFI)
                            LinkedObject.RFI.items = newList;
                            LinkedObject.RFI.count = LinkedObject.RFI.items.length;
                        }
                        const lstSUB = getData(value.sbmData, PinMarkerType.SUB);
                        if (lstSUB && LinkedObject.SUB && LinkedObject.SUB.items) {
                            const newList = LinkedObject.SUB.items.concat(lstSUB)
                            LinkedObject.SUB.items = newList;
                            LinkedObject.SUB.count = LinkedObject.SUB.items.length;
                        }
                        const lstPCI = getData(value.pciData, PinMarkerType.PCI);
                        if (lstPCI && LinkedObject.PCI && LinkedObject.PCI.items) {
                            const newList = LinkedObject.PCI.items.concat(lstPCI)
                            LinkedObject.PCI.items = newList;
                            LinkedObject.PCI.count = LinkedObject.PCI.items.length;
                        }
                    }
                } catch (error) {
                    console.log('error: ', error);
                }
            })
        }

        return LinkedObject
    }

    static getLinkedObjectById(id: string, RFIstatus: RFIstatusState, type: PinMarkerType): LinkedObjectAPI | null | undefined {
        const { RFI, SUB, DOC, PCI } = RFIstatus.data;
        let obj = null
        switch (type) {
        case PinMarkerType.RFI:
            obj = RFI?.items?.find((i) => i.rfiId === id);
            break;
        case PinMarkerType.SUB:
            obj = SUB?.items?.find((i) => i.sbmId === id);
            break;
        case PinMarkerType.PCI:
            obj = PCI?.items?.find((i) => i.pciCode === id);
            break;
        case PinMarkerType.DOC:
            obj = DOC?.items?.find((i) => i.PmrfiRfiId === id);
            break;
        default:
            break;
        }
        return obj;
    }

    static getLinkedObjectTypeById(type: PinMarkerType): LinkedObjectType | '' {
        switch (type) {
        case PinMarkerType.RFI:
            return LinkedObjectType.RFI;
        case PinMarkerType.SUB:
            return LinkedObjectType.SUB;
        case PinMarkerType.PCI:
            return LinkedObjectType.PCI;
        case PinMarkerType.DOC:
            return LinkedObjectType.DOC;
        default:
            break;
        }
        return ''
    }

    static checkViewOnly(viewId: string) : boolean{
        return viewId?.includes('view-only');
    }

    static DataURIToBlob(dataURI: string) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    
    static RgbToCommunicatorColor(rgbText: string): Communicator.Color | null {
        const regex = /^rgba?\s*\((\d+),\s*(\d+),\s*(\d+)/i;
        const cal = regex.exec(rgbText);
    
        if (cal) {
            return new Communicator.Color(+cal[1], +cal[2], +cal[3]);
        }
        return null;
    }
    static setNodeColor = (nodeIds: number[], currentColor: string, viewer: Communicator.WebViewer) => {
        if (viewer) {
            const colorMap = new Map<number, Communicator.Color>();
            const color = this.RgbToCommunicatorColor(currentColor) as any;
            nodeIds.forEach((node) => {
                colorMap.set(node, color);
            });
            viewer.model.setNodesColors(colorMap, true);
        }
        // nodeSettingsService.setNodeIds(nodeIds);
    };
    static getProjectGUIDByBaseFileId(baseFileId: string): string {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = urlParams.get('fileList');
        if (queryParams) {
            const parseQuery = Utils.parseJson(queryParams);
            if (parseQuery && Array.isArray(parseQuery)) {
                const fileParams = parseQuery.find(x => x.baseFileId === baseFileId);
                if (fileParams) {
                    return fileParams.projGUID;
                }
            }
        }
        return GUID_EMPTY;
    }

    static getProjectGUIDByModelFileId(modelFileId: string): string {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = urlParams.get('fileList');
        if (queryParams) {
            const parseQuery = Utils.parseJson(queryParams);
            if (parseQuery && Array.isArray(parseQuery)) {
                const fileParams = parseQuery.find(x => x.modelFileId === modelFileId);
                if (fileParams) {
                    return fileParams;
                }
            }
        }
        return GUID_EMPTY;
    }

    static getProjectGUIDFromUrl(url: string): string {
        const urlParams = new URLSearchParams(url);
        const projectGuid = urlParams.get('projectGUID');
        return projectGuid ? projectGuid : GUID_EMPTY;
    }
}

export default Utils;
export function finalizeWithValue<T>(callback: (value: T) => void) {
    return (source: Observable<T>): Observable<T> => defer(() => {
        let lastValue: T;
        return source.pipe(
            tap((value) => lastValue = value),
            finalize(() => callback(lastValue)),
        );
    });
}

export const defaultNotepinCategory: NotepinCategory[] = [
    {
        type: PinMarkerType.DOC,
        color: PinMarkerColor.RED,
    },
    {
        type: PinMarkerType.SUB,
        color: PinMarkerColor.GREEN,
    },
    {
        type: PinMarkerType.RFI,
        color: PinMarkerColor.YELLOW,
    },
    {
        type: PinMarkerType.PCI,
        color: PinMarkerColor.BLUE,
    },
]
interface LineDash {
    name: string,
    data: string,
}
export const dashArray: LineDash[] = [
    { name: 'Continuous', data: "m 0 12 h 140" },
    { name: 'Large Dash', data: MathHelper.calDashString('m 5 12', 'h 16 m 16 0', 6) },
    { name: 'Small Dash', data: MathHelper.calDashString('m 5 12', 'h 10 m 10 0', 6) },
    { name: 'Center', data: MathHelper.calDashString('m 5 12 h 15', 'm 12 0 h 5 m 12 0 h 20', 6) },
    { name: 'Phantom', data: MathHelper.calDashString('m 5 12 h 15', 'm 12 0 h 5 m 12 0 h 5 m 12 0 h 15', 66) },
    { name: 'Zigzag', data: MathHelper.calDashString('m 5 15', 'l 10 -10 l 10 10', 8) },
    { name: 'Cloud', data: MathHelper.calDashString('m 5 15', 'a 1 1 0 0 1 20 0', 8) },
]

export const fontFamilies = ['Arial', 'Segoe UI', 'Roboto', 'Courier New', 'Verdana'];
export const fontSizes = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 72];
export const primaryBoundingColor = {
    hex: '#5aa',
    rgb: 'rgb(85,170,170)',
    communicatorColor: new Communicator.Color(85, 170, 170),
};

export const innerPad = new Communicator.Point2(8, 2);

export const boundPadding = 12;
export const cloudPadding = 26;

export const LinkedObjectIDName = {
    'RFI': {
        id: 'VUuid',
        code: 'PmrfiRfiId',
        name: 'PmrfiSubject',
    },
    'SUB': {
        id: 'PmsmVUuid',
        code: 'PmsmSbmtId',
        name: 'PmsmSbmtStatusCode',
    },
    'PCI': {
        id: 'CmmVUuid',
        code: 'CmmCode',
        name: 'CmmName',
    },
    'DOC': {
        id: 'PmrfiVUuid',
        code: 'PmrfiRfiId',
        name: 'PmrfiSubject',
    }
}
