/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalState } from "common/global";
import CameraOrbitOperator from "../custom-operator/camera-orbit.operator";
import GeneralOperator from "../custom-operator/general.operator";
import SelectWindow from "../custom-operator/select-window.operator";
import BaseOperator from "./base.operator";

export default class AreaSelectOperator extends BaseOperator {
    generalOperator: GeneralOperator;
    generalHandle: any;
    selectWindowOperator: SelectWindow;
    selectWindowHandle: any;
    cameraOrbitOperator: CameraOrbitOperator;
    private bimOrbitEnable = false;
    orbitHandle: any;
    constructor(private viewer: Communicator.WebViewer, viewId: string) {
        super(viewer);
        this.Name = 'areaSelect';
        this.generalOperator = new GeneralOperator(viewer);
        this.generalHandle = viewer.operatorManager.registerCustomOperator(
            this.generalOperator,
        );

        this.selectWindowOperator = new SelectWindow(viewer);
        this.selectWindowHandle = viewer.operatorManager.registerCustomOperator(
            this.selectWindowOperator,
        );

        this.cameraOrbitOperator = new CameraOrbitOperator(viewer, viewId);
        this.orbitHandle = viewer.operatorManager.registerCustomOperator(this.cameraOrbitOperator);
        GlobalState.orbitOperator = this.orbitHandle;
        this.cameraOrbitOperator.setOrbitFallbackMode(Communicator.OrbitFallbackMode.OrbitTarget);
        this.cameraOrbitOperator.setOrbitTarget(viewer.view.getCamera().getTarget());
        this.cameraOrbitOperator.setPrimaryButton(Communicator.Button.None);
    }

    public UpdateTargetPoint(newTarget: Communicator.Point3) {
        this.cameraOrbitOperator.UpdateTargetPoint(newTarget);
    }
    /**
     * Active
     */
    public Active(): void {
        if (this.Status === false) {
            this.Viewer.operatorManager.push(this.generalHandle);
            this.Viewer.operatorManager.push(this.selectWindowHandle);
            this.Viewer.operatorManager.getOperator(this.selectWindowHandle).setMapping(Communicator.Button.Left);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Middle);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Middle);

            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Right);
            this.Status = true;
            GlobalState.mapOperatorCursor.set('areaSelect', 'crosshair');
            GlobalState.primaryOperator.set(this.Viewer, 'areaSelect');
            this.SetCursor('crosshair')
            this._activeOrbit();
        }
        if (this.Status === true) { // using for update the mode changed
        }
    }

    private _activeOrbit() {
        this.Viewer.operatorManager.push(this.orbitHandle);
        this.cameraOrbitOperator.setMapping(Communicator.Button.Left, Communicator.KeyModifiers.Control);
        this.Viewer.model.getModelBounding(true, false).then((box) => {
            if (box) this.UpdateTargetPoint(box.center());
        });
        if (this.Status === true) { // using for update the mode changed
            if (this.Viewer.sheetManager.isDrawingSheetActive()) {
                this.Viewer.operatorManager.getOperator(this.orbitHandle).setMapping(Communicator.Button.None, Communicator.KeyModifiers.Control);
                this.cameraOrbitOperator.DeleteTargetPointMarker();
            }
        }
    }

    /**
     * InActive
     *
     */
    public InActive(): void {
        if (this.Status === true) {
            this.Viewer.operatorManager.remove(this.selectWindowHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.selectWindowHandle);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(Communicator.Button.Left);
            this.cameraOrbitOperator.unCallBacks();
            this.Status = false;
            this.SetCursor('')
        }
    }

    setBimOrbitEnableExt(value: boolean): void {
        this.bimOrbitEnable = value;
        this.cameraOrbitOperator.setBimOrbitEnabled(this.bimOrbitEnable);
    }
}
